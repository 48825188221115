<template>
  <hr class="section-divider-1">
  <CreateProject :vModelValue="showCreateProjectDialog" @selected-project="updateProjectId"
    @update:vModelValue="val => showCreateProjectDialog = val" />
  <div class="custom-background">
    <div class="custom-padding">
      <v-container>
        <div class="project-details">

          <p class="project-name">Project Name: {{ selectedProjectName }}</p>
        </div>
        <div class="upload-area" @dragover.prevent @dragenter.prevent @drop="onDrop($event)">
          <v-icon class="mb-2">mdi-upload</v-icon>
          <span class="mb-2">Drag and drop file here</span>
          <p class="mb-2">Limit 200MB per file. PDF, DOCX, TXT</p>
          <v-btn class="mt-2" @click="$refs.fileInput.click()">
            Browse Files
          </v-btn>
          <input type="file" ref="fileInput" @change="onFileChange($event)" style="display: none" />

          <div v-if="isLoading">
            <v-progress-circular indeterminate color="#2D5EFF"></v-progress-circular>
            <p class="mb-2">Uploading...</p>
          </div>
          <div v-if="uploadedFileName">
            <p class="mb-2">Uploaded file: {{ uploadedFileName }}</p>
            <v-btn color="red" v-if="!isLoading" @click="removeFile">Remove file</v-btn>
          </div>

        </div>

        <v-row>
          <v-col cols="12" sm="12">
            <v-select class="custom-select"  hide-details="true" :items="languages"
              label="Target Language" v-model="selectedLanguage"></v-select>
          </v-col>
        </v-row>

        <v-select class="custom-select" :items="useCases" label="Use Case" hide-details="true"
          v-model="selectedUseCase"></v-select>

        <v-select class="custom-select" :items="subjects" label="Subject" v-model="selectedSubject"
          :disabled="!selectedUseCase" hide-details="true"></v-select>

        <v-checkbox class="custom-checkbox" v-model="writingImprovement" label="Improve my writing"></v-checkbox>



        <v-btn class="mt-2" @click="translateDocument()"> <v-progress-circular indeterminate color="white"
            v-if="loading" />
          <span v-else>Translate</span>
        </v-btn>
        <v-dialog v-model="showLoginDialog" width="500">
          <v-card>
            <v-card-title>
              <span class="text-h5">Login Required</span>
            </v-card-title>
            <v-card-text>Please login to proceed.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="showLoginDialog = false">Close</v-btn>
              <v-btn color="green darken-1" text @click="login">Login</v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
    <v-dialog v-model="dialog" @update:modelValue="handleDialogClose" max-width="500px">
      <v-card>
        <v-card-text class="title mb-6">
          Translate task has been created. Please check the status in My Projects.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="goToDashboard">Go to My Project</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="insufficientCreditDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="handleDialogClose">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="buyMoreCredits">Buy More Credits</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="confirmCostDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="handleDialogClose">No</v-btn>
          <v-btn color="green darken-1" text @click="handleConfirm">Yes</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="DialogConfirm">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <custom-alert ref="customAlert"></custom-alert>
  </div>
</template>

<style scoped>
.custom-background {
  background-color: #161519;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.upload-area {
  border: 2px dashed #FDFDFD;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2em;
  /* adjust this value to your liking */
}

.mt-2 {
  color: #FDFDFD;
  background-color: #2D5EFF;
  font-family: 'Red Hat Display', sans-serif;
}

.mb-2 {
  color: #FDFDFD;

}

.custom-select {
  color: black;
  background-color: #FDFDFD;
}

.custom-checkbox {
  color: white;
}

.section-divider-1 {
  margin-top: 6em;
  border-color: #00E8BE;
  /* slightly brighter line on dark background */
}


.custom-padding {
  padding-top: 4em;
  padding-left: 10%;
  padding-right: 10%;
}

.project-name {
  font-size: 1.5em;
  /* Adjust size as needed */
  font-weight: 600;
  /* Make it bold */
  color: #FDFDFD;
  /* Darker text */
  padding: 15px;
  /* Some padding around */
  margin: 0;
  /* Remove default browser margin */
  font-family: 'Red Hat Display', sans-serif;

}
</style>



<script>
import { reactive, ref, watch, onMounted } from 'vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
import CreateProject from '@/components/CreateProject.vue';
import { useRouter } from 'vue-router';
import { openCheckoutPage } from '@/components/services/stripeService';
import CustomAlert from "@/components/CustomAlert.vue";
export default {
  components: {
    CreateProject,
    CustomAlert
  },
  setup() {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const uploadedFile = ref(null);
    const uploadedFileName = ref(null);
    const selectedLanguage = ref('');
    const outputText = ref('');
    const writingImprovement = ref(true);
    const auth0 = useAuth0();
    const isLoading = ref(false);
    const showLoginDialog = ref(false);
    const showCreateProjectDialog = ref(false);
    const useCases = ref([]);  // New property for use cases
    const subjects = ref([]);  // New property for subjects
    const selectedUseCase = ref('');  // New property for selected use case
    const selectedSubject = ref('');  // New property for selected subject
    const selectedProjectId = ref(null);
    const selectedProjectName = ref(null);
    const dialog = ref(false);
    const loading = ref(false);
    const router = useRouter();
    const customAlert = ref(null);
    const goToDashboard = () => {
      router.push('/');
    };

    const login = async () => {
      try {
        await loginWithRedirect({
          redirect_uri: window.location.origin + '/documenttranslation',
        });
        showLoginDialog.value = false;  // close the dialog
      } catch (error) {
        console.error('Error during login: ', error);
      }
    };


    const checkUserAuth = async () => {
      console.log("checkUserAuth isAuthenticated.value:", isAuthenticated.value);
      if (isAuthenticated.value) {
        showCreateProjectDialog.value = true;
      } else {
        showLoginDialog.value = true;
      }
      console.log("showCreateProjectDialog.value:", showCreateProjectDialog.value);
      console.log("showLoginDialog.value:", showLoginDialog.value);
    };

    onMounted(() => {
      console.log("isAuthenticated.value:", isAuthenticated.value);
      checkUserAuth();
    });



    const languages = ref([]);

    const fetchLanguages = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/public/app/documentTranslation/getLanguages`);
        languages.value = response.data.map(language => language.name);
      } catch (error) {
        console.error('Failed to fetch languages: ', error);
      }
    };


    fetchLanguages();

    let apiData = reactive({ data: {} });
    const fetchStyles = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/public/app/documentTranslation/formTable`);
        apiData.data = response.data;  // Store response data
        useCases.value = response.data.map(useCase => useCase.name);  // Get use case names from response
      } catch (error) {
        console.error('Failed to fetch styles: ', error);
      }
    };

    fetchStyles();

    // Watch selected use case and update subjects
    watch(selectedUseCase, (newUseCase) => {
      if (newUseCase) {
        let useCase = apiData.data.find(useCase => useCase.name === newUseCase);
        if (useCase) {
          subjects.value = useCase.subjects.map(subject => subject.name);
        }
        selectedSubject.value = '';  // Reset selected subject when use case changes


      }
    });

    //-----------------------------------------------------------------------------------------------------------------------------------------------------

    let fileUploaded = ref(false);

    const onFileChange = async (e) => {  // Update to be an async function
      if (!auth0.isAuthenticated.value) {
        showLoginDialog.value = true;
        uploadedFile.value = null;
        uploadedFileName.value = null;
        return;
      }
      if (selectedProjectId.value === null) {
        showCreateProjectDialog.value = true;
        return;
      }
      if (e.target.files.length === 0) {
        return;
      }
      uploadedFile.value = e.target.files[0];
      uploadedFileName.value = e.target.files[0].name;

      // Immediately upload the file after it's selected
      await uploadFile();  // Call the new uploadFile() function
    };
    const uploadFile = async () => {

      const allowedMimeTypes = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain"];

      if (!allowedMimeTypes.includes(uploadedFile.value.type)) {
        uploadedFile.value = null;
        uploadedFileName.value = null;

        customAlert.value.openAlert("Invalid file type", "Only PDF, DOCX, and TXT files are allowed");
        return;
      }

      try {
        const token = await auth0.getAccessTokenSilently();

        let formData = new FormData();
        formData.append('file', uploadedFile.value);
        formData.append('projectId', selectedProjectId.value);
        isLoading.value = true;


        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/app/documentTranslation/uploadFile`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        // Here, we are assigning the ID to the outputText.value
        outputText.value = response.data.id;
        fileUploaded.value = true;
        console.log('fileUploaded after successful upload:', fileUploaded.value); // New line for debugging
        customAlert.value.openAlert("Success", "File uploaded successfully!");

      } catch (error) {
        fileUploaded.value = false;
        uploadedFile.value = null;
        uploadedFileName.value = null;
        customAlert.value.openAlert("Error", "Upload file error, please try again later.");
        console.error('Error while getting the token or communicating with the server: ', error);
      } finally {
        isLoading.value = false;
      }
    };
    const updateProjectId = (projectId, projectName) => {
      selectedProjectId.value = projectId;  // Add this method to handle the emitted event
      selectedProjectName.value = projectName;
    };



    const removeFile = () => {
      uploadedFile.value = null;
      uploadedFileName.value = null;
      fileUploaded.value = false;
    };

    const onDrop = (e) => {
      e.preventDefault();
      uploadedFile.value = e.dataTransfer.files[0];
      uploadedFileName.value = e.dataTransfer.files[0].name;
      uploadFile();
    };

    const createTask = async (token) => {
      const data = {
        fileId: outputText.value,
        targetLanguage: selectedLanguage.value,
        subject: selectedSubject.value,
        type: 'documentTranslationPlusRefinement',
      };

      try {
        console.log("Data to be sent: ", data);
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/app/documentTranslation/newTask`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        return response.data;

      } catch (error) {
        // If there is an error in the request, alert the user
        customAlert.value.openAlert("Error", "Error creating task, please try again later.");

        console.error('Error while creating the task: ', error);
      }

    };

    const startTranslate = async (taskId, token) => {
      loading.value = true;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/app/documentTranslation/translateDocument`, { taskId }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          dialog.value = true;
        } else {
          throw new Error("Error occurred during translation task creation.");
        }
      } catch (error) {
        dialog.value = true;
        console.error('Error while communicating with the server: ', error);
      } finally {
        loading.value = false;
      }
    };

    const confirmTranslation = async (taskId) => {
      const token = await auth0.getAccessTokenSilently();
      console.log('taskid:', taskId);

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/app/documentTranslation/makePayment`, { taskId }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        console.log('payment response', response);
        return response.data;

      } catch (error) {

        if (error.response) {
          switch (error.response.status) {
            case 400:
              customAlert.value.openAlert("Error", "You do not have enough credit for this translation. Please add more credits.");
              break;
            case 500:
              customAlert.value.openAlert("Error", "An error occurred while processing your request. Please try again later.");
              break;
            default:
              customAlert.value.openAlert("Error", "An unexpected error occurred. Please try again later.");
              break;
          }
          throw error;
        } else {
          // General error handling
          console.error('Error during the transaction', error);
          customAlert.value.openAlert("Error", "An unexpected error occurred. Please try again later.");
          throw error;
        }
      }



    };

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const task = ref(null);
    const userCredit = ref(0);

    const insufficientCreditDialog = ref(false);
    const confirmCostDialog = ref(false);
    const confirmDialog = ref(false);
    const dialogMessage = ref("");
    const dialogTitle = ref("");

    const buyMoreCredits = () => {
      // Calculate how many credits the user needs to purchase
      let amountNeeded = task.value.price - userCredit.value;

      // If the amount needed is less than 10, set it to 10
      if (amountNeeded < 10) {
        amountNeeded = 10;
      }

      // Round up to the nearest cent
      amountNeeded = Math.ceil(amountNeeded * 100) / 100;

      // Convert this amount into a quantity (assuming $1 = 100 credits)
      let quantity = amountNeeded * 100;

      // Call the openCheckoutPage function with this quantity
      openCheckoutPage(auth0, isAuthenticated, quantity, 'price_1NijNSJ40WTpEtgla3XIcD3R'); // using the same priceID as in your fixed price options
      insufficientCreditDialog.value = false;
    }


    const DialogConfirm = async () => {
      confirmDialog.value = false;
    };



    const handleDialogClose = () => {
      insufficientCreditDialog.value = false;
      confirmCostDialog.value = false;
    };

    const handleConfirm = async () => {
      confirmCostDialog.value = false;
      console.log('task: ', task.value);
      const token = await auth0.getAccessTokenSilently();
      const paymentResponse = await confirmTranslation(task.value.id);
      console.log('payment response: ', paymentResponse);

      if (paymentResponse) {
        await startTranslate(task.value.id, token);
      }
    };

    //------------------------------------------------------------------------------------------------------------------------
    const translateDocument = async () => {
      try {
        // Check if user is authenticated
        if (!auth0.isAuthenticated.value) {
          showLoginDialog.value = true;
          return;
        }

        // Check if file is uploaded
        if (!fileUploaded.value) {
          dialogTitle.value = "Error";
          dialogMessage.value = "Please upload a file before translation.";
          confirmDialog.value = true;
          return;
        }

        // Check if all options are selected
        if (!selectedSubject.value || !selectedLanguage.value) {
          dialogTitle.value = "Error";
          dialogMessage.value = "Please select target language and translation style before translation.";
          confirmDialog.value = true;
          return;
        }

        const token = await auth0.getAccessTokenSilently();
        console.log('User is authenticated, access token is: ', token);

        task.value = await createTask(token);

        // Here we get the user credit
        const creditResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/credit/get`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        console.log('credit: ', creditResponse.data.credit);
        userCredit.value = creditResponse.data.balance + creditResponse.data.rewardCredit;
        console.log('usercredit: ', userCredit.value, 'task price: ', task.value.price, "type:", typeof userCredit.value)
        
        const floatCredit = Math.ceil(parseFloat(userCredit.value) * 100);
        const floatPrice = Math.ceil(parseFloat(task.value.price) * 100);
        if (floatCredit < floatPrice) {
          let amountNeeded = floatPrice - floatCredit;
          dialogTitle.value = "Not enough credits";
          dialogMessage.value = `You have ${userCredit.value} credits, and this translation cost ${floatPrice} credits. You need ${amountNeeded} more credits. Would you like to purchase more credits? `;
          insufficientCreditDialog.value = true;
          return;
        }

        // If user has enough credit
        let remainingCredit = floatCredit - floatPrice;
        dialogTitle.value = "Confirm";
        dialogMessage.value = `The translation will cost ${floatPrice} credits. You will have ${remainingCredit} credits remaining. Do you want to continue?`;
        confirmCostDialog.value = true;

      } catch (error) {
        console.error('Error while getting the token or communicating with the server: ', error);
      }
    };



    return {
      dialog,
      loading,
      goToDashboard,
      showLoginDialog,
      login,
      uploadedFile,
      isLoading,
      uploadedFileName,
      selectedLanguage,
      outputText,
      writingImprovement,
      languages,
      onFileChange,
      removeFile,
      onDrop,
      translateDocument,
      useCases,
      subjects,
      selectedUseCase,
      selectedSubject,
      updateProjectId,
      selectedProjectName,
      selectedProjectId,
      showCreateProjectDialog,
      insufficientCreditDialog,
      confirmCostDialog,
      confirmDialog,
      dialogMessage,
      dialogTitle,
      handleDialogClose,
      handleConfirm,
      openCheckoutPage,
      buyMoreCredits,
      DialogConfirm,
      customAlert,
    };
  }
};
</script>
  
  

  