<template>
  <CreateProjectAuto
    :vModelValue="showCreateProjectDialog"
    @selected-project="updateProjectId"
    @update:vModelValue="(val) => (showCreateProjectDialog = val)"
  />
  <div class="custom-background">
    <div class="custom-padding">
      <v-container>
        <!-- Breadcrumb Trail -->
        <nav class="breadcrumb-trail">
          <span @click="$router.push('/')">{{
            pagelanguages[currentpageLanguage]?.home
          }}</span>
          <span class="separator">/</span>
          <span @click="$router.push('/businessrefinement')">{{
            pagelanguages[currentpageLanguage]?.businessrefinement
          }}</span>
        </nav>
        <p class="project-type">
          {{ pagelanguages[currentpageLanguage]?.businessrefinement }}
        </p>
        <UploadComponent @data-uploaded="updateFileId" />

        <v-select
          class="custom-select"
          :items="disciplines"
          :label="pagelanguages[currentpageLanguage]?.disciplines"
          v-model="selectedDisciplines"
          :disabled="!fileId"
          hide-details="true"
        ></v-select>
        <v-select
          class="custom-select"
          :items="subjects"
          :label="pagelanguages[currentpageLanguage]?.subject"
          v-model="selectedSubject"
          :disabled="!selectedDisciplines"
          hide-details="true"
        ></v-select>

        <v-btn class="mt-2" @click="translateDocument()">
          <v-progress-circular indeterminate color="white" v-if="loading" />
          <span v-else> {{ pagelanguages[currentpageLanguage]?.submit }}</span>
        </v-btn>
        <v-dialog v-model="showLoginDialog" width="500">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                pagelanguages[currentpageLanguage]?.pleaselogin
              }}</span>
            </v-card-title>
            <v-card-text>{{
              pagelanguages[currentpageLanguage]?.pleaselogintext
            }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="showLoginDialog = false"
                >{{ pagelanguages[currentpageLanguage]?.close }}</v-btn
              >
              <v-btn color="green darken-1" text @click="login">{{
                pagelanguages[currentpageLanguage]?.login
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </div>
    <v-dialog
      v-model="dialog"
      @update:modelValue="handleDialogClose"
      max-width="500px"
    >
      <v-card>
        <v-card-text class="title mb-6">
          {{ pagelanguages[currentpageLanguage]?.taskcreated }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="goToDashboard">
            {{ pagelanguages[currentpageLanguage]?.mydownload }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="insufficientCreditDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="handleDialogClose">
            {{ pagelanguages[currentpageLanguage]?.cancel }}</v-btn
          >
          <v-btn color="green darken-1" text @click="buyMoreCredits">
            {{ pagelanguages[currentpageLanguage]?.buymorecredits }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmCostDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="handleDialogClose">
            {{ pagelanguages[currentpageLanguage]?.no }}</v-btn
          >
          <v-btn color="green darken-1" text @click="handleConfirm">
            {{ pagelanguages[currentpageLanguage]?.yes }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>{{ dialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="DialogConfirm">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <custom-alert ref="customAlert"></custom-alert>

    <v-dialog v-model="showTaskDetails" max-width="600px">
      <v-card>
        <v-card-title>
          {{ pagelanguages[currentpageLanguage]?.confirmdetail }}
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <tbody>
              <tr>
                <td class="label-cell">
                  <strong>{{
                    pagelanguages[currentpageLanguage]?.refinementtype
                  }}</strong>
                </td>
                <td class="data-cell">
                  {{ pagelanguages[currentpageLanguage]?.businessrefinement }}
                </td>
              </tr>
              <tr>
                <td class="label-cell">
                  <strong>{{
                    pagelanguages[currentpageLanguage]?.subject
                  }}</strong>
                </td>
                <td class="data-cell">{{ selectedSubject }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" @click="showTaskDetails = false">{{
            pagelanguages[currentpageLanguage]?.cancel
          }}</v-btn>
          <v-btn color="green darken-1" @click="proceedWithTask">{{
            pagelanguages[currentpageLanguage]?.confirm
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <CheckOutDialog
    :taskId="taskId"
    :taskPrice="floatPrice"
    :discountPercentage="discountPercentage"
    :rewardBackPercentage="rewardBackPercentage"
    :rewardCredit="rewardCredit"
    :purchasedCredit="purchasedCredit"
    :totalCredit="totalCredit"
    :amountNeeded="amountNeeded"
    :checkoutDialog="checkoutDialog"
    v-model="checkoutDialog"
    @triggerConfirm="handleConfirm"
  />
</template>

<style scoped>
.custom-background {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.mt-2 {
  color: #fdfdfd;
  background-color: #2d5eff;
  font-family: 'Red Hat Display', sans-serif;
}

.custom-select {
  color: black;
  background-color: #fdfdfd;
}

.custom-padding {
  padding-top: 4em;
  padding-left: 10%;
  padding-right: 10%;
}

.project-type {
  font-size: 1.8em;
  font-weight: 600;
  color: black;
  padding: 15px;
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  text-align: center;
}
.label-cell {
  width: 50%;
  padding-right: 15px;
}

.data-cell {
  padding-left: 15px;
}
</style>

<script>
import {
  reactive,
  ref,
  watch,
  onMounted,
  onUnmounted,
  inject,
  computed,
} from 'vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
import CreateProjectAuto from '@/components/CreateProjectAuto.vue';
import { useRouter } from 'vue-router';
import { openCheckoutPage } from '@/components/services/stripeService';
import CustomAlert from '@/components/CustomAlert.vue';
import { globalState } from '@/globalState.js';
import CheckOutDialog from '@/components/CheckOutDialog.vue';
import UploadComponent from '@/components/UploadComponent.vue';
export default {
  components: {
    CheckOutDialog,
    CreateProjectAuto,
    CustomAlert,
    UploadComponent,
  },

  setup() {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const uploadedFile = ref(null);
    const uploadedFileName = ref(null);
    const fileId = ref(null);
    const auth0 = useAuth0();
    const isLoading = ref(false);
    const showLoginDialog = ref(false);
    const showCreateProjectDialog = ref(false);
    const useCases = ref([]);
    const disciplines = ref([]);
    const subjects = ref([]); // New property for subjects
    const selectedSubject = ref(''); // New property for selected subject
    const selectedDisciplines = ref('');
    const selectedProjectId = ref(null);
    const selectedProjectName = ref(null);
    const dialog = ref(false);
    const loading = ref(false);
    const router = useRouter();
    const customAlert = ref(null);
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const isTaskUnfinished = ref(false);
    const showTaskDetails = ref(false);
    const getUserGuideUrl = inject('getUserGuideUrl');
    const userGuideUrl = computed(() =>
      getUserGuideUrl(currentpageLanguage.value)
    );
    const files = ref([]); // Store the list of files for a project
    const showFileList = ref(false);
    const checkoutDialog = ref(false);
    const convertToLocalTime = (utcTime) => {
      const localTime = new Date(utcTime);
      return localTime.toLocaleString(); // this will convert the time to a string that represents the local time zone
    };
    const toggleDropdown = () => {
      if (!isAuthenticated.value) {
        showLoginDialog.value = true;
      } else if (selectedProjectId.value === null) {
        showCreateProjectDialog.value = true;
      } else {
        showFileList.value = !showFileList.value;
      }
    };
    const goToDashboard = () => {
      router.push('/mydownload');
    };

    const login = async () => {
      try {
        await loginWithRedirect({
          redirect_uri: window.location.origin,
        });
        showLoginDialog.value = false; // close the dialog
      } catch (error) {
        console.error('Error during login: ', error);
      }
    };
    const updateFileId = (newFileId) => {
      fileId.value = newFileId;
    };

    watch(isAuthenticated, (newValue) => {
      console.log('isAuthenticated watch triggered. New value:', newValue);
      showLoginDialog.value = !newValue; // Update dialog based on authentication status
    });
    const checkUserAuth = async () => {
      console.log(
        'checkUserAuth isAuthenticated.value:',
        isAuthenticated.value
      );
      if (isAuthenticated.value) {
        showCreateProjectDialog.value = true;
      } else {
        showLoginDialog.value = true;
      }
      console.log(
        'showCreateProjectDialog.value:',
        showCreateProjectDialog.value
      );
      console.log('showLoginDialog.value:', showLoginDialog.value);
    };

    onMounted(() => {
      console.log('isAuthenticated.value:', isAuthenticated.value);
      console.log('paymentsuccess:', globalState.paymentSuccess);
      checkUserAuth();
      window.addEventListener('beforeunload', warnOnPageExit);
    });

    onUnmounted(() => {
      window.removeEventListener('beforeunload', warnOnPageExit);
    });

    const warnOnPageExit = (event) => {
      if (isTaskUnfinished.value) {
        event.preventDefault();
        event.returnValue =
          'You have unfinished tasks. Are you sure you want to leave?';
      }
    };

    const languages = ref([]);

    const fetchLanguages = async () => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/public/app/documentTranslation/getLanguages`
        );
        languages.value = response.data.map((language) => language.name);
      } catch (error) {
        console.error('Failed to fetch languages: ', error);
      }
    };

    fetchLanguages();

    let apiData = reactive({ data: {} });
    const fetchStyles = async () => {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/public/app/documentTranslation/formTable`
        );
        console.log('fetch styles response:', response.data);
        apiData.data = response.data; // Store response data

        // Flatten the array and remove duplicates
        const allDisciplines = response.data.business
          .map((item) => item.disciplines)
          .flat();
        disciplines.value = [...new Set(allDisciplines)];
        console.log('disciplines:', disciplines.value);
      } catch (error) {
        console.error('Failed to fetch styles: ', error);
      }
    };

    fetchStyles();

    // Watch selected use case and update subjects

    watch(selectedDisciplines, (newDisciplines) => {
      if (newDisciplines) {
        subjects.value = [];

        for (const line of apiData.data.business) {
          if (line.disciplines === newDisciplines) {
            subjects.value.push(line.name);
          }
        }

        selectedSubject.value = ''; // Reset selected subject when use case changes
      }
    });

    //-----------------------------------------------------------------------------------------------------------------------------------------------------

    const createTask = async (token) => {
      const data = {
        fileId: fileId.value,
        subject: selectedSubject.value,
        taskType: 'documentRefinement',
      };

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/app/documentTranslation/newTask`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error) {
        // If there is an error in the request, alert the user
        customAlert.value.openAlert(
          pagelanguages[currentpageLanguage.value]?.error,
          pagelanguages[currentpageLanguage.value]?.taskerror
        );

        console.error('Error while creating the task: ', error);
      }
    };

    const startTranslate = async (taskId, token) => {
      loading.value = true;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/app/documentTranslation/translateDocument`,
          { taskId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200) {
          dialog.value = true;
        } else {
          throw new Error('Error occurred during translation task creation.');
        }
      } catch (error) {
        dialog.value = true;
        console.error('Error while communicating with the server: ', error);
      } finally {
        loading.value = false;
      }
    };

    const confirmTranslation = async (taskId) => {
      const token = await auth0.getAccessTokenSilently();
      console.log('taskid:', taskId);

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/app/documentTranslation/makePayment`,
          { taskId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('payment response', response);
        return response.data;
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              customAlert.value.openAlert(
                'Error',
                'You do not have enough credit for this translation. Please add more credits.'
              );
              break;
            case 500:
              customAlert.value.openAlert(
                'Error',
                'An error occurred while processing your request. Please try again later.'
              );
              break;
            default:
              customAlert.value.openAlert(
                'Error',
                'An unexpected error occurred. Please try again later.'
              );
              break;
          }
          throw error;
        } else {
          // General error handling
          console.error('Error during the transaction', error);
          customAlert.value.openAlert(
            pagelanguages[currentpageLanguage.value]?.error,
            pagelanguages[currentpageLanguage.value]?.unexpectederror
          );
          throw error;
        }
      }
    };

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const task = ref(null);
    const userCredit = ref(0);

    const insufficientCreditDialog = ref(false);
    const confirmCostDialog = ref(false);
    const confirmDialog = ref(false);
    const dialogMessage = ref('');
    const dialogTitle = ref('');

    const buyMoreCredits = () => {
      console.log('buy more credits');
      // Calculate how many credits the user needs to purchase
      let amountNeeded = task.value.price - userCredit.value;

      // If the amount needed is less than 10, set it to 10
      if (amountNeeded < 10) {
        amountNeeded = 10;
      }

      // Round up to the nearest cent
      amountNeeded = Math.ceil(amountNeeded * 100);

      let quantity = amountNeeded;
      quantity = Math.floor(quantity);
      console.log('quantity: ', quantity);
      // Call the openCheckoutPage function with this quantity
      openCheckoutPage(
        auth0,
        isAuthenticated,
        quantity,
        'price_1NijNSJ40WTpEtgla3XIcD3R'
      );
      isTaskUnfinished.value = false;
      insufficientCreditDialog.value = false;
    };

    const DialogConfirm = async () => {
      confirmDialog.value = false;
    };

    const handleDialogClose = () => {
      insufficientCreditDialog.value = false;
      confirmCostDialog.value = false;
    };

    const handleConfirm = async () => {
      isTaskUnfinished.value = false;
      globalState.isTaskUnfinished = false;

      try {
        checkoutDialog.value = false;
        const token = await auth0.getAccessTokenSilently();
        const paymentResponse = await confirmTranslation(task.value.id);

        if (paymentResponse) {
          await startTranslate(task.value.id, token);
        }
      } catch (error) {
        console.error('Error in handleConfirm: ', error);
        // Handle any specific errors if needed.
      } finally {
        // This will always run, whether there was an error or not.
        localStorage.removeItem('isPaymentSuccessful');
      }
    };

    const pollingInterval = ref(null);

    onMounted(() => {
      pollingInterval.value = setInterval(() => {
        if (localStorage.getItem('isPaymentSuccessful') === 'true') {
          handleConfirm();
          localStorage.removeItem('isPaymentSuccessful'); // Reset the flag
        }
      }, 1000); // Check every second
    });

    onUnmounted(() => {
      clearInterval(pollingInterval.value); // Cleanup to prevent memory leaks
    });

    //------------------------------------------------------------------------------------------------------------------------
    const translateDocument = async () => {
      try {
        // Check if user is authenticated
        if (!auth0.isAuthenticated.value) {
          showLoginDialog.value = true;
          return;
        }

        // Check if file is uploaded
        if (!fileId.value) {
          dialogTitle.value = pagelanguages[currentpageLanguage.value]?.error;
          dialogMessage.value =
            pagelanguages[currentpageLanguage.value]?.nofileerror;
          confirmDialog.value = true;
          return;
        }

        // Check if all options are selected
        if (!selectedSubject.value) {
          dialogTitle.value = pagelanguages[currentpageLanguage.value]?.error;
          dialogMessage.value =
            pagelanguages[currentpageLanguage.value]?.nosubject;
          confirmDialog.value = true;
          return;
        }

        // Display the task details for confirmation
        showTaskDetails.value = true;
      } catch (error) {
        console.error('Error while preparing for the task: ', error);
      }
    };
    const taskId = ref('');
    const proceedWithTask = async () => {
      try {
        showTaskDetails.value = false; // Close the task confirmation dialog

        const token = await auth0.getAccessTokenSilently();
        task.value = await createTask(token);
        taskId.value = task.value.id;

        isTaskUnfinished.value = false;
        globalState.isTaskUnfinished = false;
        checkoutDialog.value = true;
      } catch (error) {
        console.error('Error during the task processing: ', error);
      }
    };

    return {
      toggleDropdown,
      showFileList,
      showTaskDetails,
      pagelanguages,
      currentpageLanguage,
      dialog,
      loading,
      goToDashboard,
      showLoginDialog,
      login,
      uploadedFile,
      isLoading,
      uploadedFileName,
      languages,
      fileId,
      translateDocument,
      useCases,
      subjects,
      selectedSubject,
      disciplines,
      selectedDisciplines,
      selectedProjectName,
      selectedProjectId,
      showCreateProjectDialog,
      insufficientCreditDialog,
      confirmCostDialog,
      confirmDialog,
      dialogMessage,
      dialogTitle,
      handleDialogClose,
      handleConfirm,
      openCheckoutPage,
      buyMoreCredits,
      DialogConfirm,
      customAlert,
      proceedWithTask,
      userGuideUrl,
      files,
      convertToLocalTime,
      checkoutDialog,
      taskId,
      UploadComponent,
      updateFileId,
    };
  },
};
</script>
