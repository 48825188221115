<template>
  <div class="container">
        <!-- Breadcrumb Trail -->
    <nav class="breadcrumb-trail">
      <span @click="$router.push('/')">{{ pagelanguages[currentpageLanguage]?.home }}</span>
      <span class="separator">/</span>
      <span @click="$router.push('/myproject')">{{ pagelanguages[currentpageLanguage]?.myproject }}</span>
    </nav>
    <h1 class="text my-4 text-center">{{ pagelanguages[currentpageLanguage]?.myproject }}</h1>
    <div class="card">
      <v-card-text v-if="isLoading">
                <v-progress-circular indeterminate size="35" color="white"></v-progress-circular>
                <span class="loading-text">loading...</span>
            </v-card-text>
            <div v-else>
      <div class="header-row">
        <div class="cell-title"><div>{{ pagelanguages[currentpageLanguage]?.projectname }}</div></div>
        <div class="cell-title"><div>{{ pagelanguages[currentpageLanguage]?.tablecreationtime }}</div></div>
        <div class="cell-title"><div>{{ pagelanguages[currentpageLanguage]?.actions }}</div></div>
      </div>
      <div class="scrollable-area">
        <div class="row" v-for="project in projects" :key="project.id">
          <div class="cell">
            <a @click="goToProjectTasks(project.id, project.tasks, project.name)" class="name-link">{{ project.name }}</a>
          </div>
          <div class="cell">{{ convertToLocalTime(project.createdAt) }}</div>

          <div class="cell actions">
            <v-icon class="action-icon" small  :title="pagelanguages[currentpageLanguage]?.delete" @click="deleteProject(project.id)">mdi-trash-can-outline</v-icon>

            <div class="open-project-container">
              <button class="open-project-button" @click="goToProjectTasks(project.id, project.tasks, project.name)">{{ pagelanguages[currentpageLanguage]?.openproject }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <v-dialog v-model="dialog" persistent max-width="500px">
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ pagelanguages[currentpageLanguage]?.warning }}</span>
    </v-card-title>
    <v-card-text>
      {{ dialogMessage }}
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="dialog = false">{{ pagelanguages[currentpageLanguage]?.no }}</v-btn>
      <v-btn color="green darken-1" text @click="deleteConfirmed">{{ pagelanguages[currentpageLanguage]?.yes }}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

<v-dialog v-model="showLoginDialog" width="500">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ pagelanguages[currentpageLanguage]?.pleaselogin }}</span>
              </v-card-title>
              <v-card-text>{{ pagelanguages[currentpageLanguage]?.pleaselogintext }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="showLoginDialog = false">{{ pagelanguages[currentpageLanguage]?.close }}</v-btn>
                <v-btn color="green darken-1" text @click="login">{{ pagelanguages[currentpageLanguage]?.login }}</v-btn>
  
              </v-card-actions>
            </v-card>
</v-dialog>
</template>







  
<script>
import { ref, computed, onMounted, watch, inject } from 'vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const { isAuthenticated, getAccessTokenSilently,loginWithRedirect } = useAuth0();
    const projects = ref([]);
    const tasks = ref([]);
    const expandedProjectId = ref(null);
    const dialog = ref(false);
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const dialogMessage = ref("");
    const isLoading = ref(false);
    const showLoginDialog = ref(false);

    let projectIdToDelete = ref(null);

    const checkUserAuth= async ()=>{
      if(!isAuthenticated.value){
        showLoginDialog.value = true;
      }
    }
    const login = async () => {
        try {
          await loginWithRedirect({
            redirect_uri: window.location.origin + '/',
          });
          showLoginDialog.value = false;  // close the dialog
        } catch (error) {
          console.error('Error during login: ', error);
        }
      };
    const convertToLocalTime = (utcTime) => {
        const localTime = new Date(utcTime);
        return localTime.toLocaleString(); // this will convert the time to a string that represents the local time zone
    };
    const goToProjectTasks = (projectId, tasks, projectName) => {
      console.log("goToProjectTasks() called, projectId:", projectId, "name:", projectName);
      router.push({ 
        name: 'ProjectTable', 
        params: { 
          projectId, 
          tasks,
          projectName 
        } 
      });
    };

    const currentTasks = computed(() => {
      return tasks.value.filter(task => task.projectId === expandedProjectId.value);
    });

    const getProjects = async () => {
      isLoading.value = true;
      if (!isAuthenticated.value) {
        // TODO: Show Login Dialog
        return;
      }
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/project/get`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        console.log("getProjects() response:", response.data);
        projects.value = response.data;
        isLoading.value = false;
      } catch (error) {
        console.error('Failed to get project: ', error);
      }

    };

    const fetchTasks = async (project) => {
      isLoading.value = true;
     
      if (!project.show) {
        if (!isAuthenticated.value) {
          return;
        }
        try {
          const token = await getAccessTokenSilently();
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/file/getByProjectId/${project.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          console.log("fetchTasks() response:", response.data);
          const taskJson = [];
          response.data.map(file => {
            taskJson.push(...file.tasks);
          });

          project.tasks = taskJson.map(task => ({
            ...task,
            status: task.status === 'unpaid' ? 'Unpaid' :
              task.status === 'paid' ? 'Paid' :
                task.status === 'processing' ? 'Processing' :
                  task.status === 'translated' ? 'Translation Complete' :
                    task.status === 'deleted' ? 'Deleted' :
                    'Translation Failed',
          }));
          isLoading.value = false;
        } catch (error) {
          console.error('Failed to get tasks: ', error);
        }
      }
      console.log("fetchTasks() complete, project:", project);
      project.show = !project.show;
    };

    const deleteProject = (projectId) => {
     
      dialogMessage.value = pagelanguages[currentpageLanguage.value]?.deleteprojecttext;

      projectIdToDelete.value = projectId; // Store the projectId for deletion
      dialog.value = true; // Open the dialog
    };

    const deleteConfirmed = async () => {
      try {
        const token = await getAccessTokenSilently();

        const response = await axios.delete(`${process.env.VUE_APP_API_URL}/api/project/delete/${projectIdToDelete.value}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });

        console.log(`Deleted project with id ${projectIdToDelete.value}`);
        console.log(response);
        projects.value = projects.value.filter(project => project.id !== projectIdToDelete.value);
        dialog.value = false; // Close the dialog
      } catch (error) {
        console.error(`Error deleting project: ${error}`);
      }
    };



    watch(() => isAuthenticated.value, async (newValue) => {
      console.log("isAuthenticated.value watch triggered, newValue:", newValue);
        if (newValue) {
          await getProjects();
        }
      }, { immediate: true });

      onMounted(() => {
        checkUserAuth();
        getProjects();
        
      });

    return {
    projects,
    tasks,
    fetchTasks,
    expandedProjectId,
    currentTasks,
    goToProjectTasks,
    deleteProject,
    dialog,
    dialogMessage,
    deleteConfirmed,
    pagelanguages,
    currentpageLanguage,
    isLoading,
    convertToLocalTime,
    showLoginDialog,
    login
}

  }
}
</script>

  
<style scoped>
.container {
  padding-top: 3em; /* Adjust as needed */
  padding-left: 10%;
  padding-right: 10%;
  background-color: #161519;
  min-height: 100%;
}


.scrollable-area {
  max-height: 350px;
  overflow-y: auto; 
  background-color: #FDFDFD;
}
.card {
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.header-row,
.row {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.header-row {
  background: #57596A;
  font-weight: 600;
}

.cell {
  flex: 1;
  display: flex;
  align-items: center;
  color: #000;
}
.cell-title{
  flex: 1;
  display: flex;
  align-items: center;
  color: #FDFDFD;
}
.name-link {
  cursor: pointer;
  color: #2D5EFF;
  text-decoration: underline;
}

.cell.actions {
  display: flex;
  justify-content: flex-start;
}

.action-icon {
  margin-right: 15px;
  cursor: pointer;
}

.open-project-container {
  margin-left: auto;
}

.open-project-button {
  background: #2D5EFF;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.my-4{
  color: #FDFDFD;
  font-family: 'Red Hat Display', sans-serif;
}
.loading-text{
  padding-left: 1em;
  font-size: 2em;
  color:white;
}

</style>
  