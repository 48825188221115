<template>
  <div class="container">
    <!-- Breadcrumb Trail -->
    <nav class="breadcrumb-trail">
      <span @click="$router.push('/')">{{
        pagelanguages[currentpageLanguage]?.home
      }}</span>
      <span class="separator">/</span>
      <span @click="$router.push('/mydownload')">{{
        pagelanguages[currentpageLanguage]?.mydownload
      }}</span>
    </nav>

    <h1 class="text my-4 text-center">
      {{ pagelanguages[currentpageLanguage]?.mydownload }}
    </h1>

    <div class="card">
      <v-card-text v-if="isLoading">
        <v-progress-circular
          indeterminate
          size="35"
          color="white"
        ></v-progress-circular>
        <span class="loading-text">loading...</span>
      </v-card-text>

      <div v-else>
        <div class="header-row">
          <div class="cell-title">
            <div>{{ pagelanguages[currentpageLanguage]?.filename }}</div>
          </div>
          <div class="cell-title">
            <div>{{ pagelanguages[currentpageLanguage]?.type }}</div>
          </div>
          <div class="cell-title">
            <div>{{ pagelanguages[currentpageLanguage]?.status }}</div>
          </div>
          <div class="cell-title">
            <div>
              {{ pagelanguages[currentpageLanguage]?.tablecreationtime }}
            </div>
          </div>
          <div class="cell-title">
            <div>{{ pagelanguages[currentpageLanguage]?.actions }}</div>
          </div>
        </div>
        <div class="scrollable-area">
          <div class="row" v-for="file in completedFiles" :key="file.id">
            <div class="cell">
              <span>{{ file.name }}</span>
            </div>
            <div class="cell">{{ file.type }}</div>
            <div class="cell">{{ file.status }}</div>
            <div class="cell">{{ convertToLocalTime(file.createdAt) }}</div>

            <div class="cell actions">
              <v-icon
                class="action-icon"
                small
                :title="pagelanguages[currentpageLanguage]?.delete"
                @click="deleteFile(file.id)"
                >mdi-trash-can-outline</v-icon
              >

              <div class="download-file-container">
                <button
                  v-if="file.status === 'completed'"
                  class="download-file-button"
                  @click="downloadFile(file)"
                >
                  {{ pagelanguages[currentpageLanguage]?.download }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            pagelanguages[currentpageLanguage]?.warning
          }}</span>
        </v-card-title>
        <v-card-text>
          {{ dialogMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">{{
            pagelanguages[currentpageLanguage]?.no
          }}</v-btn>
          <v-btn color="green darken-1" text @click="deleteConfirmed">{{
            pagelanguages[currentpageLanguage]?.yes
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <LoginDialog :redirectUri="specificRedirectUri" @logged-in="getFiles" />
</template>
<script>
import { ref, watch, inject, computed } from 'vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
import LoginDialog from '@/components/LoginDialog.vue';
export default {
  components: {
    LoginDialog,
  },
  setup() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const files = ref([]);
    const dialog = ref(false);
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const dialogMessage = ref('');
    const isLoading = ref(false);
    const specificRedirectUri = ref(window.location.origin + '/mydownload');
    let fileIdToDelete = ref(null);

    const convertToLocalTime = (utcTime) => {
      const localTime = new Date(utcTime);
      return localTime.toLocaleString(); // Convert the time to a string representing the local time zone
    };

    const completedFiles = computed(() => {
      // Ensure files are available and completed
      const completed = files.value.filter(
        (file) => file.status === 'completed' || file.status === 'processing'
      );

      // Sort by createdAt, from newest to oldest
      return completed.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    });

    const getFiles = async () => {
      isLoading.value = true;
      if (!isAuthenticated.value) {
        return;
      }
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/task/get`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('getFiles() response:', response.data);
        files.value = response.data;
        isLoading.value = false;
      } catch (error) {
        console.error('Failed to get files: ', error);
      }
    };

    const deleteFile = (fileId) => {
      dialogMessage.value =
        pagelanguages[currentpageLanguage.value]?.deleteconfirm;

      fileIdToDelete.value = fileId; // Store the fileId for deletion
      dialog.value = true; // Open the dialog
    };

    const downloadFile = async (file) => {
      alert(`Downloading ${file.outputName}`);
      console.log(file);
      try {
        const taskId = file.id;

        const token = await getAccessTokenSilently();
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/app/documentTranslation/downloadOutput/`,
          { taskId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'blob', // Important for file download
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file.outputName}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(
          'Error while getting the token or communicating with the server: ',
          error
        );
        alert(`An error occurred while downloading the task: ${error.message}`);
      }
    };

    const deleteConfirmed = async () => {
      try {
        const token = await getAccessTokenSilently();

        const response = await axios.delete(
          `${process.env.VUE_APP_API_URL}/api/app/documentTranslation/deleteTask/${fileIdToDelete.value}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(`Deleted file with id ${fileIdToDelete.value}`);
        console.log(response);
        files.value = files.value.filter(
          (file) => file.id !== fileIdToDelete.value
        );
        dialog.value = false; // Close the dialog
      } catch (error) {
        console.error(`Error deleting file: ${error}`);
      }
    };

    watch(
      isAuthenticated,
      (newValue) => {
        if (newValue) {
          getFiles();
        }
      },
      { immediate: true }
    );

    return {
      files,
      deleteFile,
      downloadFile,
      dialog,
      dialogMessage,
      deleteConfirmed,
      pagelanguages,
      currentpageLanguage,
      isLoading,
      convertToLocalTime,
      completedFiles,
      specificRedirectUri,
      LoginDialog,
    };
  },
};
</script>
<style scoped>
.container {
  padding-top: 3em; /* Adjust as needed */
  padding-left: 10%;
  padding-right: 10%;
  min-height: 100%;
}

.scrollable-area {
  max-height: 60vh;
  overflow-y: auto;
  background-color: #fdfdfd;
}
.card {
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.header-row,
.row {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  overflow: hidden;
}

.header-row {
  background: #57596a;
  font-weight: 600;
}

.cell {
  flex: 1;
  display: flex;
  align-items: center;
  color: #000;
  overflow: hidden;
  padding: 0 20px;
}
.cell-title {
  flex: 1;
  display: flex;
  align-items: center;
  color: #fdfdfd;
  padding: 0 20px;
}

.cell.actions {
  display: flex;
  justify-content: flex-start;
}

.action-icon {
  margin-right: 15px;
  cursor: pointer;
}

.my-4 {
  color: black;
  font-family: 'Red Hat Display', sans-serif;
  padding-bottom: 0.4em;
  padding-top: 0.2em;
  font-size: 1.7em;
}
.loading-text {
  padding-left: 1em;
  font-size: 2em;
  color: white;
}
.download-file-container {
  margin-left: auto;
}

.download-file-button {
  background: #2d5eff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.project-link {
  color: #00e8be;
}
</style>
