<template>
  <div class="page-container">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 id="doctexttranslationrefinement" class="header">
            {{ languages[currentLanguage]?.documentreview }}
          </h1>
        </v-col>
      </v-row>
      <v-row class="align-start">
        <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="d-flex apps-card">
          <router-link
            :to="{ name: 'paperreview' }"
            class="text-decoration-none"
          >
            <v-card
              @mouseover="show($event, 'paper_review')"
              @mouseout="hide('paper_review')"
              class="card"
              outlined
              link
            >
              <div class="card-upper-free">
                <img
                  :src="require('@/assets/ReviewLogo.png')"
                  alt="Document Translation"
                  class="review-icon-img"
                />
              </div>
              <div class="card-lower">
                <span class="title">
                  {{ languages[currentLanguage]?.paperreview }}
                </span>
                <div class="description-container">
                  <p class="description-free">
                    {{ languages[currentLanguage]?.free }}
                  </p>
                </div>
              </div>
              <!-- Tooltip content -->
              <v-tooltip
                v-model="tooltips.paper_review"
                :style="tooltipPosition"
                class="custom-tooltip"
              >
                <span>{{ languages[currentLanguage]?.reviewtooltip }}</span>
              </v-tooltip>
            </v-card>
          </router-link>
        </v-col>

        <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="d-flex apps-card">
          <router-link
            :to="{ name: 'journalrecommendation' }"
            style="text-decoration: none"
          >
            <v-card
              @mouseover="show($event, 'journal_recommendation')"
              @mouseout="hide('journal_recommendation')"
              class="card"
              outlined
              link
            >
              <div class="card-upper-free">
                <img
                  :src="require('@/assets/RecommendationLogo.png')"
                  alt="Document Translation"
                  class="icon-img-recommendation"
                />
              </div>
              <div class="card-lower">
                <span class="title">
                  {{ languages[currentLanguage]?.journalrecommendation }}</span
                >
                <div class="description-container">
                  <p class="description-free">
                    {{ languages[currentLanguage]?.free }}
                  </p>
                </div>
              </div>
              <!-- Tooltip content -->
              <v-tooltip
                v-model="tooltips.journal_recommendation"
                :style="tooltipPosition"
                class="custom-tooltip"
              >
                <span>{{
                  languages[currentLanguage]?.recommendationtooltip
                }}</span>
              </v-tooltip>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h1 id="doctexttranslationrefinement" class="header">
            {{ languages[currentLanguage]?.doctranslationrefinement }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="d-flex apps-card">
          <router-link
            :to="{ name: 'academicpapers' }"
            style="text-decoration: none"
          >
            <v-card
              @mouseover="show($event, 'academic')"
              @mouseout="hide('academic')"
              class="card"
              outlined
              link
            >
              <div class="card-upper">
                <img
                  :src="require('@/assets/AcademicLogo.png')"
                  alt="Document Translation"
                  class="icon-img"
                />
              </div>
              <div class="card-lower">
                <span class="title">{{
                  languages[currentLanguage]?.academicpapers
                }}</span>
                <div class="description-container">
                  <p class="description">
                    {{ languages[currentLanguage]?.academicprice }}
                  </p>
                </div>
              </div>
              <!-- Tooltip content -->
              <v-tooltip
                v-model="tooltips.academic"
                :style="tooltipPosition"
                class="custom-tooltip"
              >
                <span>{{ languages[currentLanguage]?.academictooltip }}</span>
              </v-tooltip>
            </v-card>
          </router-link>
        </v-col>

        <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="d-flex apps-card">
          <router-link
            :to="{ name: 'businessdocuments' }"
            style="text-decoration: none"
          >
            <v-card
              @mouseover="show($event, 'business')"
              @mouseout="hide('business')"
              class="card"
              outlined
              link
            >
              <div class="card-upper">
                <img
                  :src="require('@/assets/DocLogo.png')"
                  alt="Document Translation"
                  class="icon-img"
                />
              </div>
              <div class="card-lower">
                <span class="title">{{
                  languages[currentLanguage]?.businessdocuments
                }}</span>
                <div class="description-container">
                  <p class="description">
                    {{ languages[currentLanguage]?.businessprice }}
                  </p>
                </div>
              </div>
              <!-- Tooltip content -->
              <v-tooltip
                v-model="tooltips.business"
                :style="tooltipPosition"
                class="custom-tooltip"
              >
                <span>{{ languages[currentLanguage]?.businesstooltip }}</span>
              </v-tooltip>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="d-flex apps-card">
          <router-link
            :to="{ name: 'literaturepublication' }"
            style="text-decoration: none"
          >
            <v-card
              @mouseover="show($event, 'literature')"
              @mouseout="hide('literature')"
              class="card"
              outlined
              link
            >
              <div class="card-upper">
                <img
                  :src="require('@/assets/LiteratureLogo.png')"
                  alt="Document Translation"
                  class="literature-icon-img"
                />
              </div>
              <div class="card-lower">
                <span class="title">{{
                  languages[currentLanguage]?.literature
                }}</span>
                <div class="description-container">
                  <p class="description">
                    {{ languages[currentLanguage]?.literatureprice }}
                  </p>
                </div>
              </div>
              <!-- Tooltip content -->
              <v-tooltip
                v-model="tooltips.literature"
                :style="tooltipPosition"
                class="custom-tooltip"
              >
                <span>{{ languages[currentLanguage]?.literaturetooltip }}</span>
              </v-tooltip>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h1 id="myProject" class="header">
            {{ languages[currentLanguage]?.refinementonlyapps }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="d-flex apps-card">
          <router-link
            :to="{ name: 'academicrefinement' }"
            style="text-decoration: none"
          >
            <v-card
              @mouseover="show($event, 'academic_refinement')"
              @mouseout="hide('academic_refinement')"
              class="card d-flex flex-column align-center"
              outlined
              link
            >
              <div class="card-upper-refinement">
                <img
                  :src="require('@/assets/AcademicLogo.png')"
                  alt="Document Translation"
                  class="icon-img"
                />
              </div>
              <div class="card-lower">
                <span class="title">{{
                  languages[currentLanguage]?.academicrefinement
                }}</span>
                <div class="description-container">
                  <p class="description">
                    {{ languages[currentLanguage]?.academicrefinementprice }}
                  </p>
                </div>
              </div>
              <!-- Tooltip content -->
              <v-tooltip
                v-model="tooltips.academic_refinement"
                :style="tooltipPosition"
                class="custom-tooltip"
              >
                <span>{{ languages[currentLanguage]?.academictooltip }}</span>
              </v-tooltip>
            </v-card>
          </router-link>
        </v-col>

        <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="d-flex apps-card">
          <router-link
            :to="{ name: 'businessrefinement' }"
            style="text-decoration: none"
          >
            <v-card
              @mouseover="show($event, 'business_refinement')"
              @mouseout="hide('business_refinement')"
              class="card"
              outlined
              link
            >
              <div class="card-upper-refinement">
                <img
                  :src="require('@/assets/DocLogo.png')"
                  alt="Document Translation"
                  class="icon-img"
                />
              </div>
              <div class="card-lower">
                <span class="title">{{
                  languages[currentLanguage]?.businessrefinement
                }}</span>
                <div class="description-container">
                  <p class="description">
                    {{ languages[currentLanguage]?.businessrefinementprice }}
                  </p>
                </div>
              </div>
              <!-- Tooltip content -->
              <v-tooltip
                v-model="tooltips.business_refinement"
                :style="tooltipPosition"
                class="custom-tooltip"
              >
                <span>{{ languages[currentLanguage]?.businesstooltip }}</span>
              </v-tooltip>
            </v-card>
          </router-link>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="d-flex apps-card">
          <router-link
            :to="{ name: 'literaturerefinement' }"
            style="text-decoration: none"
          >
            <v-card
              @mouseover="show($event, 'literature_refinement')"
              @mouseout="hide('literature_refinement')"
              class="card"
              outlined
              link
            >
              <div class="card-upper-refinement">
                <img
                  :src="require('@/assets/LiteratureLogo.png')"
                  alt="Document Translation"
                  class="literature-icon-img"
                />
              </div>
              <div class="card-lower">
                <span class="title">{{
                  languages[currentLanguage]?.literaturerefinement
                }}</span>
                <div class="description-container">
                  <p class="description">
                    {{ languages[currentLanguage]?.literaturerefinementprice }}
                  </p>
                </div>
              </div>
              <!-- Tooltip content -->
              <v-tooltip
                v-model="tooltips.literature_refinement"
                :style="tooltipPosition"
                class="custom-tooltip"
              >
                <span>{{ languages[currentLanguage]?.literaturetooltip }}</span>
              </v-tooltip>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="text-h5">{{
            languages[currentLanguage]?.pleaselogin
          }}</v-card-title>
          <v-card-text>
            {{ languages[currentLanguage]?.pleaselogintext }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="login">{{
              languages[currentLanguage]?.loginorsignup
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="errordialog" persistent max-width="500">
        <v-card>
          <v-card-title class="text-h5">{{
            languages[currentLanguage]?.emailverification
          }}</v-card-title>
          <v-card-text>
            {{ languages[currentLanguage]?.emailtext }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" @click="handleDialogClose">{{
              languages[currentLanguage]?.close
            }}</v-btn>
            <v-btn
              :disabled="cooldown"
              color="green darken-1"
              @click="resendVerificationEmail"
            >
              <!-- If in cooldown, display the remaining time, otherwise show the resend label -->
              {{
                cooldown
                  ? `${remainingTime}s`
                  : languages[currentLanguage]?.resendemail
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
  <CustomAlert
    v-if="loginError"
    :message="loginError"
    type="error"
  ></CustomAlert>
  <ReferralCodeDialog ref="referralDialog" />
</template>

<style scoped>
/* Overall styling */
.page-container {
  color: black; /* white text for contrast */
  margin-top: 0%;
  box-sizing: border-box;
  min-height: 100%;
  padding-left: 16%; /* Adjust the percentage as per your requirement */
  padding-right: 7%;
  padding-top: 3%;
}

.card {
  background-color: white;
  color: #161519;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 20px;
  width: 16em;
  height: 16em;
  margin-bottom: 4em;
}

.card-upper-free {
  flex: 1.4;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 14px;
  background: #e2fce7;
}
.card-upper {
  flex: 1.4;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 14px;

  background: rgb(217, 246, 255);
}
.card-upper-refinement {
  flex: 1.4;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 14px;
  background: rgb(205, 221, 246);
}

.card-lower {
  flex: 1;
  display: flex;
  text-align: left;
  padding: 14px;
  width: 100%;
}

.text-decoration-none {
  text-decoration: none;
}

.header {
  margin-bottom: 0.5em;
  color: black;
  font-size: 1.6em;
}
/* Title and other text */
.title {
  margin: 0;
  font-size: 1.2em;
  color: black;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
}
.align-start {
  justify-content: flex-start;
}

.description-container {
  position: absolute; /* Position it absolutely within card-lower */
  bottom: 10px;
  left: 20px;
}

.description-free {
  background-color: gold;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
}
.description {
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 5px;
}

h1 {
  color: #fdfdfd; /* white text */
  font-family: 'Red Hat Display', sans-serif;
}

/* Card hover effect */
.apps-card {
  cursor: pointer;
}
.apps-card:hover .card {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); /* slightly more prominent shadow on hover */
}

/* Other styles */

@media (max-width: 600px) {
  .apps-card {
    margin: 0; /* adjust as needed */
  }
}

/* Dialog Box */
v-card {
  background-color: #3a3a3a; /* slightly lighter gray for the card */
}
v-btn {
  background-color: #00e8be; /* same teal color for the button */
  color: #ffffff; /* white text for the button */
}
v-btn:hover {
  background-color: #00e8be; /* slightly darker teal on hover */
}
.icon-img {
  width: 35%;
  height: 60%;
  margin-top: -1em;
}
.icon-img-recommendation {
  width: 35%;
  height: 65%;
  margin-top: -1em;
}
.literature-icon-img {
  width: 45%;
  height: 85%;
  margin-top: -0.5em;
}

.review-icon-img {
  width: 35%;
  height: 70%;
  margin-top: -1em;
  margin-left: 1.4em;
}
</style>

<script>
import { ref, watch, onMounted, inject } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import CustomAlert from '@/components/CustomAlert.vue';
import ReferralCodeDialog from '@/components/ReferralCodeDialog.vue';
import axios from 'axios';
export default {
  components: {
    ReferralCodeDialog,
    CustomAlert,
  },
  setup() {
    const loginError = ref(null);
    const currentLanguage = inject('currentLanguage');
    const languages = inject('languages');
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    const dialog = ref(false);
    const errordialog = ref(false);
    const referralDialog = ref(null);
    // New data property to control tooltip visibility
    const tooltips = ref({
      paper_review: false,
      journal_recommendation: false,
      academic: false,
      business: false,
      literature: false,
      academic_refinement: false,
      business_refinement: false,
      literature_refinement: false,
    });

    const tooltipPosition = ref({ top: '0px', left: '0px' });
    const cooldown = ref(false);
    const remainingTime = ref(0);
    let cooldownTimer;

    const startCooldown = () => {
      cooldown.value = true;
      remainingTime.value = 60; // Set initial countdown time to 60 seconds

      // Start an interval to update the remaining time every second
      cooldownTimer = setInterval(() => {
        remainingTime.value--;

        if (remainingTime.value <= 0) {
          cooldown.value = false;
          clearInterval(cooldownTimer); // Clear the interval when countdown reaches 0
        }
      }, 1000);
    };
    // New methods to show and hide tooltip
    const show = (event, cardName) => {
      tooltips.value[cardName] = true;
      tooltipPosition.value = adjustTooltipPosition(event);
    };

    const adjustTooltipPosition = (event) => {
      return {
        top: event.clientY + window.scrollY + 10 + 'px',
        left: event.clientX + window.scrollX + 10 + 'px',
      };
    };

    const hide = (cardName) => {
      tooltips.value[cardName] = false;
    };
    const handleDialogClose = () => {
      logout({ returnTo: window.location.origin });
      errordialog.value = false;
    };

    console.log('Initial isAuthenticated value:', isAuthenticated.value);

    watch(isAuthenticated, (newValue) => {
      console.log('isAuthenticated watch triggered. New value:', newValue);
      dialog.value = !newValue; // Update dialog based on authentication status

      // If the user is authenticated, fetch the user profile
      if (newValue) {
        referralDialog.value.fetchUserProfile();
      }
    });

    async function login() {
      try {
        await loginWithRedirect();
        // If the login process is initiated successfully, set the user ID to localStorage
      } catch (error) {
        loginError.value = error.message;
      }
    }

    const resendVerificationEmail = async () => {
      console.log('user: ', localStorage.getItem('tempUserId'));
      let userId;
      if (user.value && user.value.sub) {
        userId = user.value.sub;
      } else {
        userId = localStorage.getItem('tempUserId');
      }
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/public/user/resendVerificationEmail`,
          { userId: userId },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        startCooldown();
        console.log('Verification email status:', response.data);
      } catch (error) {
        console.error('Failed to send verification email:', error);
      }
    };

    // This will run once after the component is mounted
    onMounted(() => {
      console.log(
        'Component mounted. isAuthenticated value:',
        isAuthenticated.value
      );
      window.addEventListener('resize', adjustTooltipPosition);

      const urlParams = new URLSearchParams(window.location.search);
      const errorDescription = urlParams.get('error_description');

      if (errorDescription) {
        console.log(errorDescription);
        const userIdPattern = /UserID: (auth0\|\w+)/;
        const match = errorDescription.match(userIdPattern);

        if (match && match[1]) {
          const userId = match[1];
          localStorage.setItem('tempUserId', userId);
        }

        errordialog.value = true;
        loginError.value = errorDescription;
        console.log('error message: ', loginError.value);
      }
    });

    return {
      errordialog,
      dialog,
      handleDialogClose,
      login,
      isAuthenticated: ref(isAuthenticated.value),
      currentLanguage,
      languages,
      tooltips,
      tooltipPosition,
      show,
      hide,
      referralDialog,
      resendVerificationEmail,
      cooldownTimer,
      startCooldown,
      cooldown,
      remainingTime,
    };
  },
};
</script>
