<template>
  <div>
    <!-- Vuetify Dialog for referral code -->
    <v-dialog v-model="showDialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="headline">{{
          pagelanguages[currentpageLanguage]?.doyouhavecode
        }}</v-card-title>
        <v-alert
          v-if="message"
          :type="messageType"
          style="position: relative; visibility: visible; display: block"
          >{{ message }}</v-alert
        >
        <v-card-text>
          <v-text-field
            v-model="referralCode"
            label="Referral Code"
            @input="capitalizeInput"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue darken- 1"
            text
            @click="submitReferralCode(false)"
            >{{ pagelanguages[currentpageLanguage]?.idonthaveone }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="submitReferralCode(true)">{{
            pagelanguages[currentpageLanguage]?.submit
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="successDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">{{
          pagelanguages[currentpageLanguage]?.success
        }}</v-card-title>
        <v-card-text>
          {{ successMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken- 1" text @click="successDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted, computed, inject } from 'vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'ReferralCodeDialog',

  setup() {
    const referralCode = ref('');
    const showDialog = ref(false);
    const successDialog = ref(false);
    const successMessage = ref('');
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const message = ref('');
    const messageType = computed(() => {
      return message.value.includes('successfully') ? 'success' : 'error';
    });

    const capitalizeInput = (event) => {
      referralCode.value = event.target.value.toUpperCase();
    };

    const fetchUserProfile = async () => {
      if (isAuthenticated.value) {
        try {
          const token = await getAccessTokenSilently();
          const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API_URL}/api/user/get`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            maxRedirects: 0,
            validateStatus: (status) => {
              return status >= 200 && status < 303; // default
            },
          });
          console.log('response: ', response.data.isNewUser);

          // If the user is a new user, show the dialog
          if (response.data.isNewUser) {
            showDialog.value = true;
          }
        } catch (error) {
          console.error(
            'Error while getting the token or fetching user profile: ',
            error
          );
          console.error('Axios error: ', error.response);
        }
      } else {
        console.log(
          'User is not authenticated, cannot retrieve the user profile.'
        );
      }
    };

    const submitReferralCode = async (hasreferral) => {
      // Logic to handle the submission of the referral code
      if (!referralCode.value) {
        referralCode.value = 'none';
      }
      console.log('Referral Code Submitted: ', referralCode.value);
      if (isAuthenticated.value) {
        try {
          const token = await getAccessTokenSilently();
          console.log('token: ', token);
          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/api/referral/get`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
              params: {
                hasReferral: hasreferral,
                referral: referralCode.value,
              },
            }
          );
          console.log('response: ', response);
          if (response.data === false) {
            message.value = 'The referral code you entered is invalid.';
          } else if (hasreferral === true) {
            //if the user entered referral code
            if (response.data.discountType === 'discount') {
              showDialog.value = false;
              successDialog.value = true;
              successMessage.value = pagelanguages[
                currentpageLanguage.value
              ]?.congratulationsDiscount.replace(
                '{discountPercentage}',
                response.data.discountPercentage
              );
            }
            if (response.data.discountType === 'reward') {
              showDialog.value = false;
              successDialog.value = true;
              successMessage.value = pagelanguages[
                currentpageLanguage.value
              ]?.congratulationsReward.replace(
                '{discountPercentage}',
                response.data.discountPercentage
              );
            }
          } else {
            // if user does not have referral code
            showDialog.value = false;
          }
          console.log('response.data:', response.data, 'message: ', message);
        } catch (error) {
          console.error('Error while validating refferal: ', error);
          console.error('Axios error: ', error.response);
        }
      } else {
        console.log(
          'User is not authenticated, cannot retrieve the user profile.'
        );
      }
    };

    // Fetch user profile when the component mounts
    onMounted(() => {
      fetchUserProfile();
    });

    return {
      referralCode,
      showDialog,
      submitReferralCode,
      fetchUserProfile,
      message,
      messageType,
      successMessage,
      successDialog,
      capitalizeInput,
      currentpageLanguage,
      pagelanguages,
    };
  },
};
</script>

<style scoped></style>
