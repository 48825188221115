const locale_ja = {
    //-------------------------------------------------------------------
    //mydashboard and appheader
    "home": "ホーム",
    "support": "サポート",
    "logout": "ログアウト",
    "mydashboard": "マイダッシュボード",
    "account": "アカウント",
    "loginorsignup": "ログイン/サインアップ",
    "myapps": "マイアプリ",
    "literaturepublication": "文学出版",
    "texttranslation": "テキスト翻訳",
    "voicetranslation": "音声翻訳",
    "researchai": "研究AI",
    "myproject": "マイプロジェクト",
    "pleaselogin": "ログインしてください",
    "pleaselogintext": "アクセスするにはログインが必要です。",
    "useraccount": "ユーザーアカウント",
    "buymorecredits": "クレジットを購入",
    // ------------------------------------------------------------------
    //documenttranlsation
    "projectname": "プロジェクト名：",
    "draganddrop": "ここにファイルをドラッグ＆ドロップ",
    "filelimit": "ファイルあたりの制限は200MB。DOCX、TXT",
    "browsefiles": "ファイルを参照",
    "uploading": "アップロード中...",
    "uploadedfile": "アップロードされたファイル：",
    "removefile": "ファイルを削除",
    "targetlanguage": "目的の言語",
    "subject": "科目",
    "disciplines": "分野",
    "improvemywriting": "私の書き込みを改善する",
    "translate": "翻訳",
    "login": "ログイン",
    "close": "閉じる",
    "taskcreated": "タスクが作成されました。完了までに1時間程度かかる可能性があります。終了するとメールで通知されます。また、「My Download」でステータスを確認することもできます。",

    "gotomyproject": "マイプロジェクトへ",
    "cancel": "キャンセル",
    "yes": "はい",
    "no": "いいえ",
    "invalidfile": "無効なファイルタイプ",
    "filesallowed": "DOCX、およびTXTファイルのみが許可されています",
    "success": "成功",
    "uploadsuccess": "ファイルのアップロードに成功しました！",
    "error": "エラー",
    "uploaderror": "ファイルのアップロードエラー、後でもう一度お試しください。",
    "taskerror": "タスクの作成中にエラーが発生しました、後でもう一度お試しください。",
    "unexpectederror": "予期しないエラーが発生しました。後でもう一度お試しください。",
    "nofileerror": "翻訳する前にファイルをアップロードしてください。",
    "nosubject": "翻訳する前に目的の言語と翻訳の主題を選択してください。",
    "nocredit": "クレジットが不足しています",
    "confirm": "確認",
    "translationCostMessage": "翻訳の費用は{price}クレジットになります。{remaining}クレジットが残っています。続行しますか？",
    "nocreditMessage": "あなたは{credit}クレジットを持っていますが、この翻訳のコストは{cost}クレジットです。{needed}クレジットがさらに必要です。クレジットを追加購入しますか？",
    //---------------------------------------------------------------------------------
    "selectproject": "プロジェクトを選択するか、新しいプロジェクトを作成してください",
    "tableprojectname": "プロジェクト名",
    "tablecreationtime": "作成時間",
    "actions": "アクション",
    "search": "検索",
    "createproject": " 新しいプロジェクトを作成",
    "select": " 選択",
    //---------------------------------------------------------------------------------------------
    "openproject": "プロジェクトを開く",
    "warning": "警告",
    "writingimprovement": "ライティングの改善",
    "confirmdetail": "タスクの詳細を確認してください",
    "translationtype": "翻訳タイプ",
    "inputtext": "入力テキスト",
    "outputtext": "出力テキスト",
    //------------------------------------------------------------------------------------------------
    "doctexttranslation": "ドキュメント/テキスト翻訳",
    "taskname": "タスク名",
    "taskstatus": "タスクステータス",
    "feedback": "フィードバック",
    "delete": "削除",
    "download": "ダウンロード",
    "invoice": "請求書",
    "rename": "名前の変更",
    //-------------------------------------------------------------------------------------------
    "userdetails": "ユーザー詳細",
    "supportemail": "質問がある場合は、support@culture-links.com までお問い合わせください",
    "credits": "クレジット",
    "getmorecredit": "クレジットをもっと手に入れる",
    "yourcurrentbalance": "現在の残高（100クレジット = $1）",
    "purchasemorecredits": "クレジットを購入する",
    "entercredits": "クレジットの金額を入力してください（$1 = 100クレジット）",
    "checkout": "チェックアウト",
    //-------------------------------------------------------------------------------------------------------------
    "doctranslationrefinement": "ドキュメント翻訳 & 改良",
    "includestranslationandrefinement": "翻訳と改良を含む",
    "userefinementnextsection": "記事をリファインしたいだけの場合、次のセクションの‘ドキュメントリファインメント’を使用してください",
    "forscholars": "学者と研究者向け",
    "fororganizations": "組織とコンサルタント向け",
    "forwriters": "クリエイティブな個人の作家向け",
    "comingsoon": "（近日公開）",
    "emailverification": "メール確認",
    "emailtext": "確認リンクがあなたの受信箱に送信されました。見つからない場合は、迷惑メールフォルダを確認してください。必要な場合は、再送ボタンをクリックしてください。",
    "resendemail": "メールを再送",
    "refinementtype": "リファインメントタイプ：",
    "type": "タイプ",
    "filename": "ファイル名",
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    "ordersuccess": "ご利用いただきありがとうございます！お支払いは成功しました。質問がある場合は、メールでお問い合わせください",
    "ordercancelled": "お支払いがキャンセルされました。質問がある場合は、メールでお問い合わせください",
    "fastordersuccess": "ご利用いただきありがとうございます！お支払いは成功しました。このウィンドウを閉じて、翻訳の手続きに戻ってください。質問がある場合は、メールでお問い合わせください",
    "backtohome": "ホームに戻る",
    "namerequired": "プロジェクト名は必須です",
    "namelength": "プロジェクト名は20文字以下でなければなりません",
    "create": "作成",
    "createnewproject": "新しいプロジェクトを作成する",
    "deleteprojecttext": "この操作は、このプロジェクトのすべてのタスクを削除します。このプロジェクトを削除してもよろしいですか？",
    "howtouseit": "どのように使用しますか？",
    "technicalsupport": "技術サポート",
    "userguide": "ユーザーガイド",
    "technicalsupport_url": "https://culture-links.com/technical-support-japanese/",
    "userguide_url": "https://culture-links.com/user-guide-japanese/",
    //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
    "closethiswindow": "このウィンドウを閉じる",
    "deleteconfirm": "タスクを一度削除すると、それを取り戻す方法はありません。進行しますか？",
    "deleteerror": "タスクを削除する際にエラーが発生しました。",
    "deletesuccess": "タスクは正常に削除されました",
    //---------------------------------------------------------------------------------------------------------------
    "uploadfile": "ファイルをアップロード",
    "or": "または",
    "chooseexistingfile": "以前にアップロードしたファイルを選択",
    "submit": "提出",
    "academicrefinement": "学術論文の改善",
    "businessrefinement": "ビジネス文書の改善",
    "literaturerefinement": "文学作品の改善",
    "feedback_url": "https://culture-links.com/feedback-translation-japanese",
    //------------------------------------------------------------------------------------
    "mydownload": "マイダウンロード",
    "downloadinstruction": "タスクが完了すると、ここに表示されます。進行状況も確認できます: ",
    //--------------------------------------------------------------------------------------------------
    "advertisingbar": "タスクが完了した後、10％のクレジット報酬を受け取ります！",
    "academictooltip": "使用例：医療・健康、社会科学（例：心理学、社会学）、人文科学（例：歴史、哲学）、エンジニアリング・テクノロジー、数学・統計、ビジネス・マネジメント、地球・宇宙、特許申請",
    "businesstooltip": "使用例：契約書・合意書、法的文書・資格、広告、製品情報、ユーザーガイド、投資計画、監査報告、研究報告、調達・供給",
    "literaturetooltip": "使用例：小説（例：ファンタジー、ロマンス、冒険）、ノンフィクション（例：伝記）、詩、子供の本、演劇、ウェブ記事、正式な手紙",
    //-----------------------------------------------------------------------------------------------------------------------------------
    "signup": "サインアップ",
    //----------------------------------------------------------------------------------------------
    "totalcredits": "合計クレジット（購入クレジット + 報酬クレジット）",
    "purchasedcredits": "購入クレジット ($1 = 100 クレジット)",
    "rewardcredits": "報酬クレジット",
    //---------------------------------------------------------------------------------------------
    "congratulationsDiscount": "おめでとうございます！これから、翻訳タスクで{discountPercentage}%パーセントの割引を受けることができます！",
    "discountInfo": "翻訳タスクで{discountPercentage}パーセントの割引を受けることができます！",
    "congratulationsReward": "おめでとうございます！これから、翻訳タスクで{discountPercentage}%パーセントのクレジット報酬を受けることができます！",
    "rewardInfo": "タスクが完了すると、{discountPercentage}%パーセントのクレジット報酬を受け取ることができます！",
    "doyouhavecode": "紹介コードをお持ちですか？こちらに入力してください：",
    "idonthaveone": "持っていません",
    //------------------------------------------------------------------------------------------------------------
    "octpromo": "このプロモーションコードを使用して20％オフになります：OCT20OFF",
    "pricedetails": "価格の詳細",
    "originaltaskprice": "元のタスク価格：",
    "taskprice": "タスクの価格：",
    "discountpercentage": "割引率",
    "priceafterdiscount": "割引後の価格：",
    "rewardbackpercentage": "リワード返却率：",
    "promocode": "プロモーションコード：",
    "yourbalance": "あなたの残高：",
    "rewardcredit": "報酬クレジット：",
    "purchasedcredit": "購入したクレジット：",
    "totalcredit": "合計クレジット：",
    "ordertotal": "注文合計：",
    "apply": "適用",
    "promoapplied": "プロモーションコードが適用されました",
    //--------------------------------------------------------------------------------------------------------------------------
    "academicprice": "$0.01/語",
    "businessprice": "$0.005/語",
    "literatureprice": "$0.005/語",
    "academicrefinementprice": "$0.008/語",
    "businessrefinementprice": "$0.004/語",
    "literaturerefinementprice": "$0.004/語",
    //--------------------------------------------------------------------------------------
    "processing": "処理中...",
    "takesfewminutes": "これには数分かかるかもしれません。このウィンドウを閉じても、後で「My Download」から結果をダウンロードできます。",
    "gotodownload": "ダウンロードページへ",
    "requestlimit": "30日間に4回のリクエストしか送信できませんので、後でもう一度お試しください",
    "gentlereminder": "優しいリマインダー",
    "uptofour": "30日間に最大4回のレビュー",
    "wordlimit": "(<= 10,000語、1つの論文あたり)",
    "paperreview": "文書レビュー",
    "free": "無料",
    //----------------------------------------------------------------------------------------
    "reviewtooltip": "30日間に最大4回のリクエスト（ドキュメントあたり10,000語まで）",
    "recommendationtooltip": "30日間に最大8回のリクエスト（ドキュメントあたり2,000語まで）",
    "journalrecommendation": "ジャーナルの推薦",
    "documentreview": "無料の学術サービス",
    "status": "ステータス",
    //----------------------------------------------------------------------
    "refinementonlyapps": "ドキュメントのリファインメント",
    "academicpapers": "学術翻訳",
    "businessdocuments": "ビジネス翻訳",
    "literature": "文学翻訳",
    "reward": "リワードクレジット",
    "purchased": "購入したクレジット"

   




    




}

export default locale_ja;
