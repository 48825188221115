<template>
  <header class="c-nav-bar">
    <div class="links-container" v-if="!isSmallScreen">
      <router-link to="/" class="nav-link">{{
        languages[currentLanguage]?.home
      }}</router-link>
      <div class="dropdown" @mouseleave="showSupportDropdown = false">
        <button class="nav-link" @mouseover="showSupportDropdown = true">
          {{ languages[currentLanguage]?.support }}
        </button>

        <div class="dropdown-content" v-if="showSupportDropdown">
          <a
            :href="languages[currentLanguage]?.technicalsupport_url"
            target="_blank"
            class="nav-link"
          >
            {{ languages[currentLanguage]?.technicalsupport }}
          </a>
          <a
            :href="languages[currentLanguage]?.userguide_url"
            target="_blank"
            class="nav-link"
          >
            {{ languages[currentLanguage]?.userguide }}
          </a>
          <a
            :href="languages[currentLanguage]?.feedback_url"
            target="_blank"
            class="nav-link"
          >
            {{ languages[currentLanguage]?.feedback }}
          </a>
        </div>
      </div>

      <select
        class="nav-link language-dropdown"
        v-model="selectedLanguage"
        @change="switchLanguage"
      >
        <option
          v-for="(translations, langCode) in languages"
          :key="langCode"
          :value="langCode"
        >
          {{ languageNames[langCode] }}
        </option>
      </select>
    </div>
    <div class="smallscreen" v-else>
      <v-btn class="menu-icon" icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <select
        class="nav-link language-dropdown"
        v-model="selectedLanguage"
        @change="switchLanguage"
      >
        <option
          v-for="(translations, langCode) in languages"
          :key="langCode"
          :value="langCode"
        >
          {{ languageNames[langCode] }}
        </option>
      </select>
    </div>

    <div class="logo-container">
      <a href="https://culture-links.com/" class="logo-link">
        <img :src="require('@/assets/DarkLogo.png')" alt="Logo" />
      </a>
    </div>

    <div class="user-section" v-if="isLoggedIn && !isSmallScreen">
      <button class="nav-button" @click="logout">
        {{ languages[currentLanguage]?.logout }}
      </button>
      <router-link to="/mydownload" class="nav-link">{{
        languages[currentLanguage]?.mydownload
      }}</router-link>
      <router-link to="/useraccount" class="nav-link">{{
        languages[currentLanguage]?.account
      }}</router-link>
    </div>

    <div class="user-section" v-else-if="!isSmallScreen">
      <button class="nav-button" @click="login">
        {{ languages[currentLanguage]?.login }}
      </button>
      <button class="nav-button" @click="signup">
        {{ languages[currentLanguage]?.signup }}
      </button>
    </div>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      overlay
      @overlay-click="drawer = false"
    >
      <v-list-item @click="navigateTo('/')">
        <v-list-item-title>{{
          languages[currentLanguage]?.home
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="navigateTo(languages[currentLanguage]?.technicalsupport_url)"
      >
        <v-list-item-title>{{
          languages[currentLanguage]?.technicalsupport
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="navigateTo(languages[currentLanguage]?.userguide_url)"
      >
        <v-list-item-title>{{
          languages[currentLanguage]?.userguide
        }}</v-list-item-title>
      </v-list-item>

      <!-- Login/Logout and account related links -->
      <div v-if="isLoggedIn">
        <v-list-item link to="mydownload">
          <v-list-item-title>{{
            languages[currentLanguage]?.mydownload
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item link to="useraccount">
          <v-list-item-title>{{
            languages[currentLanguage]?.useraccount
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item link to="buymorecredits">
          <v-list-item-title>{{
            languages[currentLanguage]?.buymorecredits
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-title>{{
            languages[currentLanguage]?.logout
          }}</v-list-item-title>
        </v-list-item>
      </div>
      <div v-else>
        <v-list-item @click="login">
          <v-list-item-title>{{
            languages[currentLanguage]?.loginorsignup
          }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-navigation-drawer>
  </header>

  <custom-alert ref="customAlert"></custom-alert>
  <custom-alert v-if="loginError" :message="loginError"></custom-alert>
  <hr class="section-divider-1" />

  <!-- Notice Bar -->
  <div v-if="showNotice" class="notice-bar">
    <span>{{ languages[currentLanguage]?.advertisingbar }}</span>
    <button @click="dismissNotice">X</button>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watchEffect, inject, watch } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import CustomAlert from '@/components/CustomAlert.vue';

export default {
  components: {
    CustomAlert,
  },
  setup() {
    const loginError = ref(null);
    const screenWidth = ref(window.innerWidth);
    const { isAuthenticated, user, isLoading, loginWithRedirect, logout } =
      useAuth0();
    const isLoggedIn = ref(false);
    const showDropdown = ref(false);
    const showSupportDropdown = ref(false);
    const drawer = ref(false);
    const auth0 = useAuth0();
    const customAlert = ref(null);
    const languages = inject('languages');
    const currentLanguage = inject('currentLanguage');
    const selectedLanguage = ref(currentLanguage.value);
    const showNotice = ref(true); // reactive reference for controlling the visibility of the notice bar
    const dismissNotice = () => {
      showNotice.value = false; // hide the notice bar
    };
    const switchLanguage = () => {
      currentLanguage.value = selectedLanguage.value;
      localStorage.setItem('selectedLanguage', selectedLanguage.value);
    };
    const languageNames = {
      en: 'English(EN)',
      cn: '简体中文(zh-CN)',
      zh: '繁體中文(zh-HK)',
      ja: '日本語(JA)',
    };

    const navigateTo = (url) => {
      window.location.href = url;
    };
    watch(currentLanguage, (newValue) => {
      localStorage.setItem('selectedLanguage', newValue);
    });

    const refreshAccessToken = async () => {
      try {
        await auth0.getAccessTokenSilently();
      } catch (error) {
        // Token refresh failed, log the user out
        console.log('logged out');
        logout({
          returnTo: window.location.origin + '/',
        });

        customAlert.value.openAlert(
          'Time out',
          'Your session has expired. Please log in again.'
        );
      }
    };

    // This watches the isAuthenticated state
    watchEffect(() => {
      if (!isLoading.value && isAuthenticated.value) {
        // If the user is authenticated, attempt to refresh the access token
        refreshAccessToken();
      }
    });

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
      const storedLang = localStorage.getItem('selectedLanguage');
      if (storedLang) {
        currentLanguage.value = storedLang;
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    function updateWidth() {
      screenWidth.value = window.innerWidth;
    }

    watchEffect(() => {
      if (!isLoading.value) {
        isLoggedIn.value = isAuthenticated.value;
      }
    });

    async function login() {
      try {
        console.log('login triggered ');
        await loginWithRedirect();
      } catch (error) {
        console.log('login error: ', error.message);
        loginError.value = error.message;
      }
    }
    async function signup() {
      try {
        console.log('signup triggered');
        await loginWithRedirect({
          authorizationParams: {
            screen_hint: 'signup',
          },
        });
      } catch (error) {
        console.log('signup error: ', error.message);
        loginError.value = error.message;
      }
    }

    return {
      user,
      isLoggedIn,
      login,
      signup,
      logout,
      showDropdown,
      showSupportDropdown,
      drawer,
      screenWidth,
      customAlert,
      switchLanguage,
      languages,
      currentLanguage,
      selectedLanguage,
      languageNames,
      navigateTo,
      showNotice,
      dismissNotice,
    };
  },
  computed: {
    isSmallScreen() {
      return this.screenWidth < 1100;
    },
  },
  methods: {},
};
</script>

<style scoped>
.logo-link {
  text-decoration: none;
  display: flex;
  align-items: center;
}
@media (max-width: 600px) {
  .logo-link {
    margin-top: 2.8em;
  }
}
.c-nav-bar {
  display: flex;
  width: 100%;
  height: 4em;
  padding: 0.5em 5vw;
  justify-content: stretch;
  align-items: center;
  position: fixed; /* Changed from absolute to fixed */
  top: 0; /* Aligns the header to the top of the viewport */
  left: 0; /* Aligns the header to the left of the viewport (optional, since width is already 100%) */
  z-index: 999;
  background: var(--neutral-black, #161519);
}

.logo-container {
  display: flex;
  width: 11em;
  height: 2em;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.logo-container img {
  width: 9.6em; /* Set the width as needed */
  height: 1.8em; /* Adjust the height proportionately if needed */
}

.links-container {
  display: flex;
  gap: 2em;
  padding: 0.5em;
  align-items: center;
  position: absolute;
  left: 2rem;
}
.user-section {
  display: flex;
  gap: 2em;
  padding: 0.5em;
  align-items: center;
  position: absolute;
  right: 2rem;
}

.nav-link {
  color: #fdfdfd;
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
}

.nav-button {
  display: flex;
  min-width: 4em;
  min-height: 2.5em;
  padding: 0.75em 1em;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  background: #00e8be;
  color: #fdfdfd;
  border: none;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
}
.menu-icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin-right: 0.5em;
  transform: translateY(-50%);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 8em;
  box-shadow: 0px 0.5em 1em 0px rgba(0, 0, 0, 0.2);
  padding: 0.5em 0.5em;
  z-index: 1;
  right: -2em; /* Start from the right side of the .dropdown */
}

.dropdown-content a {
  color: #161519;
  text-decoration: none;
  display: block;
  font-size: 0.85em; /* Increase the font size */
  margin-bottom: 0.5em; /* Add space between each text */
}

.dropdown:hover .dropdown-content {
  display: block;
}
@media (max-width: 600px) {
  .c-nav-bar {
    flex-direction: column;
  }

  .links-container,
  .user-section {
    flex-direction: column;
    align-items: center;
  }
}

.language-dropdown,
.language-dropdown option {
  background-color: #161519;
  border: none;
  color: #fdfdfd;
  z-index: 998;
}

.language-dropdown:hover,
.language-dropdown option:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.language-dropdown {
  padding: 8px;
  cursor: pointer;
  outline: none;
  border: none;
  z-index: 998;
}
@media (max-width: 600px) {
  .language-dropdown {
    margin-left: -12.5em;
    margin-top: 1.2em;
  }
}
.notice-bar {
  background-color: #f0f0f0;
  padding: 10px;
  text-align: center;
  position: fixed;
  top: 4em; /* Set this to the height of your header */
  left: 0;
  width: 100%; /* Ensure the notice bar stretches across the whole screen */
  font-size: 1em;
  z-index: 997;
}

.notice-bar button {
  background: none;
  border: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
}
</style>
