<template>
    <div>
      <div v-for="file in files" :key="file.id">
        <div style="overflow-x: auto;">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>User ID</th>
                <th>File Name</th>
                <th>File Type</th>
                <th>Size</th>
                
                <th>Creation Time</th>
                <th>Upload Time</th>
                <th>Status</th>
                <th>Estimated Tokens</th>
              </tr>
            </thead>
            <tbody>
              <tr @click="file.showTasks = !file.showTasks">
                <td>{{ file.userId }}</td>
                <td>{{ file.name }}</td>
                <td>{{ file.mimetype }}</td>
                <td>{{ file.size }}</td>
                <td>{{ file.createdAt }}</td>
                <td>{{ file.uploadTime }}</td>
                <td>{{ file.status }}</td>
                <td>{{ file.estToken }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div style="overflow-x: auto;">
        <v-simple-table v-if="file.showTasks">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Task ID</th>
                <th>Status</th>
                <th>File ID</th>
                <th>Price</th>
                <th>Source Language</th>
                <th>Target Language</th>
                <th>Creation Time</th>
                <th>Prompt Tokens</th>
                <th>Completion Tokens</th>
                <th>Total Tokens</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="task in file.translationTasks" :key="task.taskId">
                <td>{{ task.id }}</td>
                <td>{{ task.status }}</td>
                <td>{{ task.fileId }}</td>
                <td>{{ task.price }}</td>
                <td>{{ task.sourceLanguage }}</td>
                <td>{{ task.targetLanguage }}</td>
                <td>{{ task.createdAt }}</td>
                <td>{{ task.prompt_tokens }}</td>
                <td>{{ task.completion_tokens }}</td>
                <td>{{ task.total_tokens }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useAuth0 } from '@auth0/auth0-vue';
  
  export default {
    name: 'TaskTable',
    data() {
      return {
        files: [],
      };
    },
    async created() {
      const auth0 = useAuth0();
  
      if (auth0.isAuthenticated.value) {
        try {
          const token = await auth0.getAccessTokenSilently();
  
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/file/get`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          // Assuming the response contains an array of files
          console.log(response.data);  // log the response data
          this.files = response.data;
        } catch (error) {
          console.error('Error while getting the token or communicating with the server: ', error);
        }
      }
    },
  };
  </script>
  <style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #4CAF50;
    color: white;
  }

  tbody tr:nth-child(even) {background-color: #f2f2f2;}

  tbody tr:hover {background-color: #ddd;}
</style>

  