<template>
    <v-dialog v-model="showLoginDialog" width="500">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ pagelanguages[currentpageLanguage]?.pleaselogin }}</span>
        </v-card-title>
        <v-card-text>{{ pagelanguages[currentpageLanguage]?.pleaselogintext }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showLoginDialog = false">{{ pagelanguages[currentpageLanguage]?.close }}</v-btn>
          <v-btn color="green darken-1" text @click="login">{{ pagelanguages[currentpageLanguage]?.login }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { ref, onMounted, inject,watch } from 'vue';
  import { useAuth0 } from '@auth0/auth0-vue';
  
  export default {
    name: "LoginDialog",
    props: {
      redirectUri: {
        type: String,
        required: true,
        default: () => window.location.origin + '/'  // Default value if none is provided
      }
    },
    setup(props, ctx) {
      const { isAuthenticated, loginWithRedirect } = useAuth0();
      const showLoginDialog = ref(false);
      const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
  
      const checkUserAuth = async () => {
        if (!isAuthenticated.value) {
          showLoginDialog.value = true;
        }
      }
  
      const login = async () => {
        try {
          await loginWithRedirect({
            redirect_uri: props.redirectUri,
          });
          showLoginDialog.value = false;
          ctx.emit('logged-in');
        } catch (error) {
          console.error('Error during login: ', error);
        }
      };
      watch(isAuthenticated, (newValue) => {
            console.log('isAuthenticated watch triggered. New value:', newValue);
            showLoginDialog.value = !newValue;  // Update dialog based on authentication status
        });
      onMounted(() => {
        checkUserAuth();
      });
  
      return {
        showLoginDialog,
        login,
        pagelanguages,
        currentpageLanguage,
      }
    }
  }
  </script>
  