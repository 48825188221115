import { createApp, ref } from 'vue';
import App from './App.vue';
import { createAuth0 } from '@auth0/auth0-vue';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import router from './router';
import '@mdi/font/css/materialdesignicons.css';
import './assets/styles.css';

// Importing language files
import en from './locales/en.js';
import cn from './locales/cn.js';
import ja from './locales/ja.js';
import zh from './locales/zh.js';

// Vuetify setup
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdi', // Use the 'mdi' iconfont (Material Design Icons)
  },
});

const storedLanguage = localStorage.getItem('selectedLanguage');
const currentLanguage = ref(storedLanguage || 'en'); // Load from localStorage or default to English// Default language

const app = createApp(App);

// Providing translations to the app
const languages = {
  en,
  cn,
  ja,
  zh,
};

function getUserGuideUrl(language) {
  switch(language) {
      case 'en':
          return '/assets/Application User Guide.docx';
      case 'ja':
          return '/assets/製品使用説明書.docx';
      case 'cn':
          return '/assets/产品使用说明书.docx';
      default:
          return '/assets/Application User Guide.docx';// default to English if not matched
  }
}

app.provide('languages', languages);
app.provide('currentLanguage', currentLanguage);
app.provide('getUserGuideUrl', getUserGuideUrl); 
// Auth0 setup
app.use(
  createAuth0({
    domain: "login.culture-links.com",
    clientId: "vQaIcEW5xMwOA8ziPOEZHYS49c5g8npX",
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : '/'
      );
    },
    authorizationParams: {
      redirect_uri: window.location.origin + '/',
      audience: `${process.env.VUE_APP_AUTH0_AUDIENCE}`,
      iss: `dev-ncpnqjoqljqksjvq.us.auth0.com`,
  }
  
  })
);

app.use(router);
app.use(vuetify);
app.mount('#app');
