<script>
import axios from 'axios';
import { ref, computed, watch, onMounted } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  props: ['vModelValue'],
  setup(props, { emit }) {
    const { getAccessTokenSilently } = useAuth0();
    const dialogModel = computed({
      get: () => props.vModelValue,
      set: (value) => emit('update:vModelValue', value),
    });
    const form = ref(null);
    const projects = ref([]);
    const projectName = ref('');
    const showCreateProjectDialog = ref(false);
    const isFormValid = computed(() => formValidationResult.value.valid);

    const formValidationResult = ref({ valid: false, errors: [] });

    const getProjects = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/project/get`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        projects.value = response.data;
      } catch (error) {
        console.error('Failed to get project: ', error);
      }
    };

    const selectProject = (project) => {
      console.log('Selected project:', project);
      dialogModel.value = false;
      emit('selected-project', project.id, project.name); // Emitting event with project id
    };

    const createProject = async () => {
      try {
        const token = await getAccessTokenSilently();
        const data = {
          name: 'MyProject',
          type: 'document',
        };
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/project/create`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        // Refresh projects list
        getProjects();

        // Close the dialog
        showCreateProjectDialog.value = false;

        console.log('Project created:', response.data);
      } catch (error) {
        console.error('Failed to create project:', error);
      }
    };
    const selectLastCreatedOrNewProject = async () => {
      try {
        // Fetch the list of projects
        await getProjects();

        // Sort projects by creation date in descending order
        const sortedProjects = [...projects.value].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        if (sortedProjects.length > 0) {
          // Select the newest project
          selectProject(sortedProjects[0]);
        } else {
          // If no projects exist, create a new one
          await createProject();

          // Fetch the list of projects again
          await getProjects();

          // Sort projects again to get the newest one
          const newSortedProjects = [...projects.value].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          // Select the newest project
          selectProject(newSortedProjects[0]);
        }
      } catch (error) {
        console.error('Failed to select or create project:', error);
      }
    };

    watch(projectName, async () => {
      console.log('projectName:', projectName.value);
      if (form.value && form.value.validate) {
        const result = await form.value.validate();
        formValidationResult.value = result;
      }
    });

    onMounted(() => {
      selectLastCreatedOrNewProject();
    });
    return {
      dialogModel,
      projects,
      selectProject,
      createProject,
      form,
      projectName,
      showCreateProjectDialog,
      isFormValid,
      selectLastCreatedOrNewProject,
    };
  },
};
</script>
