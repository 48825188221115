<template>
  <div class="container">
    <!-- Breadcrumb Trail -->
    <nav class="breadcrumb-trail">
      <span @click="$router.push('/')">{{
        pagelanguages[currentpageLanguage]?.home
      }}</span>
      <span class="separator">/</span>
      <span @click="$router.push('/useraccount')">{{
        pagelanguages[currentpageLanguage]?.useraccount
      }}</span>
    </nav>
    <v-container class="user-account padded-container">
      <v-row>
        <v-col cols="12">
          <v-card
            class="mx-auto my-12 custom-border"
            max-width="800"
            max-height="800"
          >
            <v-list two-line subheader>
              <v-subheader class="userTitle">{{
                pagelanguages[currentpageLanguage]?.userdetails
              }}</v-subheader>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="usericon">mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="userSubtitle">{{
                    userProfile.username
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="usericon">mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="userSubtitle">{{
                    userProfile.email
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="usericon">mdi-credit-card</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="userSubtitle"
                    >{{ userProfile.credits }}
                    {{ pagelanguages[currentpageLanguage]?.credits }} ({{
                      userProfile.rewardcredits
                    }}
                    {{ pagelanguages[currentpageLanguage]?.reward }} +
                    {{ userProfile.purchasedcredits }}
                    {{ pagelanguages[currentpageLanguage]?.purchased }} )
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <div class="d-flex justify-center align-center" style="height: 2em">
        <v-btn class="buyCredit-button" to="/buymorecredits">{{
          pagelanguages[currentpageLanguage]?.getmorecredit
        }}</v-btn>
      </div>
    </v-container>
  </div>
  <LoginDialog :redirectUri="specificRedirectUri" />
</template>
<style scoped>
.padded-container {
  padding-top: 3em;
}
.usericon {
  color: #2d5eff;
  font-size: 2.5em;
  padding: 0.3em;
}
.userTitle {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 2.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0.3em;
  font-weight: 300;
}
.userSubtitle {
  font-size: 1.2em;
  padding: 0.3em;
}
.buyCredit-button {
  color: white;
  background-color: #2d5eff;
  margin: auto;
  font-family: 'Red Hat Display', sans-serif;
  width: 22em;
  font-size: 1.3em;
}
.custom-border {
  border: 2px solid #cfcece; /* Change '#333' to any color you prefer for the border */
}
</style>

<script>
import { ref, onMounted, watch, inject } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import axios from 'axios';
import LoginDialog from '@/components/LoginDialog.vue';
export default {
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoginDialog,
  },
  setup() {
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const specificRedirectUri = ref(window.location.origin + '/');
    const userProfile = ref({
      email: 'new@user.com',
      username: '123456',
      credit: 0,
    });

    const fetchUserProfile = async () => {
      // Check if user is authenticated
      if (isAuthenticated.value) {
        try {
          // Get the access token
          const token = await getAccessTokenSilently();
          console.log('User is authenticated. Token: ', token);

          console.log('About to send request...');

          const response = await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API_URL}/api/credit/get`,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            maxRedirects: 0,
            validateStatus: function (status) {
              return status >= 200 && status < 303; // default
            },
          });
          console.log('response: ', response);
          if (response.status === 200) {
            userProfile.value = {
              username: user.value.nickname,
              email: user.value.name,
              credits:
                Math.ceil(response.data.rewardCredit * 100) +
                Math.ceil(response.data.balance * 100),
              rewardcredits: Math.ceil(response.data.rewardCredit * 100),
              purchasedcredits: Math.ceil(response.data.balance * 100),
            };
          } else {
            console.error('Failed to fetch user profile: ', response);
          }
        } catch (error) {
          console.error(
            'Error while getting the token or fetching user profile: ',
            error
          );
          console.error('Axios error: ', error.response);
        }
      } else {
        console.log(
          'User is not authenticated, cannot retrieve the user profile.'
        );
      }
    };

    watch(
      () => isAuthenticated.value,
      async (newValue) => {
        if (newValue) {
          await fetchUserProfile();
        }
      },
      { immediate: true }
    );

    onMounted(async () => {
      console.log('Component mounted');
      if (isAuthenticated.value) {
        await fetchUserProfile();
      }
    });

    return {
      userProfile,
      fetchUserProfile,
      pagelanguages,
      currentpageLanguage,
      specificRedirectUri,
      LoginDialog,
    };
  },
};
</script>
