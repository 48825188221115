<template>
   <div class="container">
                  <!-- Breadcrumb Trail -->
                  <nav class="breadcrumb-trail">
            <span @click="$router.push('/')">{{ pagelanguages[currentpageLanguage]?.home }}</span>
            <span class="separator">/</span>
            <span @click="$router.push('/buymorecredits')">{{ pagelanguages[currentpageLanguage]?.buymorecredits}}</span>
            </nav>
  <v-container class="buy-more-credits padded-container">     

    <v-row justify="center">
      <v-col cols="12" sm="10" md="10">
        <v-card class="mb-5">
          <v-card-title>
            <div class="headline">{{ pagelanguages[currentpageLanguage]?.totalcredits}}</div>
          </v-card-title>
          <v-card-text class="display-3 text-center">
            {{ userProfile.credits }}
          </v-card-text>
        </v-card>
        <v-card class="mb-5">
          <v-card-title>
            <div class="headline">{{ pagelanguages[currentpageLanguage]?.purchasedcredits}}</div>
          </v-card-title>
          <v-card-text class="display-3 text-center">
            {{ userProfile.purchasedcredits }}
          </v-card-text>
        </v-card>
        <v-card class="mb-5">
          <v-card-title>
            <div class="headline">{{ pagelanguages[currentpageLanguage]?.rewardcredits}}</div>
          </v-card-title>
          <v-card-text class="display-3 text-center">
            {{ userProfile.rewardcredits }} 
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>
            <div class="headline">{{ pagelanguages[currentpageLanguage]?.purchasemorecredits}}</div>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-text-field 
                v-model="customAmount" 
                :label="pagelanguages[currentpageLanguage]?.entercredits"
                type="number"
                min="1000"
                class="custom-amount-field"
              ></v-text-field>
              <v-btn class="custom-purchase-btn" @click="customPayment">{{ pagelanguages[currentpageLanguage]?.checkout}}</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="paymentDialog" max-width="500px">
      <PaymentForm :amount="creditAmountToBuy" />
    </v-dialog>

  </v-container>
</div>

<LoginDialog :redirectUri="specificRedirectUri" />
</template>

<style scoped>
.buy-more-credits{
  padding: 3em;
}
.padded-container {
  padding-top: 140px;
}

.display-3 {
  font-size: 1.6em;
  margin-top: 0.2em;
  color: #2D5EFF;
}
.custom-amount-field {
  margin-top: 1em;
  margin-bottom: 20px;
}

.custom-purchase-btn {
  width: 100%;
  height: 50px;
  font-size: 1.3em;
  font-family: 'Red Hat Display', sans-serif;
  background-color: #00E8BE;
}
.headline{
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.3em;
}

.mb-4 {
  margin-bottom: rem;
}
</style>
    
    
    
    
      
    <script>
     import { ref,onMounted, watch,  inject } from 'vue';
     import { useAuth0 } from '@auth0/auth0-vue';
     import axios from 'axios';
     import LoginDialog from '@/components/LoginDialog.vue';
     export default {
      props: {
        amountNeeded: {
          type: Number,
          default: 1000, 
        },
      },

      components: {
        LoginDialog
      },
         setup(props) {
            const { isAuthenticated, getAccessTokenSilently } = useAuth0();
            const customAmount = ref(props.amountNeeded || 1000);
            const currentpageLanguage = inject('currentLanguage');
            const pagelanguages = inject('languages');
            const specificRedirectUri = ref(window.location.origin + '/');
            const customPayment = () => {
            if (customAmount.value >= 1000) {
                const quantity = customAmount.value;
                openCheckoutPage(quantity, 'price_1NijNSJ40WTpEtgla3XIcD3R'); // using the same priceID as in your fixed price options
            } else {
                alert("Please enter a minimum amount of 1000 credits.");
            }
            }
            const userProfile = ref({
                email: 'new@user.com',
                username: '123456',
                credit: 0,
            });

      const fetchUserProfile = async () => {
        // Check if user is authenticated
        if (isAuthenticated.value) {
          try {
              // Get the access token
              const token = await getAccessTokenSilently();
              console.log('User is authenticated. Token: ', token);

              console.log('About to send request...');

              const response = await axios({
                  method: 'get',
                  url: `${process.env.VUE_APP_API_URL}/api/credit/get`,
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`
                  },
                  maxRedirects: 0,
                  validateStatus: function (status) {
                      return status >= 200 && status < 303; // default
                  },
              });

              if (response.status === 200){
                  userProfile.value = {
                      userId: response.data.userId,
                      credits: Math.ceil((response.data.rewardCredit)*100) + Math.ceil((response.data.balance)*100), 
                      rewardcredits: Math.ceil((response.data.rewardCredit)*100), 
                      purchasedcredits: Math.ceil((response.data.balance)*100), 
                  };
              } else {
                  console.error('Failed to fetch user profile: ', response);
              }

          } catch (error) {
              console.error('Error while getting the token or fetching user profile: ', error);
              console.error('Axios error: ', error.response);
          }
        } else {
          console.log('User is not authenticated, cannot retrieve the user profile.');
        }
      };
        const openCheckoutPage = async (quantity, stripePriceID) => {
        // Check if user is authenticated
        if (isAuthenticated.value) {
            try {
                console.log(`Opening Checkout Page for ${quantity} credits...`);
    
                // Get the access token
                const token = await getAccessTokenSilently();
                console.log('User is authenticated. Token: ', token);
    
                const successUrl = window.location.origin + '/order/success';
                const cancelUrl = window.location.origin + '/order/cancelled';
    
                console.log('About to send request...');
    
                const response = await axios({
                    method: 'post',
                    url: `${process.env.VUE_APP_API_URL}/api/credit/createStripeSession`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    data: {
                        quantity: quantity,
                        stripePriceId: stripePriceID,
                        success_url: successUrl,
                        cancel_url: cancelUrl
                    },
                    maxRedirects: 0,
                    validateStatus: function (status) {
                        return status >= 200 && status < 303; // default
                    },
                });
    
                if (!response.ok){
                    // handleError
                }
                const redirectUrl = response.data.url;
                window.location.href = redirectUrl;
            } catch (error) {
                console.error('Error while getting the token or opening checkout page: ', error);
                console.error('Axios error: ', error.response);
                alert("An error occuered when creating payment section, please try again later");
            }
        } else {
            console.log('User is not authenticated, cannot retrieve the access token.');
        }
    }
    watch(() => isAuthenticated.value, async (newValue) => {
                if (newValue) {
                  await fetchUserProfile();
                }
              }, { immediate: true });

      onMounted(async () => {
        console.log('Component mounted');
        if (isAuthenticated.value) {
          await fetchUserProfile();
        }
      });

    
             return {
              
              openCheckoutPage,
              customAmount,
              customPayment,
              userProfile,
              fetchUserProfile,
              pagelanguages,
              currentpageLanguage,
              specificRedirectUri,
              LoginDialog
              
          };
         },
     };
    </script>


      
      