<template>
  <v-dialog v-model="localDialog" width="800">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{
          pagelanguages[currentpageLanguage]?.pricedetails
        }}</span>
      </v-card-title>

      <v-card-text>
        <!-- Show Original Task Price if discountPercentage > 0 -->
        <v-row v-if="discountPercentage > 0">
          <v-col cols="6">{{
            pagelanguages[currentpageLanguage]?.originaltaskprice
          }}</v-col>
          <v-col cols="6"
            >{{ floatPriceBeforeDiscount }}
            {{ pagelanguages[currentpageLanguage]?.credits }}</v-col
          >
        </v-row>

        <!-- Show Task Price, can be original or discounted depending on discountPercentage -->
        <v-row v-if="discountPercentage == 0">
          <v-col cols="6"
            >{{ pagelanguages[currentpageLanguage]?.taskprice }}
          </v-col>
          <v-col cols="6"
            >{{ floatPrice }}
            {{ pagelanguages[currentpageLanguage]?.credits }}</v-col
          >
        </v-row>

        <!-- Show Discount Percentage if it's > 0 -->
        <v-row v-if="discountPercentage > 0">
          <v-col cols="6">{{
            pagelanguages[currentpageLanguage]?.discountpercentage
          }}</v-col>
          <v-col cols="6">{{ discountPercentage }}%</v-col>
        </v-row>

        <v-row v-if="discountPercentage > 0">
          <v-col cols="6">{{
            pagelanguages[currentpageLanguage]?.priceafterdiscount
          }}</v-col>
          <v-col cols="6"
            >{{ floatPrice }}
            {{ pagelanguages[currentpageLanguage]?.credits }}</v-col
          >
        </v-row>

        <v-row>
          <v-col cols="6">{{
            pagelanguages[currentpageLanguage]?.rewardbackpercentage
          }}</v-col>
          <v-col cols="6">{{ rewardBackPercentage }}%</v-col>
        </v-row>

        <v-row>
          <v-alert v-if="message" :type="messageType" class="custom-alert">{{
            message
          }}</v-alert>
        </v-row>
        <v-row>
          <v-col cols="6">{{
            pagelanguages[currentpageLanguage]?.promocode
          }}</v-col>
          <v-col cols="4">
            <v-text-field
              label="Promo Code"
              v-model="promoCode"
              hide-details
              dense
              solo
              flat
              @input="capitalizeInput"
              class="equal-height"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="pl-2">
            <v-btn
              block
              small
              color="#00E8BE"
              class="equal-height"
              @click="applyPromoCode"
              >{{ pagelanguages[currentpageLanguage]?.apply }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title>
        <span class="text-h5">{{
          pagelanguages[currentpageLanguage]?.yourbalance
        }}</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="6">{{
            pagelanguages[currentpageLanguage]?.rewardcredit
          }}</v-col>
          <v-col cols="6"
            >{{ rewardCredit }}
            {{ pagelanguages[currentpageLanguage]?.credits }}</v-col
          >
        </v-row>
        <v-row>
          <v-col cols="6">{{
            pagelanguages[currentpageLanguage]?.purchasedcredit
          }}</v-col>
          <v-col cols="6"
            >{{ purchasedCredit }}
            {{ pagelanguages[currentpageLanguage]?.credits }}</v-col
          >
        </v-row>
        <v-row>
          <v-col cols="6">{{
            pagelanguages[currentpageLanguage]?.totalcredit
          }}</v-col>
          <v-col cols="6"
            >{{ totalCredit }}
            {{ pagelanguages[currentpageLanguage]?.credits }}</v-col
          >
        </v-row>

        <v-divider class="my-3"></v-divider>
        <!-- This divider will separate Amount Needed from other rows -->

        <v-row
          class="py-2"
          style="background-color: rgba(60, 242, 184, 0.1); border-radius: 5px"
        >
          <!-- subtle red background with padding -->
          <v-col class="text-h5 text-center" cols="12">
            {{ pagelanguages[currentpageLanguage]?.ordertotal }}
            {{ amountNeeded }}
            {{ pagelanguages[currentpageLanguage]?.credits }}
          </v-col>
        </v-row>

        <v-divider class="my-3"></v-divider>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCheckout">{{
          pagelanguages[currentpageLanguage]?.checkout
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.equal-height {
  height: 55px;
  align-items: center;
}
.custom-alert {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>

<script>
import { ref, watch, onMounted, computed, inject } from 'vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
import { openCheckoutPage } from '@/components/services/stripeService';
export default {
  props: {
    taskId: String,
    checkoutDialog: Boolean,
  },

  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  setup(props, context) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const floatCredit = ref(0);
    const floatPrice = ref(0);
    const floatPriceBeforeDiscount = ref(0);
    const userCredit = ref(0);
    const discountPercentage = ref(0);
    const rewardBackPercentage = ref(0);
    const rewardCredit = ref(0);
    const purchasedCredit = ref(0);
    const amountNeeded = ref(0);
    const totalCredit = ref(0);
    const message = ref('');
    const promoCode = ref('');
    const auth0 = useAuth0();
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const capitalizeInput = (event) => {
      promoCode.value = event.target.value.toUpperCase();
    };
    const messageType = computed(() => {
      return message.value.includes('applied') ? 'success' : 'error';
    });

    onMounted(() => {
      promoCode.value = '';
      message.value = '';
    });

    const getCheckOutInfo = async (taskId) => {
      if (isAuthenticated.value) {
        try {
          const token = await getAccessTokenSilently();
          const creditResponse = await axios.get(
            `${process.env.VUE_APP_API_URL}/api/credit/get`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          userCredit.value =
            Number(creditResponse.data.balance) +
            Number(creditResponse.data.rewardCredit);
          floatCredit.value = Math.ceil(parseFloat(userCredit.value) * 100);
          rewardCredit.value = Math.ceil(
            parseFloat(creditResponse.data.rewardCredit) * 100
          );
          purchasedCredit.value = Math.ceil(
            parseFloat(creditResponse.data.balance) * 100
          );
          totalCredit.value = Math.ceil(parseFloat(userCredit.value) * 100);
          console.log('task id: ', taskId);
          const task = await axios.get(
            `${process.env.VUE_APP_API_URL}/api/task/getTaskById`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                taskId: taskId,
              },
            }
          );
          discountPercentage.value = task.data.discount;
          rewardBackPercentage.value = task.data.rewardPercentage;
          floatPrice.value = Math.ceil(parseFloat(task.data.price) * 100);
          floatPriceBeforeDiscount.value = Math.ceil(
            parseFloat(task.data.priceBeforeDiscount) * 100
          );
          if (floatCredit.value < floatPrice.value) {
            amountNeeded.value = floatPrice.value - floatCredit.value;
          }
        } catch (error) {
          console.error(
            'Error while getting the token or fetching user profile: ',
            error
          );
          console.error('Axios error: ', error.response);
        }
      } else {
        console.log(
          'User is not authenticated, cannot retrieve the user profile.'
        );
      }
    };

    watch(
      () => props.checkoutDialog,
      (newVal) => {
        if (newVal) {
          getCheckOutInfo(props.taskId);
        }
      }
    );
    const applyPromoCode = async () => {
      if (isAuthenticated.value) {
        console.log('apply triggered');
        try {
          const token = await getAccessTokenSilently();
          const response = await axios.get(
            `${process.env.VUE_APP_API_URL}/api/promoCode/applyPromoCode`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                taskId: props.taskId,
                promo: promoCode.value,
              },
            }
          );

          console.log('response: ', response);

          if (response.data.status === 'error') {
            message.value = response.data.message; // This sets the message to whatever error message was returned
          } else {
            message.value = 'Your promo code has been applied!';
            getCheckOutInfo(props.taskId);
          }
        } catch (error) {
          console.error('Error while validating promo code: ', error);
          console.error('Axios error: ', error.response);
        }
      } else {
        console.log(
          'User is not authenticated, cannot retrieve the user profile.'
        );
      }
    };

    const handleCheckout = async () => {
      if (amountNeeded.value > 0) {
        // the minimum amount is 10 dollars (1000 credit)
        if (amountNeeded.value < 1000) {
          amountNeeded.value = 1000;
        }
        let quantity = Math.floor(Math.ceil(amountNeeded.value));
        console.log('quantity:', quantity);
        openCheckoutPage(
          auth0,
          isAuthenticated,
          quantity,
          'price_1NijNSJ40WTpEtgla3XIcD3R',
          props.taskId
        );
      } else {
        //run handleconfirm in parent component
        context.emit('triggerConfirm');
      }
    };

    return {
      floatPrice,
      floatPriceBeforeDiscount,
      floatCredit,
      discountPercentage,
      rewardBackPercentage,
      rewardCredit,
      purchasedCredit,
      totalCredit,
      amountNeeded,
      applyPromoCode,
      message,
      promoCode,
      handleCheckout,
      capitalizeInput,
      messageType,
      currentpageLanguage,
      pagelanguages,
    };
  },
};
</script>
