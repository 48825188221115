<template>
    <div>
      <div v-if="bookContent" v-html="bookContent"></div>
      <button @click="prevChapter">Previous</button>
      <button @click="nextChapter">Next</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentChapter: 1,
        totalChapters: 5, // assuming 5 chapters for this example
        bookContent: null
      };
    },
    mounted() {
      this.loadChapter();
    },
    methods: {
      async loadChapter() {
        try {
          const response = await fetch(`/chapter${this.currentChapter}.txt`);
          this.bookContent = await response.text();
        } catch (error) {
          console.error("Error loading chapter:", error);
        }
      },
      prevChapter() {
        if (this.currentChapter > 1) {
          this.currentChapter--;
          this.loadChapter();
        }
      },
      nextChapter() {
        if (this.currentChapter < this.totalChapters) {
          this.currentChapter++;
          this.loadChapter();
        }
      }
    }
  };
  </script>