const locale_zh = {
    //-------------------------------------------------------------------
    //mydashboard and appheader
    "home": "首页",
    "support": "支持",
    "logout": "登出",
    "mydashboard": "我的仪表板",
    "account": "帐户",
    "loginorsignup": "登录/注册",
    "myapps": "我的应用",
    "academicpapers": "学术论文翻译",
    "businessdocuments": "商务文件翻译",
    "literaturepublication": "文学出版",
    "texttranslation": "文本翻译",
    "voicetranslation": "语音翻译",
    "researchai": "研究 AI",
    "myproject": "我的项目",
    "pleaselogin": "请登录",
    "pleaselogintext": "您需要登录才能访问。",
    "useraccount": "用户帐户",
    "buymorecredits": "购买更多的信用点",
    // ------------------------------------------------------------------
    //documenttranlsation
    "projectname": "项目名称：",
    "draganddrop": "在此处拖放文件",
    "filelimit": "每个文件限制为200MB。DOCX，TXT",
    "browsefiles": "浏览文件",
    "uploading": "正在上传...",
    "uploadedfile": "已上传的文件：",
    "removefile": "删除文件",
    "targetlanguage": "目标语言",
    "subject": "学科",
    "disciplines": "学科领域",
    "improvemywriting": "提高我的写作",
    "translate": "翻译",
    "login": "登录",
    "close": "关闭",
    "taskcreated": "您的任务已创建，可能需要一个小时来完成。完成后您将收到邮件通知。您也可以在“我的下载”中检查状态。",
    "gotomyproject": "前往我的项目",
    "cancel": "取消",
    "yes": "是",
    "no": "否",
    "invalidfile": "文件类型无效",
    "filesallowed": "只允许DOCX和TXT文件",
    "success": "成功",
    "uploadsuccess": "文件上传成功！",
    "error": "错误",
    "uploaderror": "上传文件出错，请稍后再试。",
    "taskerror": "创建任务时出错，请稍后再试。",
    "unexpectederror": "发生了意外的错误。请稍后再试。",
    "nofileerror": "翻译前请上传文件。",
    "nosubject": "翻译前请选择目标语言和翻译主题。",
    "nocredit": "信用点不足",
    "confirm": "确认",
    "translationCostMessage": "翻译将花费 {price} 信用点。您将剩下 {remaining} 信用点。您想继续吗？",
    "nocreditMessage": "您有 {credit} 信用点，而此翻译费用为 {cost} 信用点。您还需要 {needed} 信用点。您想购买更多信用点吗？",
//----------------------------------------------------------------------------------------------------------------------------
    "selectproject": "请选择一个项目或创建一个新项目",
    "tableprojectname": "项目名称",
    "tablecreationtime": "创建时间",
    "actions": "操作",
    "search": "搜索",
    "createproject": " 创建新项目",
    "select": " 选择",
//-----------------------------------------------------------------------------------------------------------
    "openproject": "打开项目",
    "warning": "警告",
    "writingimprovement": "写作改进",
    "confirmdetail": "请确认您的任务详情",
    "translationtype": "翻译类型",
    "inputtext": "输入文本",
    "outputtext": "输出文本",
//--------------------------------------------------------------------------------------------------
    "doctexttranslation": "文档/文字翻译",
    "taskname": "任务名称",
    "taskstatus": "任务状态",
    "feedback": "反馈",
    "delete": "删除",
    "download": "下载",
    "invoice": "发票",
    "rename": "重命名",
//------------------------------------------------------------------------------------------------------
    "userdetails": "用户详情",
    "supportemail": "如果您有任何疑问，请联系我们 support@culture-links.com",
    "credits": "积分",
    "getmorecredit": "获取更多积分",
    "yourcurrentbalance": "您当前的余额（100积分 = $1）",
    "purchasemorecredits": "购买更多积分",
    "entercredits": "输入您的积分金额（$1 = 100积分）",
    "checkout": "结账",
//---------------------------------------------------------------------------------------------------
    "doctranslationrefinement": "文档翻译和润色",
    "refinementonlyapps": "文档润色",
    "includestranslationandrefinement": "包含翻译和润色",
    "userefinementnextsection": "如果您只想润色您的文章，请在下一部分使用‘文档润色’",
    "forscholars": "适合学者和研究人员",
    "fororganizations": "适合组织和顾问",
    "forwriters": "适合创意、独立作家",
    "comingsoon": "（即将上线）",
    "emailverification": "邮箱验证",
    "emailtext": "验证链接已发送到您的收件箱。如果找不到，请检查您的垃圾邮件。如有需要，请点击重新发送按钮。",
    "resendemail": "重新发送邮件",
    "refinementtype": "润色类型：",
    "type": "类型",
    "literature": "文学作品翻译",
    "filename": "文件名",
//---------------------------------------------------------------------------------------------------------------------------------------------------
    "ordersuccess": "感谢您的惠顾！您的支付已成功。如有任何疑问，请发邮件至",
    "ordercancelled": "您的支付已被取消。如有任何疑问，请发邮件至",
    "fastordersuccess": "感谢您的惠顾！您的支付已成功。请关闭此窗口并返回以继续您的翻译。如有任何疑问，请发邮件至",
    "backtohome": "返回主页",
    "namerequired": "项目名称为必填项",
    "namelength": "项目名称必须少于20个字符",
    "create": "创建",
    "createnewproject": "创建新项目",
    "deleteprojecttext": "此操作将删除此项目中的所有任务，您确定要删除此项目吗？",
    "howtouseit": "如何使用?",
    "technicalsupport": "技术支持",
    "userguide": "用户指南",
    "technicalsupport_url": "https://culture-links.com/technical-support-chinese/",
    "userguide_url": "https://culture-links.com/user-guide-chinese/",
//-------------------------------------------------------------------------------------------------------------------------------------------------------------    
    "closethiswindow": "关闭此窗口",
    "deleteconfirm": "一旦任务被删除，将无法恢复。您确定要继续吗？",
    "deleteerror": "删除任务时发生错误。",
    "deletesuccess": "任务已成功删除",
//------------------------------------------------------------------------------------------------------------------------------------
    "uploadfile": "上传文件",
    "or": "或",
    "chooseexistingfile": "选择您之前上传的文件",
//-----------------------------------------------------------------------------------------------------------------
    "submit": "提交",
    "academicrefinement": "学术论文润色",
    "businessrefinement": "商务文件润色",
    "literaturerefinement": "文学作品润色",
    "feedback_url": "https://culture-links.com/feedback-translation-chinese",
//--------------------------------------------------------------------------------------------------------------------------
    "mydownload": "我的下载",
    "downloadinstruction": "这里会显示已完成的任务，您也可以用此链接来查看它们的状态：",
//----------------------------------------------------------------------------------------------------------
"advertisingbar": "任务完成后，您将获得10%的积分奖励！",
    "academictooltip": "示例：医学与健康、社会科学（例如，心理学、社会学）、人文学（例如，历史、哲学）、工程技术、数学与统计、商务与管理、地球与空间、专利申请",
    "businesstooltip": "示例：合同与协议、法律文件与证书、广告、产品信息、用户指南、投资计划、审计报告、研究报告、采购与供应",
    "literaturetooltip": "示例：小说（例如，奇幻、浪漫、冒险）、非小说（例如，传记）、诗歌、儿童书籍、戏剧、网页文章、正式信件",
//-----------------------------------------------------------------------------------------------------------------------------------
"signup": "注册",
//--------------------------------------------------------------------------------------------------------------------------
"totalcredits": "总积分（购买积分 + 奖励积分）",
"purchasedcredits": "购买积分 ($1 = 100 积分)",
"rewardcredits": "奖励积分",
//-----------------------------------------------------------------------------------------------
"congratulationsDiscount": "恭喜！从现在起，您将在您的翻译任务中获得{discountPercentage}的折扣！",
"discountInfo": "您将在翻译任务中获得{discountPercentage}的折扣！",
"congratulationsReward": "恭喜！从现在起，您的翻译任务将获得{discountPercentage}%的积分奖励！",
"rewardInfo": "一旦您的任务完成，您将获得{discountPercentage}%的积分奖励！",
"doyouhavecode": "您有推荐码吗？请在此输入：",
"idonthaveone": "我没有",
//--------------------------------------------------------------------------------------------------------
"octpromo": "使用此促销代码可享受20%的折扣：OCT20OFF",
"pricedetails": "价格详情",
"originaltaskprice": "原始任务价格：",
"taskprice": "任务价格：",
"discountpercentage": "折扣百分比",
"priceafterdiscount": "折后价格：",
"rewardbackpercentage": "返奖百分比：",
"promocode": "促销代码：",
"yourbalance": "您的余额：",
"rewardcredit": "奖励信用：",
"purchasedcredit": "已购买信用：",
"totalcredit": "总信用：",
"ordertotal": "订单总计：",
"apply": "应用",
"promoapplied": "您的促销代码已应用",
//---------------------------------------------------------------------------------------------------------------------
"academicprice": "$0.01/字",
"businessprice": "$0.005/字",
"literatureprice": "$0.005/字",
"academicrefinementprice": "$0.008/字",
"businessrefinementprice": "$0.004/字",
"literaturerefinementprice": "$0.004/字",
//------------------------------------------------------------------------------------
"processing": "处理中...",
"takesfewminutes": "这可能需要几分钟时间。如果您关闭这个窗口，您可以稍后在‘我的下载’中下载结果。",
"gotodownload": "前往下载页面",
"requestlimit": "您每30天只能提交4次请求，请稍后再试",
"gentlereminder": "温馨提示",
"uptofour": "每30天最多4次审查",
"wordlimit": "(每篇论文 <= 10,000 字)",
"paperreview": "论文反馈",
"free": "免费",
//--------------------------------------------------------------------------------------------------
"reviewtooltip": "每30天最多4次请求（每个文档<= 10,000字）",
"recommendationtooltip": "每30天最多8次请求（每个文档<= 2,000字）",
"journalrecommendation": "期刊推荐",
"documentreview": "免费学术服务",
"status": "状态",
"reward": "奖励积分",
"purchased": "购买积分"
}


export default locale_zh;
