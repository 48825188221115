<template>
    <v-app>
      <v-app-bar app>
        <!-- Your logo and navigation items here -->
      </v-app-bar>
      
      <!-- How to Use Translation Apps -->
      <v-container fluid>
        <v-row>
        <v-col cols="12" class="d-flex justify-center">
            <v-card class="pa-4 mb-3" style="width: 80%;">
            <h2>How to Use Translation Apps?</h2>
            <v-row>
                <v-col cols="12" md="4">
                <h3>Step 1</h3>
                <p>Step 1 description...</p>
                </v-col>
                <v-col cols="12" md="4">
                <h3>Step 2</h3>
                <p>Step 2 description...</p>
                </v-col>
                <v-col cols="12" md="4">
                <h3>Step 3</h3>
                <p>Step 3 description...</p>
                </v-col>
            </v-row>
            <v-btn color="blue" dark>Support</v-btn>
            </v-card>
        </v-col>
        </v-row>
    </v-container>
  
      <!-- How to Use Research AI -->
      <v-container fluid>
        <v-row>
        <v-col cols="12" class="d-flex justify-center">
            <v-card class="pa-4 mb-3" style="width: 80%;">
            <h2>How to Use Translation Apps?</h2>
            <v-row>
                <v-col cols="12" md="4">
                <h3>Step 1</h3>
                <p>Step 1 description...</p>
                </v-col>
                <v-col cols="12" md="4">
                <h3>Step 2</h3>
                <p>Step 2 description...</p>
                </v-col>
                <v-col cols="12" md="4">
                <h3>Step 3</h3>
                <p>Step 3 description...</p>
                </v-col>
            </v-row>
            <v-btn color="blue" dark>Support</v-btn>
            </v-card>
        </v-col>
        </v-row>
    </v-container>
  
      <!-- How to Use Bilingual Bookstore -->
      <v-container fluid>
        <v-row>
        <v-col cols="12" class="d-flex justify-center">
            <v-card class="pa-4 mb-3" style="width: 80%;">
            <h2>How to Use Translation Apps?</h2>
            <v-row>
                <v-col cols="12" md="4">
                <h3>Step 1</h3>
                <p>Step 1 description...</p>
                </v-col>
                <v-col cols="12" md="4">
                <h3>Step 2</h3>
                <p>Step 2 description...</p>
                </v-col>
                <v-col cols="12" md="4">
                <h3>Step 3</h3>
                <p>Step 3 description...</p>
                </v-col>
            </v-row>
            <v-btn color="blue" dark>Support</v-btn>
            </v-card>
        </v-col>
        </v-row>
    </v-container>
  
    </v-app>
  </template>
  
  <script>
  export default {
    // Your Vue.js component details here
  }
  </script>
  
  <style scoped>
  /* Your CSS styles here */
  
  .v-container {
    padding-top: 160px;
    padding-bottom: 60px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  ol {
    margin-left: 20px;
    margin-bottom: 30px;
  }
  
  .text-button {
    color: blue;
    cursor: pointer;
  }
  .v-card {
  box-shadow: 0px 0px 35px 0px rgba(0,0,0,0.15);
}
  
  </style>
  