const locale_en = {
    //-------------------------------------------------------------------
    //mydashboard and appheader
    "home": "Home",
    "support": "Support",
    "logout": "Logout",
    "mydashboard": "My Dashboard",
    "account": "Account",
    "loginorsignup":"Login/Sign up",
    "literaturepublication": "Literature Publication",
    "myapps":"My Apps",
    "texttranslation": "Text Translation",
    "voicetranslation": "Voice Translation",
    "researchai": "Research AI",
    "myproject": "My Project",
    "pleaselogin": "Please Log In",
    "pleaselogintext": "You need to be logged in to access.",
    "useraccount": "User Account",
    "buymorecredits":"Buy More Credits",
    // ------------------------------------------------------------------
    //documenttranlsation
    "projectname":"Project Name: ",
    "draganddrop":"Drag and drop file here",
    "filelimit":"Limit 200MB per file. DOCX, TXT",
    "browsefiles":"Browse Files",
    "uploading":"Uploading...",
    "uploadedfile":"Uploaded File: ",
    "removefile":"Remove File",
    "targetlanguage":"Target Language",
    "subject":"Subject",
    "disciplines":"Disciplines",
    "improvemywriting":"Improve my writing",
    "translate":"Translate",
    "login":"Login",
    "close":"Close",
    "taskcreated":"Your task has been created and may take an hour to complete. You will receive an email notification when it is down. You can also check the status in My Download.",
    "gotomyproject":"Go to My Project",
    "cancel":"Cancel",
    "yes":"Yes",
    "no":"No",
    "invalidfile":"Invalid file type",
    "filesallowed":"Only DOCX, and TXT files are allowed",
    "success":"Success",
    "uploadsuccess":"File uploaded successfully!",
    "error": "Error",
    "uploaderror":"Upload file error, please try again later.",
    "taskerror":"Error creating task, please try again later.",
    "unexpectederror":"An unexpected error occurred. Please try again later.",
    "nofileerror":"Please upload a file before translation.",
    "nosubject":"Please select target language and translation subject before translation.",
    "nocredit":"Not enough credits",
    "confirm":"Confirm",
    "translationCostMessage":"The translation will cost {price} credits. You will have {remaining} credits remaining. Do you want to continue?",
    "nocreditMessage":"You have {credit} credits, and this translation cost {cost} credits. You need {needed} more credits. Would you like to purchase more credits?",
 
    //--------------------------------------------------------------------------------------------------------------------------

    "selectproject":"Please select a project or create a new one",
    "tableprojectname":"Project Name",
    "tablecreationtime":"Creation Time",
    "actions":"Actions",
    "search":"Search",
    "createproject":" Create New Project",
    "select":" Select",
//----------------------------------------------------------------------------------------------------------------------------------------

  "openproject":"Open Project",
  "warning":"Warning",
  "writingimprovement":"Writing Improvement",
  "confirmdetail":"Please confirm your task details",
  "translationtype":"Translation Type", 
  "inputtext":"Input Text",
  "outputtext":"Output Text",
//---------------------------------------------------------------------------------------------------------------------------
  "doctexttranslation":"Doc/Text Translation and Refinement",
  "taskname":"Task Name",
  "taskstatus":"Task Status",
  "feedback":"Feedback",
  "delete":"Delete",
  "download":"Download",
  "invoice":"Invoice",
  "rename":"Rename",
 //----------------------------------------------------------------------------------------------------------
  "userdetails":"User Details", 
  "supportemail":"If you have any questions, please contact us at support@culture-links.com",
  "credits":"credits",
  "getmorecredit":"Get More Credit",
  "yourcurrentbalance": "Your Current Balance  (100 credits = $1)",
  "purchasemorecredits": "Purchase More Credits",
  "entercredits": "Enter your credits amount ($1 = 100 credits)",
  "checkout": "checkout",

  //-------------------------------------------------------------------------------------------------------
  "doctranslationrefinement":"Document Translation & Refinement",
  
  "includestranslationandrefinement":"Includes Translation and Refinement",
  "userefinementnextsection":"If you just want to refine your article, use ‘Document Refinement’ in the next section",
  "forscholars":"For Scholars and Researchers",

  "fororganizations":"For Organizations and Consultants",
  
  "forwriters":"For Creative, Individual Writers",
  
  "comingsoon":"(Coming Soon)",
  "emailverification": "Email Verification",
  "emailtext":"A verification link has been sent to your inbox. Check your junk mail if not found. Click resend button when needed.",
  "resendemail":"Resend Email",
  "refinementtype":"Refinement Type: ",

  "type":"Type",
  
  "filename":"File Name",
 
  //------------------------------------------------------------------------------------------------------
  "ordersuccess":" We appreciate your business! Your payment was successful. If you have any questions, please email",
  "ordercancelled":"Your payment has been cancelled. If you have any questions, please email",
  "fastordersuccess":" We appreciate your business! Your payment was successful. Please close this window and go back to proceed your translation. If you have any questions, please email",
  "backtohome":"Back to Home",
  "namerequired":"Project name is required",
  "namelength":"Project name must be less than 20 characters",
  "create":"Create",
  "createnewproject":"Create a new project",
  "deleteprojecttext":"This action will delete ALL tasks in this project, Are you sure you want to delete this project?",
  "howtouseit": "How to use it?",
  "technicalsupport":"Technical Support",
  "userguide":"User Guide",
  "technicalsupport_url": "https://culture-links.com/technical-support/",
  "userguide_url": "https://culture-links.com/user-guide-english/",
  //--------------------------------------------------------------------------------------------------------------------------------------
  "closethiswindow": "Close this window",
  "deleteconfirm":"Once the task is deleted, there is no way to get it back. Do you wish to proceed?",
  "deleteerror":"An error occurred while deleting the task.",
  "deletesuccess":"Task has been deleted successfully",
  //--------------------------------------------------------------------------------------------------------------------------
  "uploadfile":"Upload a file",
  "or":"or",
  "chooseexistingfile":"Choose an uploaded file",
  "selectafile":"Select a file",
  //-----------------------------------------------------------------------------------------------
  "submit":"Submit",
  "academicrefinement":"Academic Refinement ",
  "businessrefinement":"Business Refinement ",
  "literaturerefinement":"Literature Refinement ",
  "feedback_url": "https://culture-links.com/feedback-translation",
  //-----------------------------------------------------------------------------------------------------------------
  "mydownload":"My Download",
  "downloadinstruction":"Your tasks will show up here once they are completed, you can also check their status in ",
  //-------------------------------------------------------------------------------------------------------
  "advertisingbar":"You will receive 10% credits reward after your task is finished!",
  "academictooltip": "Example Use Case: Medical & Health, Social Sciences (e.g., Psychology, Sociology), Humanities (e.g., History, Philosophy), Engineering & Tech, Math & Stats, Business & Management, Earth & Space, Patent Applications",
  "businesstooltip": "Example Use Case: Contracts & Agreements, Legal Docs & Credentials, Advertising, Product Info, User Guides, Investment Plans, Audit Reports, Research Reports, Procurement & Supply",
  "literaturetooltip": "Example Use Case: Fiction (e.g., Fantasy, Romance, Adventure), Non-fiction (e.g., Biography), Poetry, Kids' Books, Plays, Web Articles, Formal Letters",
  //-----------------------------------------------------------------------------------------------------------------------------------------------
  "signup":"Sign Up",
  //-------------------------------------------------------------------------------------------------------
  "totalcredits":"Total Credits (Purchased Credits + Reward Credits)",
  "purchasedcredits": "Purchased Credits ($1 = 100 Credits)",
  "rewardcredits": "Reward Credits",
  //------------------------------------------------------------------------------------------------------------------------
  "congratulationsDiscount": "Congratulations! You will have {discountPercentage} percent discount in your translation task from now on!",
  "discountInfo": "You will have {discountPercentage} percent discount in your translation task!",
  "congratulationsReward": "Congratulations! You will have {discountPercentage} percent of credit reward in your translation task from now on!",
  "rewardInfo": "You will receive a {discountPercentage} percent credit reward once your task is completed!",
  "doyouhavecode": "Do you have a referral code? Please enter it here:",
  "idonthaveone": "I don't have one",
  "reward": "reward credits",
  "purchased": "purchased credits",
  //--------------------------------------------------------------------------------------------------------------------------------------
  "octpromo" : "Use this promo code to get 20% off: OCT20OFF ",
  "pricedetails":"Price Details",
  "originaltaskprice": "Original Task Price:",
  "taskprice": "Task Price:",
  "discountpercentage": "Discount Percentage",
  "priceafterdiscount": "Price After Discount:",
  "rewardbackpercentage": "Reward Back Percentage:",
  "promocode":"Promo Code:",
  "yourbalance": "Your Balance:",
  "rewardcredit": "Reward Credit:",
  "purchasedcredit": "Purchased Credit:",
  "totalcredit": "Total Credit:",
  "ordertotal": "Order Total:",
  "apply": "Apply",
  "promoapplied":"Your promo code has been applied",
  //----------------------------------------------------------------------------------------------------------------------------
  "academicprice":"$0.01/word",
  "businessprice":"$0.005/word",
  "literatureprice": "$0.005/word",
  "academicrefinementprice":"$0.008/word",
  "businessrefinementprice":"$0.004/word",
  "literaturerefinementprice":"$0.004/word",
  //----------------------------------------------------------------------------------------------------------------------------------
  "processing": "Processing...",
  "takesfewminutes": "This may take a few minutes. If you close this window, you can download the result in 'My Download' later.",
  "gotodownload": "Go to Download Page",
  "requestlimit": "You can only submit 4 request every 30 days, so please try again later.",
  "gentlereminder": "Gentle Reminder",
  
  "uptofour": "Up to 4 reviews per 30 days",
  "wordlimit": "(<= 10,000 words, per paper)",
  "paperreview": "Paper Review",
  "free": "FREE",
  //-----------------------------------------------------------------------------------------------------------------------------------
  "reviewtooltip": "Up to 4 requests per 30 days (<= 10,000 words per document)",
  "recommendationtooltip": "Up to 8 requests per 30 days (<= 2,000 words per document)",
  "journalrecommendation": "Journal Recommendation",
  "documentreview": "Free Academic Services",
  "status": "Status",
  //----------------------------------------------------------------------------
  "refinementonlyapps":"Document Refinemnt",
  "academicpapers": "Academic Tanslation",
  "businessdocuments": "Business Translation",
  "literature":"Literature Translation",
  
}
 

  
  export default  locale_en;
  
  