<template>
    <v-dialog v-model="dialogModel" max-width="45em">

        <v-card>
            <v-card-text v-if="isLoading">
                <v-progress-circular indeterminate></v-progress-circular>
                Loading...
            </v-card-text>

        <div v-else>
            <v-card-title>
                <h1 class="text-h6 text-center">{{ pagelanguages[currentpageLanguage]?.selectproject }}</h1>
                <v-spacer />
                <v-text-field class="search" v-model="search" append-icon="mdi-magnify"  :label="pagelanguages[currentpageLanguage]?.search" single-line hide-details />
            </v-card-title>
            <table>
                <thead>
                    <tr>
                        <th>{{ pagelanguages[currentpageLanguage]?.tableprojectname }}</th>
                        <th>{{ pagelanguages[currentpageLanguage]?.tablecreationtime }}</th>
                        <th>{{ pagelanguages[currentpageLanguage]?.actions }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="project in filteredProjects" :key="project.id" @click="selectProject(project)">
                        <td>{{ project.name }}</td>
                        <td>{{ convertToLocalTime(project.createdAt) }}</td>
                        <td><v-btn class="v-button" small @click.stop="selectProject(project)">{{ pagelanguages[currentpageLanguage]?.select }}</v-btn></td>
                    </tr>
                </tbody>
            </table>

            <v-card-actions>
                <v-spacer />
            </v-card-actions>
            <v-btn class="create-button" text @click="showCreateProjectDialog = true">
            {{ pagelanguages[currentpageLanguage]?.createproject }}
            </v-btn>

        </div>
        </v-card>


        <!-- New project dialog -->
        <v-dialog v-model="showCreateProjectDialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ pagelanguages[currentpageLanguage]?.createnewproject }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field 
                            v-model="projectName" 
                            :rules="projectNameRules" 
                            :label="pagelanguages[currentpageLanguage]?.tableprojectname"
                            required>
                        </v-text-field>

                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" @click="createProject" :disabled="!isFormValid">{{ pagelanguages[currentpageLanguage]?.create }}</v-btn>


                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </v-dialog>
</template>


  
<script>
import axios from 'axios';
import { ref, computed, inject, watch } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
    props: ['vModelValue'],
    setup(props, { emit }) {
        const { isAuthenticated, getAccessTokenSilently } = useAuth0();
        const dialogModel = computed({
            get: () => props.vModelValue,
            set: value => emit('update:vModelValue', value)
        });
        const currentpageLanguage = inject('currentLanguage');
        const pagelanguages = inject('languages');
        const form = ref(null);
        const search = ref('');
        const projects = ref([]);
        const projectName = ref('');
        const isLoading = ref(false);
        const showCreateProjectDialog = ref(false);
        const isFormValid = computed(() => formValidationResult.value.valid);

        const formValidationResult = ref({ valid: false, errors: [] });
        

        const projectNameRules = computed(() => [
            v => !!v || pagelanguages[currentpageLanguage.value]?.namerequired,
            v => v.length <= 20 || pagelanguages[currentpageLanguage.value]?.namelength,
        ]);

        const convertToLocalTime = (utcTime) => {
        const localTime = new Date(utcTime);
        return localTime.toLocaleString(); // this will convert the time to a string that represents the local time zone
    };

        const getProjects = async () => {
            isLoading.value = true;
            if (!isAuthenticated.value) {
                // TODO: Show Login Dialog
                return;
            }
            try {
                const token = await getAccessTokenSilently();
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/project/get`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                projects.value = response.data;

            } catch (error) {
                console.error('Failed to get project: ', error);
            }
            finally {
                isLoading.value = false;
            }
        };

        const selectProject = (project) => {
            console.log('Selected project:', project);
            dialogModel.value = false;
            emit('selected-project', project.id, project.name); // Emitting event with project id
        };

        const createProject = async () => {
            isLoading.value = true;
            if (!form.value.validate()) {
                // Form is invalid
                return;
            }

            try {
                const token = await getAccessTokenSilently();
                console.log(projectName.value)
                const name = projectName.value
                const data = {
                    name: name,
                    type: 'document'
                }
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/project/create`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                // Refresh projects list
                getProjects();

                // Close the dialog
                showCreateProjectDialog.value = false;

                console.log('Project created:', response.data);

            } catch (error) {
                console.error('Failed to create project:', error);
            }
            finally {
                isLoading.value = false;
            }
        };

        // Filter projects based on search query
        const filteredProjects = computed(() => {
            if (search.value) {
                return projects.value.filter(project =>
                    project.name.includes(search.value) ||
                    project.id.includes(search.value)
                );
            }
            return projects.value;
        });

        // Fetch projects when component is created
        getProjects();
        
        watch(projectName, async () => {
            console.log("projectName:", projectName.value);
            if (form.value && form.value.validate) {
                const result = await form.value.validate();
                formValidationResult.value = result;
            }
        });


        return {
            dialogModel,
            search,
            projects,
            filteredProjects,
            selectProject,
            createProject,
            form,
            projectName,
            projectNameRules,
            showCreateProjectDialog,
            pagelanguages,
            currentpageLanguage,
            isLoading,
            isFormValid,
            convertToLocalTime
          
        };
    }
};
</script>
<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1.5em;
}
.search{
    margin-top: 1.5em;
}
th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

thead {
    background-color: #f2f2f2;
}
.text-h6 {
    font-size: 1.8rem !important;
    font-weight: 600 !important;
    color: #333 !important;
    text-transform: capitalize !important;
    margin-top: 0.5em;
    font-family: 'Red Hat Display', sans-serif;
}
/* For tablets or smaller screens */
@media (max-width: 768px) {
    .text-h6 {
        font-size: 1.2rem !important;
    }
}

/* For mobile or smaller screens */
@media (max-width: 480px) {
    .text-h6 {
        font-size: 0.9rem !important;
    }
}
.v-button{
    background-color: #2D5EFF;
    color:#FDFDFD
}
.create-button{
    background-color: #2D5EFF;
    color:#FDFDFD;
    width: 100%;
    
}

</style>