<template>
    <section id="voicetranlsation" class="section-container">
      <h1>Voice Translation App</h1>
      <hr>
      <!-- rest of your section content goes here -->
    </section>
  </template>
  
  <script>
  export default {
    name: "VoiceTranslation",
  };
  </script>

  <style scoped>
  .section-container {
    padding-top: 180px;
    padding-bottom: 60px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  p, ul {
    margin-left: 20px;
    margin-bottom: 30px;
  }
  
  </style>
  
  
