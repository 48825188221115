<template>
  <div v-if="isOpen" class="preview-modal">
    <div v-if="loading" class="loading-container">
      <v-progress-circular indeterminate color="white" size="70" />
      <p>{{ pagelanguages[currentpageLanguage]?.processing }}</p>
      <p>
        {{ pagelanguages[currentpageLanguage]?.takesfewminutes }}
      </p>
    </div>
    <div v-else class="text-preview">
      <pre>{{ previewText }}</pre>
    </div>
    <div class="button-group" v-if="previewText !== ''">
      <button @click="closeModal">
        {{ pagelanguages[currentpageLanguage]?.close }}
      </button>
      <button @click="downloadTxt">
        {{ pagelanguages[currentpageLanguage]?.gotodownload }}
      </button>
    </div>
  </div>
  <custom-alert ref="customAlert"></custom-alert>
</template>

<style scoped>
.preview-modal {
  width: 800px;
  height: 800px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.text-preview {
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  height: calc(
    100% - 40px
  ); /* Adjusted height to ensure padding doesn’t cut off text */
  padding: 20px;
  white-space: pre-wrap; /* Respect whitespace and line breaks */
  word-wrap: break-word; /* Break lines within words if necessary to prevent overflow */
}

pre {
  /* New style to prevent <pre> tag from overflowing */
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0; /* Remove default margin */
}

.button-group {
  padding-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 1.15em;
}

p {
  margin-top: 20px;
}
</style>

<script>
import { ref, watch, inject } from 'vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
import { useRouter } from 'vue-router';
import CustomAlert from '@/components/CustomAlert.vue';
import downloadFile from '@/components/MyDownload.vue';

export default {
  props: {
    modelValue: Boolean, // prop for v-model="previewDialog"
    isOpen: Boolean, // prop for v-model:is-open="isOpen"
    task: Object, // prop for :task="currentTask"
  },
  components: {
    CustomAlert,
  },
  emits: ['update:modelValue', 'update:is-open'],
  setup(props, { emit }) {
    const previewText = ref('');
    const auth0 = useAuth0();
    const loading = ref(false);
    const router = useRouter();
    const customAlert = ref(null);
    const closeModal = () => {
      previewText.value = '';
      emit('update:is-open', false);
      emit('update:modelValue', false);
    };
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const downloadTxt = () => {
      router.push('/mydownload'); // Navigate to download route
    };
    const startJob = async (taskId, type) => {
      loading.value = true;
      const token = await auth0.getAccessTokenSilently();
      let apiAddress = ref('');
      if (type == 'paperReview') {
        apiAddress.value = `${process.env.VUE_APP_API_URL}/api/app/paperReview/startReview`;
      } else if (type == 'journalRecommendation') {
        apiAddress.value = `${process.env.VUE_APP_API_URL}/api/app/journalRecommendation/journalRecommendation`;
      }
      try {
        const response = await axios.post(
          apiAddress.value,
          { taskId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        console.log('startReview response:', response);
        if (response.status === 200) {
          loading.value = false;
          previewText.value = response.data.kwargs.content;
        } else if (response.status === 203) {
          closeModal();
          customAlert.value.openAlert(
            pagelanguages[currentpageLanguage.value]?.gentlereminder,
            pagelanguages[currentpageLanguage.value]?.requestlimit
          );
        } else {
          throw new Error('Error occurred during review task creation.');
        }
      } catch (error) {
        console.error('Error while communicating with the server: ', error);
      } finally {
        loading.value = false;
      }
    };

    watch(
      () => props.isOpen,
      (newVal) => {
        console.log('taskId:', props.task.value.id);
        if (newVal) startJob(props.task.value.id, props.task.value.type);
      }
    );

    return {
      previewText,
      closeModal,
      downloadTxt,
      loading,
      customAlert,
      downloadFile,
      pagelanguages,
      currentpageLanguage,
    };
  },
};
</script>
