<template>
  <div class="custom-background">
    <div class="custom-padding">
      <nav class="breadcrumb-trail">
        <span @click="$router.push('/')">{{
          pagelanguages[currentpageLanguage]?.home
        }}</span>
        <span class="separator">/</span>
        <span @click="$router.push('/paperreview')">{{
          pagelanguages[currentpageLanguage]?.paperreview
        }}</span>
      </nav>
      <div>
        <p class="project-type">
          {{ pagelanguages[currentpageLanguage]?.paperreview }}
        </p>
        <UploadComponent @data-uploaded="updateFileId" />
        <v-btn class="mt-2" @click="proceedReview()" :disabled="!fileId">
          <v-progress-circular indeterminate color="white" v-if="loading" />
          <span v-else>
            {{ pagelanguages[currentpageLanguage]?.submit }}
          </span>
        </v-btn>
      </div>
    </div>
  </div>
  <custom-alert ref="customAlert"></custom-alert>
  <PreviewDialog
    v-model="previewDialog"
    v-model:is-open="isOpen"
    :task="currentTask"
  />
</template>

<style scoped>
.custom-padding {
  padding-top: 4em;
  padding-left: 10%;
  padding-right: 10%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.project-type {
  font-size: 1.8em;
  font-weight: 600;
  color: black;
  padding: 15px;
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  text-align: center;
}
</style>
<script>
import { ref, defineComponent, inject } from 'vue';
import axios from 'axios';
import UploadComponent from '@/components/UploadComponent.vue';
import PreviewDialog from '@/components/PreviewDialog.vue';
import { useAuth0 } from '@auth0/auth0-vue';
import CustomAlert from '@/components/CustomAlert.vue';
export default defineComponent({
  components: {
    PreviewDialog,
    UploadComponent,
    CustomAlert,
  },

  setup() {
    const auth0 = useAuth0();
    const customAlert = ref(null);
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const task = ref(null);
    const loading = ref(false);
    const previewDialog = ref(false);
    const isOpen = ref(false);
    const selectedTask = ref(null);
    const currentTask = ref(null);
    const fileId = ref(null);
    const openPreviewDialog = (task) => {
      currentTask.value = task;
      isOpen.value = true;
      previewDialog.value = true;
    };

    const updateFileId = (newFileId) => {
      fileId.value = newFileId;
    };
    const createTask = async () => {
      console.log('fileId: ', fileId.value);
      const token = await auth0.getAccessTokenSilently();
      const data = {
        fileId: fileId.value,
        taskType: 'paperReview',
      };

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/app/paperReview/newTask`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error) {
        // If there is an error in the request, alert the user
        customAlert.value.openAlert(
          pagelanguages[currentpageLanguage.value]?.error,
          pagelanguages[currentpageLanguage.value]?.taskerror
        );

        console.error('Error while creating the task: ', error);
      }
    };

    //------------------------------------------------------------------------------------------------------------

    const proceedReview = async () => {
      try {
        const token = await auth0.getAccessTokenSilently();
        task.value = await createTask(token);
        if (task.value) {
          openPreviewDialog(task);
        }
      } catch (error) {
        console.error('Error during the task processing: ', error);
      }
    };
    return {
      UploadComponent,
      customAlert,
      loading,
      pagelanguages,
      currentpageLanguage,
      previewDialog,
      selectedTask,
      fileId,
      updateFileId,
      proceedReview,
      openPreviewDialog,
      currentTask,
      isOpen,
    };
  },
});
</script>
