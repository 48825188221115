<template>
  <div class="page-container">
    <section class="order-success">
      <p class="order-success__text">
        {{message}}
       
      </p>
      <v-btn color="#00E8BE" text @click="goToMyProject"> {{ pagelanguages[currentpageLanguage]?.gotomyproject}}</v-btn>
    </section>
  </div>
</template>
  
<script>
import {inject ,onMounted, ref} from 'vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
import { useRouter } from 'vue-router';
export default {
  name: 'OrderSuccess',
  setup() {
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const {isAuthenticated} = useAuth0();
    const urlParams = new URLSearchParams(window.location.search);
    const taskIdFromURL = urlParams.get('taskId');
    const auth0 = useAuth0();
    const message = ref("");
    const router = useRouter();
    const goToMyProject = () => {
        router.push('/myproject');
      };
    const startTranslate = async (taskId) => {
      const token = await auth0.getAccessTokenSilently();
  
      try {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/app/documentTranslation/makePayment`, { taskId }, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
  
          console.log('payment response', response);
         if(response.data){
          
          try {
              const translateResponse = await axios.post(`${process.env.VUE_APP_API_URL}/api/app/documentTranslation/translateDocument`, { taskId }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
                }
              });
              console.log("translate response: ", translateResponse);
              if (translateResponse.status === 200){
                message.value = pagelanguages[currentpageLanguage.value]?.taskcreated ;
                console.log("message:",message.value);
              }
              else{
                message.value = "An error occured, please contact support@culture-links.com if you have any question.";
              }

            } 
          catch (error) {

              console.error('Error while making translation: ', error);
            } 
         }
  
        } catch (error) {
          message.value = "An error occured, please contact support@culture-links.com if you have any question.";
          console.error('Error while making payment: ', error);
          } 


      };
    
    if (taskIdFromURL) {
    // Use this taskId to automatically process the task
    }

    onMounted(() =>{
      if(isAuthenticated.value) {
        startTranslate(taskIdFromURL)
      }
    });


    return {
      pagelanguages,
      currentpageLanguage,
      message,
      goToMyProject,
    };
  }
};
</script>


<style scoped>
    .page-container {
      background-color: #161519; /* dark gray background */
      color: #FDFDFD; /* white text for contrast */
      padding-left: 0;
      padding-right: 0;
      margin-top: 0%;  
      box-sizing: border-box;
      min-height: 100%;
    }
.order-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px); /* 100px is the approximate height of your header, adjust if needed */
  text-align: center;
}

.order-success__text {
  font-size: 1.5em; /* Increase the size of the text */
  max-width: 80%;
  margin-bottom: 1em;
}



</style>
