<template>
  <CreateProjectAuto
    :vModelValue="showCreateProjectDialog"
    @selected-project="updateProjectId"
    @update:vModelValue="(val) => (showCreateProjectDialog = val)"
  />
  <v-container>
    <div
      class="upload-area"
      @dragover.prevent
      @dragenter.prevent
      @drop="onDrop($event)"
    >
      <v-icon class="mb-2">mdi-upload</v-icon>
      <span class="mb-2">{{
        pagelanguages[currentpageLanguage]?.draganddrop
      }}</span>
      <p class="mb-2">
        {{ pagelanguages[currentpageLanguage]?.filelimit }}
      </p>
      <v-btn class="mt-2" @click="$refs.fileInput.click()">
        {{ pagelanguages[currentpageLanguage]?.uploadfile }}
      </v-btn>
      <!-- The button for selecting existing files -->
      <a class="or-text">{{ pagelanguages[currentpageLanguage]?.or }}</a>

      <div class="menu-container">
        <v-dialog v-model="showFileList" width="40rem">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mt-2"
              v-bind="attrs"
              v-on="on"
              @click="toggleDropdown"
            >
              {{ pagelanguages[currentpageLanguage]?.chooseexistingfile }}
              <v-icon :class="{ 'rotate-icon': showFileList }"
                >mdi-menu-down</v-icon
              >
            </v-btn>
          </template>

          <v-card>
            <v-card-title>{{
              pagelanguages[currentpageLanguage]?.selectafile
            }}</v-card-title>
            <v-list>
              <v-list-item-group v-if="files.length > 0">
                <v-list-item
                  v-for="file in files"
                  :key="file.id"
                  @click="onFileSelected(file.name)"
                >
                  <v-list-item-content>
                    <v-row no-gutters>
                      <!-- Column for the file name -->
                      <v-col cols="8">
                        <v-list-item-title>
                          {{ file.name }}
                        </v-list-item-title>
                      </v-col>

                      <!-- Column for the creation time -->
                      <v-col cols="4">
                        <v-list-item-subtitle>
                          {{ convertToLocalTime(file.createdAt) }}
                        </v-list-item-subtitle>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-dialog>
      </div>

      <input
        type="file"
        ref="fileInput"
        @change="onFileChange($event)"
        style="display: none"
      />

      <div v-if="isLoading">
        <v-progress-circular
          indeterminate
          color="#2D5EFF"
        ></v-progress-circular>
        <p class="mb-2">
          {{ pagelanguages[currentpageLanguage]?.uploading }}
        </p>
      </div>
      <div v-if="uploadedFileName">
        <p class="uploadedfile">
          {{ pagelanguages[currentpageLanguage]?.uploadedfile }}
          {{ uploadedFileName }}
        </p>
      </div>
    </div>
  </v-container>
  <v-dialog v-model="showLoginDialog" width="500">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{
          pagelanguages[currentpageLanguage]?.pleaselogin
        }}</span>
      </v-card-title>
      <v-card-text>{{
        pagelanguages[currentpageLanguage]?.pleaselogintext
      }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="showLoginDialog = false">{{
          pagelanguages[currentpageLanguage]?.close
        }}</v-btn>
        <v-btn color="green darken-1" text @click="login">{{
          pagelanguages[currentpageLanguage]?.login
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <custom-alert ref="customAlert"></custom-alert>
</template>

<script>
import { defineComponent, ref, inject, onMounted } from 'vue';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
import CustomAlert from '@/components/CustomAlert.vue';
import CreateProjectAuto from '@/components/CreateProjectAuto.vue';
import { globalState } from '@/globalState.js';
export default defineComponent({
  name: 'UploadComponent',
  components: {
    CreateProjectAuto,
    CustomAlert,
  },
  setup(props, { emit }) {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const auth0 = useAuth0();
    const uploadedFile = ref(null);
    const uploadedFileName = ref(null);
    const isLoading = ref(false);
    const customAlert = ref(null);
    const selectedProjectId = ref(null);
    const selectedProjectName = ref(null);
    const showLoginDialog = ref(false);
    const showCreateProjectDialog = ref(false);
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const isTaskUnfinished = ref(false);
    let fileUploaded = ref(false);
    const files = ref([]); // Store the list of files for a project
    const showFileList = ref(false);

    const login = async () => {
      try {
        await loginWithRedirect({
          redirect_uri: window.location.origin,
        });
        showLoginDialog.value = false; // close the dialog
      } catch (error) {
        console.error('Error during login: ', error);
      }
    };
    const checkUserAuth = async () => {
      if (isAuthenticated.value) {
        showCreateProjectDialog.value = true;
      } else {
        showLoginDialog.value = true;
      }
    };
    onMounted(() => {
      checkUserAuth();
    });
    const convertToLocalTime = (utcTime) => {
      const localTime = new Date(utcTime);
      return localTime.toLocaleString(); // this will convert the time to a string that represents the local time zone
    };

    const toggleDropdown = () => {
      if (!isAuthenticated.value) {
        showLoginDialog.value = true;
      } else if (selectedProjectId.value === null) {
        showCreateProjectDialog.value = true;
      } else {
        showFileList.value = !showFileList.value;
      }
    };
    const fetchFiles = async () => {
      isLoading.value = true;
      try {
        const token = await auth0.getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/file/getByProjectId/${selectedProjectId.value}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        files.value = response.data.filter((file) => file.status !== 'deleted');
        console.log('finding id:', response.data);
        isLoading.value = false;
      } catch (error) {
        console.error('Failed to get files: ', error);
      }
    };

    const onFileChange = async (e) => {
      if (!auth0.isAuthenticated.value) {
        showLoginDialog.value = true;
        uploadedFile.value = null;
        uploadedFileName.value = null;
        return;
      }
      if (selectedProjectId.value === null) {
        showCreateProjectDialog.value = true;
        console.log('create project dialog: ', showCreateProjectDialog.value);
        return;
      }
      if (e.target.files.length === 0) {
        return;
      }
      uploadedFile.value = e.target.files[0];

      // Immediately upload the file after it's selected
      await uploadFile(e); // Call the new uploadFile() function
    };

    const onFileSelected = (fileName) => {
      showFileList.value = false;
      const selected = files.value.find((file) => file.name === fileName);
      console.log('selected file', selected);

      if (selected) {
        uploadedFile.value = selected; // Set the uploadedFile value here

        // Mock event data to simulate a file upload for consistency with the uploadFile function
        const mockEvent = {
          target: {
            files: [selected],
          },
        };

        selectExistingFile(mockEvent); // Call uploadFile with mock data
      }
    };

    const uploadFile = async (e) => {
      const allowedMimeTypes = [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
      ];

      if (!allowedMimeTypes.includes(uploadedFile.value.type)) {
        uploadedFile.value = null;
        uploadedFileName.value = null;

        customAlert.value.openAlert(
          pagelanguages[currentpageLanguage.value]?.invalidfile,
          pagelanguages[currentpageLanguage.value]?.filesallowed
        );
        return;
      }

      try {
        const token = await auth0.getAccessTokenSilently();

        let formData = new FormData();
        formData.append('file', uploadedFile.value);
        formData.append('projectId', selectedProjectId.value);
        console.log('project id:', selectedProjectId.value);
        isLoading.value = true;

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/app/documentTranslation/uploadFile`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        // Here, we are assigning the ID to the fileId.value
        const newFileId = response.data.id;
        emit('data-uploaded', newFileId);

        fileUploaded.value = true;
        console.log('fileUploaded', fileUploaded.value);
        isTaskUnfinished.value = true; // Mark the task as unfinished
        console.log('isAuthenticated', isAuthenticated.value);
        globalState.isTaskUnfinished = true;
        console.log('globalstate', globalState.isTaskUnfinished);
        uploadedFileName.value = e.target.files[0].name;
        console.log('uploadedFileName', uploadedFileName);
        console.log(
          'fileUploaded after successful upload:',
          fileUploaded.value
        ); // New line for debugging
        customAlert.value.openAlert(
          pagelanguages[currentpageLanguage.value]?.success,
          pagelanguages[currentpageLanguage.value]?.uploadsuccess
        );
      } catch (error) {
        fileUploaded.value = false;
        uploadedFile.value = null;
        uploadedFileName.value = null;
        customAlert.value.openAlert(
          pagelanguages[currentpageLanguage.value]?.error,
          pagelanguages[currentpageLanguage.value]?.uploaderror
        );
        console.error(
          'Error while getting the token or communicating with the server: ',
          error
        );
      } finally {
        isLoading.value = false;
      }
    };

    const selectExistingFile = (selectedFile) => {
      const fileData = selectedFile.target.files[0];

      const newFileId = fileData.id;
      emit('data-uploaded', newFileId);

      fileUploaded.value = true;
      isTaskUnfinished.value = true;
      globalState.isTaskUnfinished = true;
      uploadedFileName.value = fileData.name;

      customAlert.value.openAlert(
        pagelanguages[currentpageLanguage.value]?.success,
        pagelanguages[currentpageLanguage.value]?.uploadsuccess
      );
    };

    const updateProjectId = (projectId, projectName) => {
      selectedProjectId.value = projectId; // Add this method to handle the emitted event
      selectedProjectName.value = projectName;
      fetchFiles();
    };

    const onDrop = (e) => {
      e.preventDefault();

      uploadedFile.value = e.dataTransfer.files[0]; // Set the uploadedFile value here

      // Create a mock event object to match the structure expected by uploadFile
      const mockEvent = {
        target: {
          files: e.dataTransfer.files,
        },
      };

      uploadFile(mockEvent);
    };

    return {
      onFileChange,
      uploadedFile,
      uploadedFileName,
      onDrop,
      isLoading,
      selectedProjectName,
      selectedProjectId,
      updateProjectId,
      customAlert,
      showCreateProjectDialog,
      onFileSelected,
      fetchFiles,
      files,
      pagelanguages,
      currentpageLanguage,
      toggleDropdown,
      showFileList,
      convertToLocalTime,
      showLoginDialog,
      login,
    };
  },
});
</script>
<style scoped>
.v-container {
  display: flex;
  justify-content: center;
  align-items: center; /* if you also want vertical centering */
}
.upload-area {
  border: 2px dashed black;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2em;
  background-color: white;
  width: 70%;
}
.mt-2 {
  color: #fdfdfd;
  background-color: #2d5eff;
  font-family: 'Red Hat Display', sans-serif;
}

.mb-2 {
  color: black;
}

.or-text {
  color: black;
  font-family: 'Red Hat Display', sans-serif;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.menu-container {
  position: relative;
}
.uploadedfile {
  font-family: 'Red Hat Display', sans-serif;
  margin-top: 2em;
  margin-bottom: 0.5em;

  color: #2d5eff;
}

.rotate-icon {
  transform: rotate(180deg);
}
</style>
