<template>
  <div class="container">
    <!-- Breadcrumb Trail -->
    <nav class="breadcrumb-trail">
      <span @click="$router.push('/')">{{
        pagelanguages[currentpageLanguage]?.home
      }}</span>
      <span class="separator">/</span>
      <span @click="$router.push('/myproject')">{{
        pagelanguages[currentpageLanguage]?.myproject
      }}</span>
      <span class="separator">/</span>
      <span>{{ projectName }}</span>
    </nav>

    <h1 class="text my-4 text-center">{{ projectName }}</h1>
    <div>
      <v-card-text v-if="isLoading">
        <v-progress-circular
          indeterminate
          size="35"
          color="white"
        ></v-progress-circular>
        <span class="loading-text">loading...</span>
      </v-card-text>
      <div v-else>
        <table class="table">
          <tbody class="scrollable-tbody">
            <template v-for="file in tasks" :key="'file-' + file.id">
              <!-- Main file row -->
              <tr>
                <td @click="file.showTasks = !file.showTasks">
                  <span
                    class="mdi mdi-chevron-right"
                    v-if="!file.showTasks"
                  ></span>
                  <span
                    class="mdi mdi-chevron-down"
                    v-if="file.showTasks"
                  ></span>
                  {{ file.name }}
                </td>

                <td>{{ convertToLocalTime(file.createdAt) }}</td>
              </tr>

              <!-- Nested tasks for each file -->
              <tr v-if="file.showTasks">
                <td colspan="2">
                  <table class="nested-table">
                    <tr
                      v-for="task in filteredTasks(file)"
                      :key="'task-' + task.id"
                    >
                      <td>{{ task.type }}</td>
                      <td>{{ task.status }}</td>
                      <td>{{ task.targetLanguage }}</td>
                      <td>{{ convertToLocalTime(task.createdAt) }}</td>
                      <td>
                        <div class="button-container">
                          <button
                            class="icon-button"
                            :title="
                              pagelanguages[currentpageLanguage]?.feedback
                            "
                            @click="navigateToSupport"
                          >
                            <span class="mdi mdi-comment-quote"></span>
                          </button>
                          <div
                            v-if="activeBubble === task.id"
                            class="text-bubble"
                          >
                            {{
                              pagelanguages[currentpageLanguage]?.supportemail
                            }}
                          </div>
                        </div>
                        <button
                          class="icon-button"
                          @click="deleteTask(task)"
                          v-if="task.status !== 'Deleted'"
                          :title="pagelanguages[currentpageLanguage]?.delete"
                        >
                          <span class="mdi mdi-delete"></span>
                        </button>
                        <button
                          class="icon-button"
                          @click="downloadTask(task)"
                          v-if="task.status === 'Completed'"
                          :title="pagelanguages[currentpageLanguage]?.download"
                        >
                          <span class="mdi mdi-download"></span>
                        </button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-vue';
import { onMounted, ref, inject } from 'vue';

export default {
  props: ['projectId', 'projectName'],
  setup(props) {
    const { getAccessTokenSilently } = useAuth0();
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');
    const tasks = ref([]);
    const showBubble = ref(false);
    const activeBubble = ref(null);
    const isLoading = ref(false);
    // to not show task that has status "created"
    const filteredTasks = (file) => {
      return file.showTasks ? file.tasks : [];
    };

    const toggleBubble = (taskId) => {
      activeBubble.value = activeBubble.value === taskId ? null : taskId;
    };
    const navigateToSupport = () => {
      const supportURL = pagelanguages[currentpageLanguage.value]?.feedback_url;
      window.location.href = supportURL;
    };

    const convertToLocalTime = (utcTime) => {
      const localTime = new Date(utcTime);
      return localTime.toLocaleString(); // this will convert the time to a string that represents the local time zone
    };

    const fetchTasks = async () => {
      isLoading.value = true;
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/file/getByProjectId/${props.projectId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('fetchTasks() response:', response.data);
        const taskJson = [];
        response.data.map((file) => {
          taskJson.push(...file.tasks);
        });
        tasks.value = response.data.map((file) => ({
          ...file,
          status:
            file.status === 'created'
              ? 'Created'
              : file.status === 'pending'
              ? 'Pending'
              : file.status === 'processing'
              ? 'Processing'
              : file.status === 'completed'
              ? 'Completed'
              : 'Processing',
          tasks: file.tasks
            .map((task) => ({
              ...task,
              status:
                task.status === 'created'
                  ? 'Created'
                  : task.status === 'pending'
                  ? 'Pending'
                  : task.status === 'processing'
                  ? 'Processing'
                  : task.status === 'completed'
                  ? 'Completed'
                  : 'Processing',
              type:
                task.type === 'documentRefinement'
                  ? 'Refinement'
                  : task.type === 'documentTranslationPlusRefinement'
                  ? 'Translation & Refinement'
                  : task.type === 'paperReview'
                  ? 'Paper Review'
                  : task.type === 'journalRecommendation'
                  ? 'Journal Recommendation '
                  : 'Unknown Type',
            }))
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)), // sort the tasks by creation time
        }));

        tasks.value.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        console.log('fetchTasks() complete, tasks:', tasks.value);
        isLoading.value = false;
      } catch (error) {
        console.error('Failed to get tasks: ', error);
      }
    };

    onMounted(async () => {
      console.log('projectname: ', props.projectName);
      console.log('Props on mount: ', JSON.parse(JSON.stringify(props)));
      await fetchTasks();
      tasks.value.forEach((file) => (file.showTasks = false));
    });

    const downloadTask = async (task) => {
      alert(`Downloading ${task.outputName}`);
      console.log(task);
      try {
        const taskId = task.id;
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/app/documentTranslation/downloadOutput/`,
          { taskId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: 'blob', // Important for file download
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${task.outputName}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(
          'Error while getting the token or communicating with the server: ',
          error
        );
        alert(`An error occurred while deleting the task: ${error.message}`);
      }
    };

    const deleteTask = async (task) => {
      const confirmation = confirm(
        pagelanguages[currentpageLanguage.value]?.deleteconfirm
      );

      if (!confirmation) {
        return; // If user cancels the operation, do nothing.
      }

      try {
        const taskId = task.id;
        const token = await getAccessTokenSilently();
        await axios.delete(
          `${process.env.VUE_APP_API_URL}/api/app/documentTranslation/deleteTask/${taskId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        alert(pagelanguages[currentpageLanguage.value]?.deletesuccess);
        await fetchTasks();
      } catch (error) {
        console.error('Error while deleting the task: ', error);
        alert(pagelanguages[currentpageLanguage.value]?.deleteerror);
      }
    };
    const getFileType = (file) => {
      if (!file.tasks || !file.tasks.length) return 'Unknown Type';

      const taskType = file.tasks[0].type;
      return taskType === 'documentRefinement'
        ? 'Refinement'
        : taskType === 'documentTranslationPlusRefinement'
        ? 'Translation & Refinement'
        : 'Unknown Type';
    };

    return {
      tasks,
      deleteTask,
      downloadTask,
      pagelanguages,
      currentpageLanguage,
      showBubble,
      toggleBubble,
      activeBubble,
      isLoading,
      filteredTasks,
      navigateToSupport,
      convertToLocalTime,
      getFileType,
    };
  },
};
</script>

<style scoped>
.container {
  padding-top: 3em; /* Adjust as needed */
  padding-left: 10%;
  padding-right: 10%;
  background-color: #161519;
  min-height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.scrollable-tbody {
  display: block;
  max-height: 850px;
  overflow-y: auto;
  background-color: #fdfdfd;
}

table thead,
table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.task-name {
  max-width: 200px; /* Adjust this as per your needs */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.task-title {
  max-width: 200px; /* Adjust this as per your needs */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fdfdfd;
}
th {
  text-align: left;
  padding: 20px 10px;
  background-color: #57596a;
  border-bottom: 1px solid #dee2e6;
}

td {
  padding: 15px 10px;
  border-bottom: 1px solid #dee2e6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* or whatever width you want to set */
}

.icon-button {
  background: none;
  border: none;
  color: #6c757d;
  font-size: 24px;
  padding: 5px;
}

.icon-button:hover {
  color: #212529;
}
.section-divider-1 {
  margin-top: 6em;
  border-color: #00e8be;
  font-family: 'Red Hat Display', sans-serif;
  /* slightly brighter line on dark background */
}
.my-4 {
  color: #fdfdfd;
  font-family: 'Red Hat Display', sans-serif;
}

.separator {
  margin: 0 0.5rem; /* Adjust the value 0.5rem to increase or decrease the spacing */
}
.button-container {
  position: relative;
  display: inline-block; /* Keeps the container size tight around the button */
}

.text-bubble {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  width: 350px;
  font-size: 1 em;
  position: absolute;
  right: 100%; /* Position it to the left of the button */
  top: 50%; /* Aligns the center of the bubble with the center of the button */
  transform: translateY(-50%);
  margin-right: 10px; /* Space between the bubble and the button */
}
.loading-text {
  padding-left: 1em;
  font-size: 2em;
  color: white;
}
.triangle-down {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black; /* or any color you prefer */
  margin-right: 5px;
}

.triangle-up {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black; /* or any color you prefer */
  margin-right: 5px;
}
.nested-table {
  width: 100%;
  border-collapse: collapse;
  margin-left: 20px; /* Indentation */
}

.nested-table td {
  border: 1px solid black; /* Light border */
  padding: 8px; /* Padding for cells */
}
</style>
