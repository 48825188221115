const locale_zh = {
    //-------------------------------------------------------------------
    //mydashboard and appheader
    "home": "首頁",
    "support": "支援",
    "logout": "登出",
    "mydashboard": "我的儀表板",
    "account": "帳戶",
    "loginorsignup": "登錄/註冊",
    "myapps": "我的應用",
    "literaturepublication": "文學出版",
    "texttranslation": "文本翻譯",
    "voicetranslation": "語音翻譯",
    "researchai": "研究 AI",
    "myproject": "我的項目",
    "pleaselogin": "請登錄",
    "pleaselogintext": "您需要登入才能訪問。",
    "useraccount": "用戶帳戶",
    "buymorecredits": "購買更多的信用點",
    // ------------------------------------------------------------------
    //documenttranlsation
    "projectname": "項目名稱：",
    "draganddrop": "在此處拖放文件",
    "filelimit": "每個文件限制為200MB。DOCX，TXT",
    "browsefiles": "瀏覽文件",
    "uploading": "正在上傳...",
    "uploadedfile": "已上傳的文件：",
    "removefile": "刪除文件",
    "targetlanguage": "目標語言",
    "subject": "學科",
    "disciplines": "學科領域",
    "improvemywriting": "提高我的寫作",
    "translate": "翻譯",
    "login": "登錄",
    "close": "關閉",
    "taskcreated": "您的任務已創建，可能需要一個小時來完成。完成後您將收到郵件通知。您也可以在「我的下載」中檢查狀態。",
    "gotomyproject": "前往我的項目",
    "cancel": "取消",
    "yes": "是",
    "no": "否",
    "invalidfile": "文件類型無效",
    "filesallowed": "只允許DOCX和TXT文件",
    "success": "成功",
    "uploadsuccess": "文件上傳成功！",
    "error": "錯誤",
    "uploaderror": "上傳文件出錯，請稍後再試。",
    "taskerror": "創建任務時出錯，請稍後再試。",
    "unexpectederror": "發生了意外的錯誤，請稍後再試。",
    "nofileerror": "翻譯前請上傳文件。",
    "nosubject": "翻譯前請選擇目標語言和翻譯主題。",
    "nocredit": "信用點不足",
    "confirm": "確認",
    "translationCostMessage": "翻譯將花費 {price} 信用點。您將剩下 {remaining} 信用點。您想繼續嗎？",
    "nocreditMessage": "您有 {credit} 信用點，而此翻譯費用為 {cost} 信用點。您還需要 {needed} 信用點。您想購買更多信用點嗎？",
    //----------------------------------------------------------------------------------------------------------------------------
    "selectproject": "請選擇一個項目或創建一個新項目",
    "tableprojectname": "項目名稱",
    "tablecreationtime": "創建時間",
    "actions": "操作",
    "search": "搜索",
    "createproject": "創建新項目",
    "select": "選擇",
    //-----------------------------------------------------------------------------------------------------------
    "openproject": "打開項目",
    "warning": "警告",
    "writingimprovement": "寫作改進",
    "confirmdetail": "請確認您的任務詳情",
    "translationtype": "翻譯類型",
    "inputtext": "輸入文本",
    "outputtext": "輸出文本",
    //--------------------------------------------------------------------------------------------------
    "doctexttranslation": "文檔/文字翻譯",
    "taskname": "任務名稱",
    "taskstatus": "任務狀態",
    "feedback": "反饋",
    "delete": "刪除",
    "download": "下載",
    "invoice": "發票",
    "rename": "重命名",
    //------------------------------------------------------------------------------------------------------
    "userdetails": "用戶詳情",
    "supportemail": "如果您有任何疑問，請聯繫我們 support@culture-links.com",
    "credits": "積分",
    "getmorecredit": "獲取更多積分",
    "yourcurrentbalance": "您當前的餘額（100積分 = $1）",
    "purchasemorecredits": "購買更多積分",
    "entercredits": "輸入您的積分金額（$1 = 100積分）",
    "checkout": "結賬",
    //---------------------------------------------------------------------------------------------------
    "doctranslationrefinement": "文檔翻譯和潤色",
    "includestranslationandrefinement": "包含翻譯和潤色",
    "userefinementnextsection": "如果您只想潤色您的文章，請在下一部分使用「文檔潤色」",
    "forscholars": "適合學者和研究人員",
    "fororganizations": "適合組織和顧問",
    "forwriters": "適合創意、獨立作家",
    "comingsoon": "（即將上線）",
    "emailverification": "郵箱驗證",
    "emailtext": "驗證連結已發送到您的收件箱。如果找不到，請檢查您的垃圾郵件。如有需要，請點擊重新發送按鈕。",
    "resendemail": "重新發送郵件",
    "refinementtype": "潤色類型：",
    "type": "類型",
    "filename": "文件名",
    //---------------------------------------------------------------------------------------------------------------------------------------------------
    "ordersuccess": "感謝您的惠顧！您的支付已成功。如有任何疑問，請發郵件至",
    "ordercancelled": "您的支付已被取消。如有任何疑問，請發郵件至",
    "fastordersuccess": "感謝您的惠顧！您的支付已成功。請關閉此窗口並返回以繼續您的翻譯。如有任何疑問，請發郵件至",
    "backtohome": "返回主頁",
    "namerequired": "項目名稱為必填項",
    "namelength": "項目名稱必須少於20個字符",
    "create": "創建",
    "createnewproject": "創建新項目",
    "deleteprojecttext": "此操作將刪除此項目中的所有任務，您確定要刪除此項目嗎？",
    "howtouseit": "如何使用?",
    "technicalsupport": "技術支援",
    "userguide": "用戶指南",
    "technicalsupport_url": "https://culture-links.com/technical-support-chinese/",
    "userguide_url": "https://culture-links.com/user-guide-chinese/",
    //-------------------------------------------------------------------------------------------------------------------------------------------------------------    
    "closethiswindow": "關閉此窗口",
    "deleteconfirm": "一旦任務被刪除，將無法恢復。您確定要繼續嗎？",
    "deleteerror": "刪除任務時發生錯誤。",
    "deletesuccess": "任務已成功刪除",
    //------------------------------------------------------------------------------------------------------------------------------------
    "uploadfile": "上傳文件",
    "or": "或",
    "chooseexistingfile": "選擇您之前上傳的文件",
    //-----------------------------------------------------------------------------------------------------------------
    "submit": "提交",
    "academicrefinement": "學術論文的潤色",
    "businessrefinement": "商務文件的潤色",
    "literaturerefinement": "文學作品的潤色",
    "feedback_url": "https://culture-links.com/feedback-translation-chinese",
    //--------------------------------------------------------------------------------
    "mydownload": "我的下載",
    "downloadinstruction": "一旦任務完成，它們會在這裡顯示，您也可以在以下位置檢視它們的狀態：",
    //-------------------------------------------------------------------------------------------------
    "advertisingbar": "任務完成後，您將獲得10%的積分獎勵！",
    "academictooltip": "用途：醫學與健康、社會科學（例如，心理學、社會學）、人文學（例如，歷史、哲學）、工程技術、數學與統計、商務與管理、地球與空間、專利申請",
    "businesstooltip": "用途：合同與協議、法律文件與證書、廣告、產品資訊、使用者指南、投資計劃、審計報告、研究報告、採購與供應",
    "literaturetooltip": "用途：小說（例如，奇幻、浪漫、冒險）、非小說（例如，傳記）、詩歌、兒童書籍、戲劇、網頁文章、正式信件",
    "signup": "註冊",
   //---------------------------------------------------------------------------------------------------------------------
   "totalcredits": "總積分（購買積分 + 獎勵積分）",
   "purchasedcredits": "購買積分 ($1 = 100 積分)",
   "rewardcredits": "獎勵積分",
   //------------------------------------------------------------------------------------------------
   "congratulationsDiscount": "恭喜！從現在起，您將在您的翻譯任務中獲得{discountPercentage}%的折扣！",
   "discountInfo": "您將在翻譯任務中獲得{discountPercentage}的折扣！",
   "congratulationsReward": "恭喜！從現在起，您的翻譯任務將獲得{discountPercentage}%的積分獎勵！",
   "rewardInfo": "一旦您的任務完成，您將獲得{discountPercentage}%的積分獎勵！",
   "doyouhavecode": "您有推薦碼嗎？請在此輸入：",
   "idonthaveone": "我沒有",
   //----------------------------------------------------------------------------------------------------------------
   "octpromo": "使用此促銷代碼可享受20%的折扣：OCT20OFF",
   "pricedetails": "價格詳情",
   "originaltaskprice": "原始任務價格：",
   "taskprice": "任務價格：",
   "discountpercentage": "折扣百分比",
   "priceafterdiscount": "折後價格：",
   "rewardbackpercentage": "返獎百分比：",
   "promocode": "促銷代碼：",
   "yourbalance": "您的餘額：",
   "rewardcredit": "獎勵信用：",
   "purchasedcredit": "已購買信用：",
   "totalcredit": "總信用：",
   "ordertotal": "訂單總計：",
   "apply": "應用",
   "promoapplied": "您的促銷代碼已應用",
   //------------------------------------------------------------------------------------------
   "academicprice": "$0.01/字",
   "businessprice": "$0.005/字",
   "literatureprice": "$0.005/字",
   "academicrefinementprice": "$0.008/字",
   "businessrefinementprice": "$0.004/字",
   "literaturerefinementprice": "$0.004/字",
   //-------------------------------------------------------------------------------
   "processing": "處理中...",
   "takesfewminutes": "這可能需要幾分鐘時間。如果您關閉這個窗口，您可以稍後在‘我的下載’中下載結果。",
   "gotodownload": "前往下載頁面",
   "requestlimit": "您每30天只能提交4次請求，請稍後再試",
   "gentlereminder": "溫馨提示",
   "uptofour": "每30天最多4次審查",
   "wordlimit": "(每篇論文 <= 10,000 字)",
   "paperreview": "論文審閱",
   "free": "免費",
   "reviewtooltip": "每30天最多4次請求（每個文件<= 10,000字）",
   "recommendationtooltip": "每30天最多8次請求（每個文件<= 2,000字）",
   "journalrecommendation": "期刊推薦",
   "documentreview": "免費學術服務",
   "status": "狀態",
   //------------------------------------------------------------
   "refinementonlyapps": "文件細化",
   "academicpapers": "學術翻譯",
   "businessdocuments": "商業翻譯",
   "literature": "文學翻譯",
   "reward": "獎勵積分",
   "purchased": "購買積分"
}

export default locale_zh;
