<template>
  <v-app>
    <v-app-bar app>
      <!-- Your logo and navigation items here -->
    </v-app-bar>
    
    <!-- Our Mission -->
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h2>Our Mission</h2>
          <p>Develop AI products to help break down language barriers and open up a world of seamless communication and cultural exchange.</p>
        </v-col>
      </v-row>
    </v-container>

    <!-- Our Values -->
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h2>Our Values</h2>
          <ul>
            <li><strong>Customer-centricity:</strong> By putting the customer at the core of the business, we can provide a positive experience, build long-term relationships, and deliver on our promise.</li>
            <li><strong>Integrity:</strong> We do the right thing. By being honest, transparent, and committed to doing what’s best for our customers and our company, we can be held accountable.</li>
            <li><strong>Innovation:</strong> We are innovators. By focusing on impact, exploring new ideas, and pursuing excellence, we constantly use cutting-edge technology to deliver the best quality products and services to our customers.</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>

    <!-- Our Product Advantages -->
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h2>Our Product Advantages</h2>
          <ul>
            <li><strong>Unrivaled Quality:</strong> CultureLinks offers unmatched translation quality thanks to the integration of cutting-edge AI technology and human ingenuity. Experience seamless, accurate translation across all languages, including those spoken by smaller populations.</li>
            <li><strong>Instant Communication:</strong> Our real-time voice and document translation services allow for immediate and effortless cross-cultural communication, erasing language barriers and facilitating global understanding.</li>
            <li><strong>Academic Excellence:</strong> With ResearchAI, researchers worldwide can access, understand, and benefit from international academic papers. We're breaking language barriers in academia to propel global research forward.</li>
            <li><strong>Data Protection:</strong> CultureLinks understands the importance of data privacy and security. We adhere to high-standard data protection protocols, ensuring your conversations and documents are safe and confidential.</li>
            <li><strong>Cost-Effective:</strong> Our services provide top-notch results at a very competitive and affordable price, making quality translation accessible to all.</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>

  </v-app>
</template>

<script>
export default {
  // Your Vue.js component details here
}
</script>

<style scoped>
.v-container {
  padding-top: 180px;
  padding-bottom: 60px;
  border-bottom: 1px solid #e0e0e0;
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

p, ul {
  margin-left: 20px;
  margin-bottom: 30px;
}

</style>
