<template>
  <v-app>
    <AppHeader />
    <div :style="backgroundStyle" class="background-logo">
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import { ref, onMounted } from 'vue';
export default {
  components: {
    AppHeader,
  },
  setup() {
    const backgroundImages = [
      require('@/assets/bg1.jpeg'),
      require('@/assets/bg2.jpeg'),
      require('@/assets/bg3.jpeg'),
      require('@/assets/bg4.jpeg'),
      require('@/assets/bg5.jpeg'),
      require('@/assets/bg6.jpeg'),
      require('@/assets/bg7.jpeg'),
      require('@/assets/bg8.jpeg'),
      require('@/assets/bg9.jpeg'),
    ];
    const backgroundStyle = ref({
      backgroundImage: `url(${
        backgroundImages[Math.floor(Math.random() * backgroundImages.length)]
      })`,
    });

    onMounted(() => {
      const randomIndex = Math.floor(Math.random() * backgroundImages.length);
      backgroundStyle.value.backgroundImage = `url(${backgroundImages[randomIndex]})`;
    });

    return {
      backgroundStyle,
    };
  },
};
</script>
<style>
.background-logo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  transition: background-image 1s ease;
  min-height: 100vh; /* Ensures it's at least as tall as the viewport */
}

.v-navigation-drawer {
  height: 100vh !important;
}
.container {
  padding-top: 3em; /* Adjust as needed */
  padding-left: 10%;
  padding-right: 10%;
  min-height: 100%;
}
.breadcrumb-trail {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #2d5eff;
}

.breadcrumb-trail span {
  cursor: pointer;
}

.breadcrumb-trail span:not(:last-child):hover {
  text-decoration: underline;
}
.separator {
  margin: 0 0.5rem; /* Adjust the value 0.5rem to increase or decrease the spacing */
}
.section-divider-1 {
  margin-top: 4em;
  border-color: white; /* slightly brighter line on dark background */
}
.downloadlink {
  font-size: 1.2em;
  text-decoration: underline;
  color: #00e8be;
  font-family: 'Red Hat Display', sans-serif;
  text-align: right; /* This will align the link to the right side */
  margin-bottom: 20px; /* This will provide some space below the link. Adjust as required. */
  display: block; /* Make the link behave like a block element to occupy full width and respect text-align */
}
.support-email {
  color: #00e8be;
}
</style>
