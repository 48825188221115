// stripeService.js

import axios from 'axios';
export const openCheckoutPage = async (auth0, isAuthenticated, quantity, stripePriceID, taskId) => {
    // Check if user is authenticated
    if (isAuthenticated.value) {
        try {
            console.log(`Opening Checkout Page for ${quantity} credits...`);

            // Get the access token
            const token = await auth0.getAccessTokenSilently();

            const successUrl = `${window.location.origin}/order/fastordersuccess?taskId=${taskId}`;
            const cancelUrl = window.location.origin + '/order/cancelled';
            const response = await axios({
                method: 'post',
                url: `${process.env.VUE_APP_API_URL}/api/credit/createStripeSession`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                data: {
                    quantity: quantity,
                    stripePriceId: stripePriceID,
                    success_url: successUrl,
                    cancel_url: cancelUrl
                },
                maxRedirects: 0,
                validateStatus: function (status) {
                    return status >= 200 && status < 303; // default
                },
            });

            if (!response.ok){
                // handleError
            }
            const redirectUrl = response.data.url;
            window.location.href = redirectUrl;  // directly redirect the user
        } catch (error) {
            console.error('Error while getting the token or opening checkout page: ', error);
            console.error('Axios error: ', error.response);
            alert("An error occurred when creating payment section, please try again later");
        }
    } else {
        console.log('User is not authenticated, cannot retrieve the access token.');
    }
}
