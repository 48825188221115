<template>
  <div class="page-container">
    <section class="order-cancelled">
      <p class="order-cancelled__text">
        {{ pagelanguages[currentpageLanguage]?.ordercancelled }}
        <a href="mailto:orders@example.com" class="support-email">support@culture-links.com</a>.
      </p>
      <button class="order-cancelled__button" @click="goToDashboard">{{ pagelanguages[currentpageLanguage]?.backtohome }}</button>
    </section>
  </div>
  </template>
    
  <script>

import{inject} from 'vue';
  export default {
    name: 'OrderCancelled',
    methods: {
      goToDashboard() {
        this.$router.push('/'); 
      },
    },
    setup(){
    const currentpageLanguage = inject('currentLanguage');
    const pagelanguages = inject('languages');


    return{
      pagelanguages,
      currentpageLanguage,
    }
  }
  };
  </script>
  
  <style scoped>
     .page-container {
      background-color: #161519; /* dark gray background */
      color: #FDFDFD; /* white text for contrast */
      padding-left: 0;
      padding-right: 0;
      margin-top: 0%;  
      box-sizing: border-box;
      min-height: 100%;
    }
 .order-cancelled {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px); /* 100px is the approximate height of your header, adjust if needed */
    text-align: center;
  }
  
  .order-cancelled__text {
    font-size: 1.5em; /* Increase the size of the text */
    max-width: 80%;
    margin-bottom: 1em;
  }
  
  .order-cancelled__button {
    background-color: #2D5EFF;
    color: #FDFDFD;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .order-cancelled__button:hover {
    background-color: #e2e6ea;
  }
  </style>
  