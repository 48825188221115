<template>
    <div>
        <v-banner single-line elevation="2" class="mt-3 banner large-banner">
        <div class="banner-content">
            Translation should be a breeze! Free for 30 days
        </div>
        </v-banner>

        <v-container> 
            <v-row>
                <v-col cols="3">
                    <v-card class="pa-2" outlined style="position: sticky; top: 120px;">
                        <v-list dense>
                            <v-list-item-group :model-value="selectedApp" @update:model-value="val => selectedApp = val" color="primary">
                            <v-list-item class="list-item" :class="{active: selectedApp === 0}" @click="selectedApp = 0">
                                <v-list-item-content>
                                <v-list-item-title>Text Translation</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>   
                            <v-list-item class="list-item" :class="{active: selectedApp === 1}" @click="selectedApp = 1">
                                <v-list-item-content>
                                <v-list-item-title>Document Translation</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="list-item" :class="{active: selectedApp === 2}" @click="selectedApp = 2">
                                <v-list-item-content>
                                <v-list-item-title>My Projects</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="list-item" :class="{active: selectedApp === 3}" @click="selectedApp = 3">
                                <v-list-item-content>
                                <v-list-item-title>Task Table</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>

                <v-col cols="9">
                    <v-card class="pa-2 mb-4" outlined>
                        <component :is="currentAppComponent"></component>
                    </v-card>
                    <!-- pricing component -->
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { ref, watchEffect, computed } from 'vue'
import DocumentTranslation from './DocumentTranslation.vue';
import TextTranslation from './TextTranslation.vue';
import ProjectTable from './ProjectTable.vue';
import TaskTable from './TaskTable.vue';
export default {
    components: {
        DocumentTranslation,
        TextTranslation,
        ProjectTable,
        TaskTable,
    },
    setup() {
        const selectedApp = ref(0);
        
        watchEffect(() => {
            console.log(`selectedApp is now: ${selectedApp.value}`);
        });
        
        return {
            selectedApp,
            currentAppComponent: computed(() => {
                switch (selectedApp.value) {
                    case 0: return 'TextTranslation';
                    case 1: return 'DocumentTranslation';
                    case 2: return 'ProjectTable';
                    case 3: return 'TaskTable';
                    default: return null;
                }
            })
        };
    },
};
</script>


  
  <style scoped>
  .banner {
  padding-top: 300px; /* adjust as needed */
    }
    .large-banner {
    height: 150px;  
    }

    .banner-content {
    position: relative;
    top: 100px; /* Adjust this value as necessary */
    font-size: 36px; /* Adjust this value to make the text larger */
    }

  .v-list-item-group--active .v-list-item {
    background-color: rgba(0,0,0,0.1);
  }
  
  
  </style>
  