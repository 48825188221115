import { createRouter, createWebHistory } from 'vue-router';
import TranslationProducts from './components/TranslationProducts.vue';
import OrderSuccess from '@/components/OrderSuccess.vue';
import OrderCancelled from '@/components/OrderCancelled.vue';
import HowItWorks from '@/components/HowItWorks.vue';
import AboutUs from '@/components/AboutUs.vue';
import VoiceTranslation from '@/components/VoiceTranslation.vue';
import ResearchAI from '@/components/ResearchAI.vue';
import BiLingualBookstore from '@/components/BiLingualBookstore.vue';
import MyDashboard from '@/components/MyDashboard';
import DocumentTranslation from '@/components/DocumentTranslation.vue';
import TextTranslation from '@/components/TextTranslation.vue';
import ProjectTable from '@/components/ProjectTable.vue';
import TaskTable from '@/components/TaskTable.vue';
import MyProject from '@/components/MyProject.vue';
import CreateProject from '@/components/MyProject.vue';
import UserAccount from '@/components/UserAccount.vue';
import BuyMoreCredits from '@/components/BuyMoreCredits.vue';
import FastOrderSuccess from '@/components/FastOrderSuccess.vue';
import AcademicPapers from '@/components/AcademicPapers.vue';
import BusinessDocuments from '@/components/BusinessDocuments.vue';
import LiteraturePublication from '@/components/LiteraturePublication.vue';
import AcademicRefinement from '@/components/AcademicRefinement.vue';
import BusinessRefinement from '@/components/BusinessRefinement.vue';
import LiteratureRefinement from '@/components/LiteratureRefinement.vue';
import MyDownload from '@/components/MyDownload.vue';
import EbookReader from '@/components/EbookReader.vue';
import PaperReview from '@/components/PaperReview.vue';
import JournalRecommendation from '@/components/JournalRecommendation.vue';
import { globalState } from "@/globalState.js";



const routes = [
  { path: '/useraccount', component: UserAccount, name: 'useraccount' },
  { path: '/howitworks', component: HowItWorks, name: 'howitworks' },
  { path: '/aboutus', component: AboutUs, name: 'aboutus' },
  { path: '/translationproducts', component: TranslationProducts, name: 'translationproducts' },
  { path: '/voicetranslation', component: VoiceTranslation, name: 'voicetranslation' }, 
  { path: '/researchai', component: ResearchAI, name: 'researchai' },
  { path: '/bilingualbookstore', component: BiLingualBookstore, name: 'bilingualbookstore' },
  { path: '/documenttranslation', component: DocumentTranslation, name: 'documenttranslation' },
  { path: '/texttranslation', component: TextTranslation, name: 'texttranslation' },
  { path: '/tasktable', component: TaskTable, name: 'tasktable' },
  { path: '/useraccount', component: UserAccount, name: 'useraccount' },
  { path: '/academicpapers', component: AcademicPapers, name: 'academicpapers' },
  { path: '/businessdocuments', component: BusinessDocuments, name: 'businessdocuments' },
  { path: '/literaturepublication', component: LiteraturePublication, name: 'literaturepublication' },
  { path: '/academicrefinement', component: AcademicRefinement, name: 'academicrefinement' },
  { path: '/businessrefinement', component: BusinessRefinement, name: 'businessrefinement' },
  { path: '/literaturerefinement', component: LiteratureRefinement, name: 'literaturerefinement' },
  { path: '/mydownload', component: MyDownload, name: 'mydownload' },
  { path: '/ebookreader', component: EbookReader, name: 'ebookreader' },
  { path: '/paperreview', component: PaperReview, name: 'paperreview' },
  { path: '/journalrecommendation', component: JournalRecommendation, name: 'journalrecommendation' },

  { 
    path: '/buymorecredits/:amountNeeded?',
    component: BuyMoreCredits, 
    name: 'buymorecredits',
    props: true },
    {
      path: '/project/:projectId/:projectName',
      name: 'ProjectTable',
      component: ProjectTable,
      props: true,
    },
  { 
    path: '/',
    component: MyDashboard, 
    name: 'mydashboard',
  },
  { path: '/myproject', component: MyProject, name: 'myproject' },
  { path: '/createproject', component: CreateProject, name: 'createproject' },
  {
    path: '/order/success',
    name: 'OrderSuccess',
    component: OrderSuccess
  },
  {
    path: '/order/cancelled',
    name: 'OrderCancelled',
    component: OrderCancelled
  },
  {
    path: '/order/fastordersuccess',
    name: 'FastOrderSuccess',
    component: FastOrderSuccess
  },

  
 
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes, 
})

router.beforeEach((to, from, next) => {
  if (globalState.isTaskUnfinished) {
    const answer = window.confirm('You have unfinished tasks. Are you sure you want to leave?');
    if (answer) {
      globalState.isTaskUnfinished = false; // Reset the state
      next();
    } else {
      next(false);
    }
  } else {
    next();
  }
});


export default router
