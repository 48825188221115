<template>
  <CreateProject :vModelValue="showCreateProjectDialog"  @selected-project="updateProjectId" @update:vModelValue="val => showCreateProjectDialog = val" />

    <div class="page-container">
    <div class="project-details">
      <p class="project-name">{{ pagelanguages[currentpageLanguage]?.projectname }} {{ selectedProjectName }}</p>
  </div>
    <v-select class="custom-select" hide-details="true" 
      :items="languages"
      :label="pagelanguages[currentpageLanguage]?.targetlanguage"
      v-model="selectedLanguage"
    ></v-select>
    <v-select class="custom-select" hide-details="true" :items="useCases" label="Use Case" v-model="selectedUseCase"></v-select>

    <v-select class="custom-select" hide-details="true"
      :items="subjects"
      :label="pagelanguages[currentpageLanguage]?.subject"
      v-model="selectedSubject"
      :disabled="!selectedUseCase"
    ></v-select>

    <v-checkbox class="custom-checkbox"
      v-model="writingImprovement"
      :label="pagelanguages[currentpageLanguage]?.writingimprovement"
    ></v-checkbox>

    <v-row>
      <v-col cols="12" md="6">
        <v-textarea
        class="custom-textarea"
        hide-details="true"
        :label="pagelanguages[currentpageLanguage]?.inputtext"
          v-model="inputText"
          rows="6"
        ></v-textarea>
      </v-col>

      <v-col cols="12" md="6">
        <v-textarea
        class="custom-textarea"
        hide-details="true"
          outlined
          auto-grow
          readonly
          :label="pagelanguages[currentpageLanguage]?.outputtext"
          rows="6"
          v-model="outputText"
        >
        </v-textarea>
      </v-col>
    </v-row>

    <v-btn class="translate-button" @click="translateText()">
      <v-progress-circular
        indeterminate
        color="white"
        v-if="loading"
      />
      <span v-else >{{ pagelanguages[currentpageLanguage]?.translate }}</span>
    </v-btn>
    <v-dialog v-model="vModelValue" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ pagelanguages[currentpageLanguage]?.pleaselogin }}</span>
        </v-card-title>
        <v-card-text>{{ pagelanguages[currentpageLanguage]?.pleaselogintext }}.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="showLoginDialog = false">{{ pagelanguages[currentpageLanguage]?.close }}</v-btn>
          <v-btn color="green darken-1" text @click="login">{{ pagelanguages[currentpageLanguage]?.login }}</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  
  <v-dialog v-model="insufficientCreditDialog" max-width="500">
  <v-card>
    <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
    <v-card-text>{{ dialogMessage }}</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="handleDialogClose">{{ pagelanguages[currentpageLanguage]?.close }}</v-btn>
      <v-btn color="green darken-1" text @click="buyMoreCredits">{{ pagelanguages[currentpageLanguage]?.buymorecredits }}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


<v-dialog v-model="confirmCostDialog" max-width="500">
  <v-card>
    <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
    <v-card-text>{{ dialogMessage }}</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="handleDialogClose">{{ pagelanguages[currentpageLanguage]?.no }}</v-btn>
      <v-btn color="green darken-1" text @click="handleConfirm">{{ pagelanguages[currentpageLanguage]?.yes }}</v-btn>
      
    </v-card-actions>
  </v-card>
</v-dialog>
<custom-alert ref="customAlert"></custom-alert>
</template>


<style scoped>
.page-container {
  padding-top: 140px;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  background-color: #161519;
  min-height: 100%;
 
  flex-direction: column;
}
.section-divider-1 {
  margin-top: 6em;
  border-color: #00E8BE;
  /* slightly brighter line on dark background */
}

.project-name {
  font-size: 1.5em;
  /* Adjust size as needed */
  font-weight: 600;
  /* Make it bold */
  color: #FDFDFD;
  /* Darker text */
  padding: 15px;
  /* Some padding around */
  margin: 0;
  /* Remove default browser margin */
  font-family: 'Red Hat Display', sans-serif;

}
.custom-select {
  color: black;
  background-color: #FDFDFD;
}
.custom-checkbox {
  color: white;
}
.custom-textarea{
  background-color: #FDFDFD;
  color:black;
}
.translate-button{
  color: #FDFDFD;
  background-color: #2D5EFF;
  margin-top: 1em;
  font-family: 'Red Hat Display', sans-serif;
}
</style>



  <script>
  import { reactive,ref,watch,onMounted,inject  } from 'vue';
  import axios from 'axios';
  import { useAuth0 } from '@auth0/auth0-vue';
  import CreateProject from '@/components/CreateProject.vue';
  import { useRouter } from 'vue-router'
  import { openCheckoutPage } from '@/components/services/stripeService';
  import CustomAlert from "@/components/CustomAlert.vue";
  export default {
    components: {
    CreateProject,
    CustomAlert
  },
    setup() {
      const { isAuthenticated, loginWithRedirect } = useAuth0();
      const showLoginDialog = ref(false);
      const showCreateProjectDialog = ref(false);
      const auth0 = useAuth0();
      const selectedLanguage = ref('');
      const inputText = ref('');
      const outputText = ref('');
      const writingImprovement = ref(true);
      const languages = ref([]);
      const useCases = ref([]); 
      const selectedUseCase = ref('');
      const subjects = ref([]); 
      const selectedSubject = ref(''); 
      const selectedProjectId = ref(null);
      const selectedProjectName = ref(null);
      const router = useRouter();
      const loading = ref(false);
      let amountNeeded = 0;
      const customAlert = ref(null);
      const currentpageLanguage = inject('currentLanguage');
      const pagelanguages = inject('languages');
      
      const userProfile = ref({
          email: 'dummy@user.com',
          userId: '123456',
          credit: 100,
      });
      const fetchLanguages = async () => {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/public/app/documentTranslation/getLanguages`);
          languages.value = response.data.map(language => language.name);
        } catch (error) {
          console.error('Failed to fetch languages: ', error);
        }
      };

      fetchLanguages();
      let apiData = reactive({ data: {} });
      const fetchStyles = async () => {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/public/app/documentTranslation/formTable`);
        apiData.data = response.data;  // Store response data
        console.log(response.data)
        let useCaseNames = [];

        Object.keys(response.data).forEach(key => {
            const names = response.data[key].map(useCase => useCase.name);
            useCaseNames = useCaseNames.concat(names);
        });
       
      } catch (error) {
        console.error('Failed to fetch styles: ', error);
      }
    };
    fetchStyles();
    watch(selectedUseCase, (newUseCase) => {
      if (newUseCase) {
        let useCase = apiData.data.find(useCase => useCase.name === newUseCase);
        if (useCase) {
          subjects.value = useCase.subjects.map(subject => subject.name);
        }
        selectedSubject.value = '';  // Reset selected subject when use case changes
      }
    });

//------------------------------------------------------------------------------------------------------------------------------------------------


      const login = async () => {
        try {
          await loginWithRedirect({
            redirect_uri: window.location.origin + '/texttranslation',
          });
          showLoginDialog.value = false;  // close the dialog
        } catch (error) {
          console.error('Error during login: ', error);
        }
      };



      const checkUserAuth = async () => {
        console.log("checkUserAuth isAuthenticated.value:", isAuthenticated.value);
      if (isAuthenticated.value) {
        showCreateProjectDialog.value = true;
      } else {
        showLoginDialog.value = true;
      }
    };

    onMounted(() => {
      console.log("isAuthenticated.value:", isAuthenticated.value);
      checkUserAuth();
    });

  
      const updateProjectId = (projectId, projectName) => {
          selectedProjectId.value = projectId; 
          selectedProjectName.value = projectName;
        };
//-----------------------------------------------------------------------------------------------------------------------------------------------------


const insufficientCreditDialog = ref(false);
const confirmCostDialog = ref(false);
const dialogMessage = ref("");
const dialogTitle = ref("");

const buyMoreCredits = () => {
    
    // If the amount needed is less than 10, set it to 10
    if (amountNeeded < 10) {
        amountNeeded = 10;
    }
    
    // Convert this amount into a quantity (assuming $1 = 100 credits)
    let quantity = amountNeeded * 100;
    
    // Call the openCheckoutPage function with this quantity
    openCheckoutPage(auth0,isAuthenticated,  quantity, 'price_1NijNSJ40WTpEtgla3XIcD3R'); // using the same priceID as in your fixed price options
    insufficientCreditDialog.value = false;
  }


const handleDialogClose = () => {
  insufficientCreditDialog.value = false;
  confirmCostDialog.value = false;
};





//----------------------------------------------------------------------------------------------------
  const calculateWordsRegex =(str)=> {
    // Match CJK, Arabic, non-complex script words, and punctuation
    const wordAndPunctuationRegex =
        /[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u3400-\u4DBF\uAC00-\uD7AF\u0E00-\u0E7F\u0E80-\u0EFF\u0F00-\u0FFF\u1000-\u109F\u1780-\u17FF\u0D80-\u0DFF\u0600-\u06FF]|\b\w+\b|[.,;!?]/g;
    const allWordsMatched = str.match(wordAndPunctuationRegex);
    const totalCount = allWordsMatched ? allWordsMatched.length : 0;

    // Return an object with the count and the words
    return  totalCount;
}


const fetchUserProfile = async () => {
        // Check if user is authenticated
        if (isAuthenticated.value) {
          try {
              // Get the access token
              const token = await auth0.getAccessTokenSilently();
              console.log('User is authenticated. Token: ', token);

              console.log('About to send request...');

              const response = await axios({
                  method: 'get',
                  url: `${process.env.VUE_APP_API_URL}/api/credit/get`,
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`
                  },
                  maxRedirects: 0,
                  validateStatus: function (status) {
                      return status >= 200 && status < 303; // default
                  },
              });

              if (response.status === 200){
                  userProfile.value = {
                      userId: response.data.userId,
                      credits: response.data.balance, 
                  };
              } else {
                  console.error('Failed to fetch user profile: ', response);
              }

          } catch (error) {
              console.error('Error while getting the token or fetching user profile: ', error);
              console.error('Axios error: ', error.response);
          }
        } else {
          console.log('User is not authenticated, cannot retrieve the user profile.');
        }
      };



      const translateText = async () => {
        console.log("isAuthenticated:", isAuthenticated.value);
        if (!isAuthenticated.value) {
          showLoginDialog.value = true;
          return;
        }
        if (selectedProjectId.value === null) {
          showCreateProjectDialog.value = true;
          return;
        }

        // Check if source language, target language and translation style are selected
        if (!inputText.value || !selectedLanguage.value) {
          customAlert.value.openAlert("Error", "Please provide input text, target language and translation style before translation.");
          return;
        }

        loading.value = true;

        try {
          const token = await auth0.getAccessTokenSilently();

          // Calculate word count
          const wordCount = calculateWordsRegex(inputText.value);
          console.log('Word count:', wordCount);

          // Get price per word from backend API
          const priceResponse = await axios.get(`${process.env.VUE_APP_API_URL}/api/app/documentTranslation/getProductPrice`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log('getprice response: ', JSON.stringify(priceResponse.data));
          const selectedUseCasePrice = priceResponse.data.find((item) => item.useCase.name === selectedUseCase.value); // replace `selectedUseCase.value` with the variable that stores the selected use case
          if (!selectedUseCasePrice) {
            alert("Unable to retrieve the price for the selected use case. Please try again later.");
            return;
          }
          const pricePerWord = parseFloat(selectedUseCasePrice.price);
          console.log('Price per word:', pricePerWord);

          // Calculate total price
          const totalPrice = wordCount * pricePerWord;
          console.log('Total price:', totalPrice);

          // Get user profile
          await fetchUserProfile();
          const userBalance = parseFloat(userProfile.value.credits);
          console.log('User balance:', userBalance);

          // Check if user has enough balance
          if (userBalance < totalPrice) {
            const amountNeeded = totalPrice - userBalance;
            dialogTitle.value = 'Credit not enough';
            dialogMessage.value = `You have ${userBalance}. You need ${amountNeeded} more. Would you like to purchase more credits?`;
            insufficientCreditDialog.value = true;
            loading.value = false;
            return;
          }

          // Calculate remaining balance
          const remainingBalance = userBalance - totalPrice;
          console.log('Remaining balance:', remainingBalance);

          // Show confirmation window with price and remaining balance information
          dialogMessage.value = `The translation will cost ${totalPrice} credits. After this operation, you will have ${remainingBalance} credits left. Do you want to proceed?`;
          confirmCostDialog.value = true;

        } catch (error) {
          // This block will handle any unexpected errors that occur in the try block
          console.error('Unexpected error:', error);
          customAlert.value.openAlert("Error", "An unexpected error occurred. Please try again later.");
          loading.value = false;
        }
      };


    const handleConfirm = async () => {
      confirmCostDialog.value = false;  // close the dialog

      try {
        const token = await auth0.getAccessTokenSilently();
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/app/textTranslation/uploadFile`,
          {
            text: inputText.value,
            targetLanguage: selectedLanguage.value,
            subject: selectedSubject.value,
            projectId: selectedProjectId.value,
            writingImprovement: writingImprovement.value,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
          }
        );

        console.log('response:',response.data.translated_text);
        console.log('response:',response.status);
        if (response.status>=300){
          customAlert.value.openAlert("Error", "There is an error occurred, please try again later.", response.status);
          loading.value = false;
          return;

        }
        if (response.status == 200) {
          outputText.value=response.data.translated_text;
          loading.value = false;
          return;
        }
        
        if (response.status == 202) {
          openAlertAndNavigate("Time out", "Your task may take longer to complete, please check it in My Project.", "/");
          loading.value = false;
          return;
        }

      } catch (error) {
        // This block will handle any unexpected errors that occur in the try block
        console.error('Unexpected error:', error);
        customAlert.value.openAlert("Error", "An unexpected error occurred. Please try again later.");
        loading.value = false;
      }
    };
    const openAlertAndNavigate = (title, message, route) => {
      customAlert.value.openAlert(title, message);

      const unwatch = customAlert.value.$watch('show', (newVal) => {
        if(!newVal) { // dialog is closed
          router.push(route);
          unwatch(); // remove watcher when not needed anymore
        }
      });
    };





      return {
        selectedLanguage,
        inputText,
        outputText,
        writingImprovement,
        languages,
        isAuthenticated,
        showLoginDialog,
        login,
        translateText,
        updateProjectId,
        selectedProjectId,
        selectedProjectName,
        subjects,
        useCases,
        selectedUseCase,
        selectedSubject,
        showCreateProjectDialog,
        insufficientCreditDialog,
        dialogMessage,
        dialogTitle,
        loading,
        handleDialogClose,
        openCheckoutPage,
        buyMoreCredits,
        customAlert,
        handleConfirm,
        confirmCostDialog,
        pagelanguages,
        currentpageLanguage,
      };
    }
  }
</script>

  
  
  