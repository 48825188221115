<template>
    <v-dialog v-model="show" width="500">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeAlert">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: "CustomAlert",
    data() {
      return {
        show: false,
        title: "",
        message: ""
      };
    },
    methods: {
      openAlert(title, message) {
        this.title = title;
        this.message = message;
        this.show = true;
      },
      closeAlert() {
        this.show = false;
        this.$emit('closed');
      }
    }
  };
  </script>
  